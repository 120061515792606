import React, {ChangeEventHandler} from "react";
import './style.scss'

interface ITextareaProps {
  placeholder?: string;
  className?: string;
  value?: string | number;
  name?: string;
  title?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
}

const Textarea = ({
  placeholder,
  className,
  value,
  name,
  onChange,
  title
}: ITextareaProps) => {
  return (
    <div className="relative w-full common-textarea">
      {
        title && <label className="">{title}</label>
      }
      <div className="mt-1">
        <textarea
          placeholder={placeholder}
          className={`w-full resize-none p-3 h-24 focus:outline-none rounded ${className}`}
          value={value}
          name={name}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export default Textarea;
