import React, { useState, useEffect } from "react";
import ProfileDetailSection from "../../components/modules/ProfileDetailSection/ProfileDetail";
import Modal from "../../components/elements/Modal";
import Button from "../../components/elements/Button";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import TiktokIcon from "../../components/elements/Icon/TiktokIcon";
import Textarea from "../../components/elements/Textarea";
import FullLayout from "../../components/layout/FullLayout";
import httpService from "services/http.service";
import { TwitterShareButton } from "react-share";
import { profileShareMessage } from "constants/shareMessage";
import "./style.scss";
import { useSelector } from "react-redux";
import { RootState } from "store";
import imageService from "services/image.service";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import { Helmet } from "react-helmet-async";

const ProfileDetail = (props: any) => {
  const param = props.match.params.url;
  const [isSocialModalOpened, setIsSocialModalOpened] = useState(false);
  const [isReportModalOpened, setIsReportModalOpened] = useState(false);
  const [fetchData, setFetchData] = useState(null);
  const [reportDescription, setReportDescription] = useState("");
  const [coverImage, setCoverImage] = useState("/assets/images/cover.png");
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const [isCopied, setIsCopied] = useState(false);
  const [isCoverUploading, setIsCoverUploading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [petTypes, setPetTypes] = useState<string[]>([]);

  useEffect(() => {
    const getProfileData = async () => {
      const responseData: any[] = (
        await httpService.get(`/profile/profile-by-name?url=${"/" + param}&userAddress=${userAddress ? userAddress : ''}`)
      ).data;

      const owner = (await httpService.get(`/user/by-address/${responseData[0].profile_owner}`)).data;

    //  console.log('setFetchData', responseData[0]);
      setFetchData({...responseData[0], owner: owner});
      setCoverImage(responseData[0]?.cover_url)
    };
    getProfileData();

    // getting profile types
    const getTypes = async () => {
      const responseData = (await httpService.get(`type/types`)).data;
      setPetTypes(responseData.map((typeElement) => typeElement.name));
    };
    getTypes();
  }, []);

  // useEffect(() => {
  //   const getUserInfo = async () => {
  //     const responseData: any = (await httpService.get(`/user/user-info`)).data;
  //     console.log('onCoverImage 3');
  //     setCoverImage(responseData?.cover_url || "/assets/images/cover.png");
  //   };

  //   if (userAddress) getUserInfo();
  // }, [userAddress]);
  const handleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleSocialModal = () => {
    setIsSocialModalOpened(!isSocialModalOpened);
  };

  const handleReportModal = () => {
    setIsReportModalOpened(!isReportModalOpened);
  };

  const reportProfile = async () => {
    await httpService.post("/report-profile/report", {
      profileId: fetchData.profile_id.toString(),
      description: reportDescription,
    });
    handleReportModal();
  };

  const onFileSelected = async (e) => {
    setIsCoverUploading(true);
    let image = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async () => {
      let result = null;
      if (typeof reader.result == "string") result = reader.result;
      // const uploadedUrl = await ipfsService.uploadImageToIpfs(result);
      const uploadedUrl = await imageService.uploadImage(result);
      const responseData = (
        await httpService.put(`/profile/set-cover`, {
          coverUrl: uploadedUrl,
          profileId: fetchData.profile_id
        })
      ).data;
      setFetchData({...fetchData, cover_url: uploadedUrl});
      setCoverImage(uploadedUrl);
      setIsCoverUploading(false);
    };
  };

  const removeFile = async () => {
    handleDeleteModal();
    setIsCoverUploading(true);
    const responseData = (
      await httpService.put(`/profile/set-cover`, {
        coverUrl: '',
        profileId: fetchData.profile_id
      })
    ).data;
    setCoverImage("/assets/images/cover.png");
    setFetchData({...fetchData, cover_url: ''});
    setIsCoverUploading(false);

  };

  const handleCopy = (url: string) => {
    setIsCopied(prev => !prev)
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setIsCopied(prev => !prev)
    }, 2000)
  }

  console.log("fetchData", fetchData)

  return (
    <FullLayout>
      <>
      <Helmet>
        <title> {fetchData?.name ? fetchData.name : "Pet Profile"} | KINGDOM</title>
        <meta property="og:title" content={` ${fetchData?.name ? fetchData.name : "Pet Profiles"}'s content on KINGDOM`} />
        <meta name="description" content={`${fetchData?.items?.length > 0 ? fetchData.items.length : 0} posts - See photos of ${fetchData?.name ? fetchData.name : "Pet Profiles"} the ${fetchData?.type_id && petTypes.length > 0 ? petTypes.find((item:any) => item.type_id === fetchData.type_id) : "Type"} on KINGDOM.`} />
        <meta name="og:description" content={`${fetchData?.items?.length > 0 ? fetchData.items.length : 0} posts - See photos of ${fetchData?.name ? fetchData.name : "Pet Profiles"} the ${fetchData?.type_id && petTypes.length > 0 ? petTypes.find((item:any) => item.type_id === fetchData.type_id) : "Type"} on KINGDOM.`} />
        <meta name="og:image" content={fetchData?.image_url ? fetchData.image_url : `${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
      <div className="max-w-full lg:max-w-screen-xl mx-auto mt-6 mb-20 profile-page">
        {isSocialModalOpened && (
          <Modal handleModal={handleSocialModal}>
            <div className="space-y-2 text-gothic">
              <TwitterShareButton
                url={profileShareMessage + " " + window.location.href}
                className="w-full"
              >
                <Button
                  classNames="w-full rounded-md flex items-center px-4 py-2 !h-auto"
                  leftIcon={<FaTwitter className="mr-4" />}
                  label="Share on twitter"
                />
              </TwitterShareButton>
              {/* Later */}
              {/* <Button
                classNames="w-full rounded-md flex items-center"
                leftIcon={<TiktokIcon className="mr-4" />}
                label="Share on tiktok"
              />
              <Button
                classNames="w-full rounded-md flex items-center"
                leftIcon={<FaInstagram className="mr-4" />}
                label="Share on instagram"
              /> */}

              <Button
                classNames="w-full rounded-md flex items-center copy-btn px-4 py-2 !h-auto"
                onClick={()=>handleCopy(window.location.href)}
                leftIcon={
                  <svg className="mr-2" height="1.5em" width="1.5em" viewBox="0 0 24 24" stroke-width="1.5" stroke="#444" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                  </svg>
                }
                label={isCopied ? "Copied to clipboard" : "Copy link"}
              /> 
            </div>
            <div className="mt-5 sm:mt-6 text-gothic">
              <Button
                label="close"
                classNames="w-full rounded-md hover:!bg-gray-300"
                bgColor="gray"
                onClick={handleSocialModal}
              />
            </div>
          </Modal>
        )}
        {isReportModalOpened && (
          <Modal handleModal={handleReportModal}>
            <div className="space-y-2">
              <p className="mb-2 text-base text-gothic font-medium">Report items</p>
              <Textarea
                title="Please describe your complaint."
                onChange={(event) => setReportDescription(event.target.value)}
              />
            </div>
            <div className="mt-5 sm:mt-6 space-y-2">
              <div className="flex justify-center">
                <Button
                  label="report"
                  bgColor="red"
                  classNames="rounded-full w-40 text-gothic font-semibold text-sm"
                  onClick={reportProfile}
                />
              </div>
              <div className="flex justify-center">
                <Button
                  label="cancel"
                  bgColor="gray"
                  variant="text"
                  classNames="rounded-full w-40 text-gothic font-semibold text-sm"
                  onClick={handleReportModal}
                />
              </div>
            </div>
          </Modal>
        )}        
        <div className="w-full h-64 bg-gray-100 group relative text-gothic font-medium">
          <img
            src={coverImage ? coverImage : '/assets/images/cover.png'}
            alt="cover"
            className="absolute inset-0 object-cover h-full w-full"
          />
          {userAddress?.toLowerCase() === fetchData?.profile_owner?.toLowerCase() &&  !isCoverUploading &&
            <div className="group-hover:opacity-100 opacity-0 absolute inset-0 bg-white/70 flex flex-col justify-center items-center">
              <p>PNG or JPEG Max 50MB</p>
              <small>Recommended size 1440 x 256px</small>
              <input
                id="file"
                onChange={(e) => onFileSelected(e)}
                className="hidden"
                type="file"
                hidden={true}
              />
              <label
                htmlFor="file"
                className="change-cover bg-transparent rounded-full mt-4 border-2"
                style={{ padding: "10px 25px 10px 25px" }}
              >
                Change cover
              </label>
              {fetchData.cover_url && <>
                <button
                id="file-reset"
                onClick={(e) => handleDeleteModal()}
                className="hidden"                
                hidden={true}
              />
              <label
                htmlFor="file-reset"
                className="reset-cover bg-transparent rounded-full mt-4 border-2"
                style={{ padding: "10px 15px 10px 15px" }}
              >
                Reset to default
              </label>
              </>}
              
            </div>
         }
         {
            isCoverUploading && (
              <div className="absolute inset-0 flex flex-col justify-center items-center">
                <SpinnerIcon className="my-auto"/>
              </div>
            )
          }
        </div>
        <ProfileDetailSection
          handleSocialModal={handleSocialModal}
          handleReportModal={handleReportModal}
          data={fetchData}
        />
         {isDeleteModalOpen && (
          <Modal
            handleModal={handleDeleteModal}
            disableOverlay={false}
            size={800}
          >
            <div className="delete-modal">
              <h1 className="modal-title">Are you sure to remove cover image?</h1>
              <div className="action-button-container">
                <button
                  className="py-3 px-6 rounded-md mt-4 long-btn mint"
                  onClick={handleDeleteModal}
                >
                  No, Keep it
                </button>
                <button
                  className="py-3 px-6 rounded-md mt-4 long-btn delete-nft"
                  onClick={removeFile}
                >
                  Yes, delete it
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
      </>
    </FullLayout>
  );
};

export default ProfileDetail;
