import React, { ChangeEventHandler } from "react";
import './style.scss'

interface IInputProps {
  placeholder?: string;
  type?: 'text' | 'number' | 'password';
  className?: string;
  title?: string;
  hint?: string;
  value?: string | number;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  coloredHint?: boolean
}

const Input = ({
  placeholder = '',
  type = 'text',
  className,
  title,
  hint,
  value,
  onChange,
  name,
  coloredHint
}: IInputProps) => {
  return (
    <div className="w-full common-input">
      {
        title && <label className="block font-medium font-title text-sm text-gray-700 ">{title}</label>
      }
      <div className="relative w-full mt-1">
        <input
          type={type}
          placeholder={placeholder}
          className={`w-full rounded-md focus:outline-none pl-3 py-3 h-12 bg-light-bg ${className}`}
          onChange={onChange}
          value={value}
          name={name}
        />
      </div>
      {
        hint && <small className={`input-hint ${coloredHint? "colored" : ""}`}>{hint}</small>
      }
    </div>
  );
};

export default Input;
