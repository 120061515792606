import React from "react";

interface ITiktokIconProps {
  className?: string;
}

const TiktokIcon = ({ className }: ITiktokIconProps) => {
  return (
    <svg className={`h-5 w-5 ${className}`} viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path
        d="M20.7,10.2a8.3,8.3,0,0,1-4.8-1.5v6.9A6.3,6.3,0,1,1,9.6,9.3h.9v3.5a2.9,2.9,0,0,0-3.7,1.9,2.9,2.9,0,0,0,5.6,1.7,2.8,2.8,0,0,0,.1-.9V2.1h3.4c0,.3.1.5.1.8h0A4.5,4.5,0,0,0,18.1,6a5,5,0,0,0,2.6.8Z"/>
      </svg>
  );
};

export default TiktokIcon;
