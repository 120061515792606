import React, { useEffect, useState } from "react";
import Button from "../../../elements/Button";
import Modal from "../../../elements/Modal";
import ModalStep1 from "./ModalStep1";
import ModalStep2 from "./ModalStep2";
import ModalStep3 from "./ModalStep3";
import ModalStep4 from "./ModalStep4";
import PreviewCard from "../Step3/PreviewCard";
import httpService from "services/http.service";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ethers } from "ethers";
import collectionJson from "../../../../artifacts/contracts/KingdomNFTCollectionV1.sol/KingdomNFTCollectionV1.json";
import { Link } from "react-router-dom";
import StripePayment, { PaymentMethods } from "../../../modules/StripePayment";
import useNotification from "hooks/useNotification";
import Input from "components/elements/Input";
import { getETHRate, verifyNFT } from "common/utils";
import { setVerifyModalOpen, setVerifyModalMessage } from "store/reducers/walletpopupSlicer";


interface IStep5Props {
  selectedInfo: null | number;
  onChangeStep: () => void;
  imageInfo: string;
  titleInfo: string;
  item: any;
  profileInfo
}

type IPetType = {
  petName: string;
  petHandle: string;
  petIg: string;
  avatar: string;
  petType: string;
  petBreed: string;
};

const Step5 = ({
  selectedInfo,
  onChangeStep,
  imageInfo,
  titleInfo,
  item,
  profileInfo,
}: IStep5Props) => {
  const [createStep, setCreateStep] = useState(1);
  const [show, setShow] = useState(false);
  const [pets, setPets] = useState<IPetType[]>([]);
  const [paymentMethodModalOpen, setPaymentMethodModalOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [paymentPromoMethodModalOpen, setPaymentPromoMethodModalOpen] =
    useState(false);
  const [
    paymentCreditCardMethodModalOpen,
    setPaymentCreditCardMethodModalOpen,
  ] = useState(false);
  const [collectionAddress, setCollectionAddress] = useState<string>("");
  const [mintFee, setMintFee] = useState<string>("");
  const [mintFeeUsd, setMintFeeUsd] = useState<number>(0);
  // const [txFeeUsd, setTxFeeUsd] = useState<number>(0);
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const signer = useSelector((state: RootState) => state.wallet.signer);
  const provider = useSelector((state: RootState) => state.wallet.provider);
  const type = useSelector((state: RootState) => state.wallet.type);
  const notify = useNotification();
  const dispatch = useDispatch()

  useEffect(() => {
    const petList = localStorage.getItem("pets");
    if (petList != null) {
      setPets(JSON.parse(petList));
    }
  }, []);

  useEffect(() => {
    const getCollectionAddress = async () => {
      console.log('item', item);
      if (item) {
        const responseData = (
          await httpService.get(`/category/category-by-id?id=${item.category_id}`)
        ).data;
        if (responseData.length > 0) {
          const rate = await getETHRate();
          setCollectionAddress(responseData[0].collection_address);

          console.log('mint fee', responseData[0].collections.mint_fee);
          setMintFee(responseData[0].collections.mint_fee);
          
          const _mintFeeinUsd = parseFloat(ethers.utils.formatEther(responseData[0].collections.mint_fee)) * parseFloat(rate);
          
          

          if (item.token_url.includes('http')) {
            const feeData = await provider.getFeeData();
            const gasPrice = Number(feeData.gasPrice.toString());
  
            const estimation = (await httpService.get(`/stripe/estimate-gas?collectionAddress=${responseData[0].collection_address}&userAddress=${userAddress}&mintFee=${responseData[0].collections.mint_fee}&tokenUrl=${item.token_url}`)).data;
            
            const gasLimit = Number(estimation);
            const transactionCost = gasPrice * gasLimit;
            
            const transactionCostUsd = parseFloat(
              ethers.utils.formatEther(transactionCost)
            ) * parseFloat(rate)
            const totalCostUsd = _mintFeeinUsd + transactionCostUsd;
            console.log('totalCostUsd', totalCostUsd);

            setMintFeeUsd(totalCostUsd);
          }
        }
      }
    };
    getCollectionAddress();
  }, [item]);

  const mintAtBackend = async () => {
    const responseData = (
      await httpService.put("item/mint", {
        itemId: item.item_id.toString(),
      })
    ).data;
  };

  const mintOnBlockchain = async () => {
    try {
      const contract = new ethers.Contract(
        collectionAddress,
        collectionJson.abi,
        provider
      );
      
      if (type === 'WALLET_CONNECT'){
        dispatch(setVerifyModalMessage('Please confirm the transaction from your connected wallet app.'));
        dispatch(setVerifyModalOpen(true));
      }
      
      const tx = await contract
        .connect(signer)
        .mintToken(userAddress, item.token_url, {
          value: mintFee,
        });
        dispatch(setVerifyModalOpen(false));
      await tx.wait();
      notify("Transaction Successful", "success");
      setCreateStep(3);
    } catch (error) {
      console.log(error)
      // notify(error.message, 'error');
      notify("We have faced an error", "error");
      dispatch(setVerifyModalOpen(false));
      setShow(false);
      const responseData = (
        await httpService.put("item/mint-failed", {
          itemId: item.item_id.toString(),
        })
      ).data;
    }
  };

  const handlePaymentMethodModal = () => {
    setPaymentMethodModalOpen(!paymentMethodModalOpen);
    setPaymentPromoMethodModalOpen(false);
  };

  const handlePromoPaymentMethodModal = () => {
    setPaymentMethodModalOpen(false);
    setPaymentPromoMethodModalOpen(!paymentPromoMethodModalOpen);
  };

  const handleCreditCardPaymentMethodModal = () => {
    setPaymentCreditCardMethodModalOpen(!paymentCreditCardMethodModalOpen);
    setPaymentMethodModalOpen(false);
  };

  const handleMintNow = (method: PaymentMethods) => {
    if (method === PaymentMethods.CREDIT_CARD)
      handleCreditCardPaymentMethodModal();
    else if (method === PaymentMethods.ETH) {
      handlePaymentMethodModal();
      onCreate();
    } else if (method === PaymentMethods.PROMO) {
      handlePromoPaymentMethodModal();
    }
  };

  const success = async (step) => {

    console.log('create steps', step);
    setShow(true);
    setCreateStep(step);
  };

  const onCreate = async () => {
    setShow(true);
    setCreateStep(1);

    await mintAtBackend();
    setCreateStep(2);

    // setTimeout(() => {
    //   setCreateStep(3);
    // }, 2000);

    await mintOnBlockchain();

    setTimeout(() => {
      setCreateStep(4);
    }, 2000);
  };

  const handleModal = () => {
    setShow(false);
  };

  const renderModalContent = (createStep: number) => {
    switch (createStep) {
      case 1:
        return <ModalStep1 />;
      case 2:
        return <ModalStep2 />;
      case 3:
        return <ModalStep3 />;
      case 4:
        return <ModalStep4 itemId={item.item_id} />;
    }
  };

  const handlePromoCodeChange = (event) => {
    setPromoCode(event.target.value);
  };

  const triggerPromoMinting = async () => {

    console.log('triggerPromoMinting', promoCode);

    if (!promoCode) {
      notify("Input promo code.", "error");
      return;
    }

    handlePromoPaymentMethodModal();
    setShow(true);
    setCreateStep(2);
    try {
      await mintAtBackend();
      setCreateStep(3);      

      const data = (
        await httpService.post("/promotion/use-promotion", {
          itemId: item.item_id,
          promotionCode: promoCode.trim(),
        })
      ).data;

      console.log('mint output', data);

      const endRequestsAt = Date.now() + parseInt(process.env.REACT_APP_TRANSACTION_WAITING_TIME);
      const verified = await verifyNFT(endRequestsAt, item.item_id);
      if (verified) {
        setCreateStep(4);
      } else {
        notify("Transaction timeout, but still your transaction can succeeded", "error");
        await httpService.put("item/mint-failed", {
          itemId: item.item_id.toString(),
        });
        handleModal();
      }

    } catch (error) {
      await httpService.put("item/mint-failed", {
        itemId: item.item_id.toString(),
      });
      notify(error.message, "error");
      handleModal();
    }
  };

  return (
    <>
      {show && (
        <Modal handleModal={handleModal} disableOverlay={false} className={'!z-40'}>
          {renderModalContent(createStep)}
        </Modal>
      )}
      <div className="relative min-h-[500px] pb-20 step-5 pt-5">
        <div className="flex flex-col-reverse md:flex-row gap-10">
          <div className="relative space-y-6 min-w-auto sm:min-w-[500px]">
            <p className="subtitle">
              Your NFT was created! You can choose to mint now or do it some
              other time.
            </p>
            <Button
              label="Mint now"
              classNames="rounded btn-long colored !h-[60px]"
              onClick={handlePaymentMethodModal}
            />
            <Link
              to={`/nfts/${item?.item_id}`}
              className="btn-long transparent rounded flex items-center justify-center tracking-wide !h-[60px]"
            >
              Mint later
            </Link>
          </div>
          <div className="relative">
            <div className="rounded-xl min-w-[250px] min-h-[284px] bg-gray-100">
              {selectedInfo !== null && (
                <PreviewCard
                  selected={selectedInfo}
                  title={titleInfo}
                  pet={pets[selectedInfo]}
                  image={imageInfo}
                  profileInfo={profileInfo}
                />
              )}
            </div>
          </div>

          {paymentMethodModalOpen && (
            <Modal
              size={384}
              disableOverlay={true}
              handleModal={handlePaymentMethodModal}
            >
              <div className="payment-method-container w-full">
                <p className="mb-2 min-w-[300px] text-center success-modal-text">
                  How would you like to pay?
                </p>
                <p className="success-modal-subtitle">
                  {`$${mintFeeUsd.toFixed(2)} USD (${ethers.utils.formatEther(mintFee)} ETH + Gas Fee)`}
                </p>
                <Button
                  label={`Credit Card`}
                  classNames="rounded btn-long colored !h-[60px]"
                  onClick={() => handleMintNow(PaymentMethods.CREDIT_CARD)}
                />
                <Button
                  label={`Metamask`}
                  classNames="rounded btn-long transparent !h-[60px]"
                  onClick={() => handleMintNow(PaymentMethods.ETH)}
                />
                <Button
                  label="I have a promo code"
                  classNames="promo-btn"
                  onClick={() => handleMintNow(PaymentMethods.PROMO)}
                />
              </div>
            </Modal>
          )}

          {paymentPromoMethodModalOpen && (
            <Modal
              size={384}
              disableOverlay={true}
              handleModal={handlePaymentMethodModal}
            >
              <div className="payment-method-container w-full">
                <p className="mb-6 min-w-[300px] text-center success-modal-text">
                  Please enter the promo?
                </p>
                <Input
                  placeholder="PROMO CODE"
                  className="rounded transparent !h-[60px]"
                  onChange={handlePromoCodeChange}
                />
                <Button
                  label="Promo"
                  classNames="rounded btn-long colored !h-[60px]"
                  onClick={triggerPromoMinting}
                />
              </div>
            </Modal>
          )}

          {paymentCreditCardMethodModalOpen && (
            <StripePayment
              amount={mintFeeUsd}
              handleModal={handleCreditCardPaymentMethodModal}
              onCreate={success}
              itemId={item.item_id}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Step5;
