import React, { useState } from "react";
import CopyIcon from "../../../elements/Icon/CopyIcon";
import CheckIcon from "../../../elements/Icon/CheckIcon";
import './style.scss';

const ProfileButton = (props?: any) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyAddress = () => {
    if (!isCopied) navigator.clipboard.writeText(props.address);
    setIsCopied(!isCopied);
  };

  return (
    <button
      className={`flex space-x-3 rounded-full hover:bg-gray-100 h-10 items-center px-3 w-52 profile-btn ${
        isCopied
          ? "copied"
          : ""
      }`}
      onClick={() => copyAddress()}
    >
      <span className="w-36">
        {isCopied
          ? "Copied!"
          : props.address
          ? props.address.slice(0, 8) +
            "...." +
            props.address.slice(props.address.length - 5, props.address.legnth)
          : "0x0"}
      </span>
      {isCopied ? <CheckIcon /> : <CopyIcon />}
    </button>
  );
};

export default ProfileButton;
