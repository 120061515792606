import { Buffer } from "buffer";
import { create } from "ipfs-http-client";
import decodeUri from "data-uri-to-buffer";

const projectId = '2E8ZGfQnLWW0bwmkdJd3YDNq52v';   // <---------- your Infura Project ID
const projectSecret = 'cce161cb0c3c3d30dd2da05a7e11bfcf';  // <---------- your Infura Secret
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64');
const client = create({
    host: "infura-ipfs.io",
    port: 5001,
    protocol: "https",
    apiPath: "/api/v0",
    headers: {
        authorization: auth,
    },
});

const uploadImageToIpfs = async (imageDataUrl: string) => {
    console.log('uploadImageToIpfs');
    const data = decodeUri(imageDataUrl);
    const file = Buffer.from(data);
    try {
        const created = await client.add(file);
        await client.pin.add(created.cid);
        const url = `https://ipfs.io/ipfs/${created.path}`;
        return url
    } catch (error) {
        return error;
    }
}

const uploadJSONToIpfs = async (metadata: string) => {
    console.log('uploadJSONToIpfs');
  
    try {
        const created = await client.add(metadata);
        await client.pin.add(created.cid);
        const url = `https://ipfs.io/ipfs/${created.path}`;
        return url
    } catch (error) {
        return error;
    }
}

export default {
    uploadImageToIpfs,
    uploadJSONToIpfs
}