import FullLayout from 'components/layout/FullLayout';
import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <FullLayout>
        <div className="static-page">
            <div className="mt-20 mb-20 px-4 max-w-4xl mx-auto h-full w-full flex items-center justify-center flex-col">
                <h1 className="page-title mt-12 text-center">Page Not Found</h1>
                <Link to="/" className="!mt-6 btn-long auto colored !h-[60px] !w-[320px] rounded-md flex item-center justify-center">Go to Homepage</Link>
            </div>
        </div>
        </FullLayout>
        
    );
};

export default NotFound;