import React from "react";
import FullLayout from "../../components/layout/FullLayout";

const MarketPlace = () => {
  return (
    <FullLayout>
      <div className="container mx-auto mt-6 mb-20">
        <div className="border-2 border-dashed bg-gray-50 rounded-xl p-10">
          <h1 className="h-56 text-center">Kingdom marketplace first drop goes here</h1>
        </div>
      </div>
    </FullLayout>
  );
};

export default MarketPlace;
