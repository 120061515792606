import React from "react";
import ProfileButton from "../ProfileButton";
import Button from "../../../elements/Button";
import ConnectIcon from "../../../elements/Icon/ConnectIcon";
import {profileCards} from "../../../../constants/profile";
import DetailCard from "../DetailCard";
import { Link } from 'react-router-dom';

interface IMyProfileDetailSectionProps {
  handleSocialModal: () => void;
}

const MyProfileDetailSection = ({ handleSocialModal }: IMyProfileDetailSectionProps) => {
  return (
    <div className="relative max-w-5xl mx-auto -mt-12 px-4">
      <div className="flex flex-col md:flex-row md:items-center w-full flex-wrap">
        <div className="relative group rounded-full overflow-hidden w-28 md:w-32 h-28 md:h-32 mb-4 md:mb-0">
          <img
            src="/assets/images/fake-1.png"
            alt="fake-1"
            className="w-28 md:w-32 h-28 md:h-32 rounded-full object-cover border-[3px] border-primary"
          />
        </div>
        <div className="flex flex-col md:flex-row md:ml-12 space-y-2 md:space-y-0 md:space-x-10 items-start md:items-center relative md:mt-8">
          <Link
            to="/my-profile-detail"
            className="block font-bold hover:underline"
          >
            Bella's profile
          </Link>
          <p>
            @username
          </p>
          <ProfileButton />
        </div>
        <div className="items-center relative ml-auto mt-8 hidden md:flex space-x-3">
          <Button
            leftIcon={<><ConnectIcon /></>}
            classNames="rounded-full w-12 inline-flex justify-center items-center"
            onClick={handleSocialModal}
          />
        </div>
      </div>
      <div className="rounded-2xl p-2 md:p-10 min-h-[400px] mt-6 bg-gray-100">
        {
          profileCards.length === 0 && (
            <p className="text-center w-full text-gothic">You have no profiles yet</p>
          )
        }
        <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
          {
            profileCards.map((card) => (
              <DetailCard
                key={card.image}
                data={card}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default MyProfileDetailSection;
