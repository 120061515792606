import React from "react";

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="12" height="12"
         viewBox="0 0 24 24" strokeWidth="5" stroke="currentColor" fill="none" strokeLinecap="round"
         strokeLinejoin="round">
      <path d="M5 12l5 5l10 -10"/>
    </svg>
  );
};

export default CheckIcon;
