import React from "react";
import DiamondIcon from "../../../../elements/Icon/DiamondIcon";
import Button from "../../../../elements/Button";
import { Link } from "react-router-dom";

const ModalStep4 = ({ itemId }) => {
  return (
    <div>
      <div className="flex flex-col h-full">
        <div>
          <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full bg-indigo-100 mt-3">
            <DiamondIcon />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="success-modal-text">Pet Post minted successfully!</h3>
          </div>
        </div>
        <div className="mt-3 space-y-4">
          <Link
            to={`/nfts/${itemId}`}
            className="btn-long colored rounded flex items-center justify-center tracking-wide !h-[60px]"
          >
            View
          </Link>
          <a
            href={`/create`}
            className="btn-long transparent rounded flex items-center justify-center tracking-wide !h-[60px]"
          >
            Make another Pet Post?
          </a>
        </div>
      </div>
    </div>
  );
};

export default ModalStep4;
