import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import httpService from "services/http.service";
import { loadStripe } from "@stripe/stripe-js";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import Modal from "../../elements/Modal";
import ElementProvider from "./ElementProvider";
import useNotification from "hooks/useNotification";

interface StripePaymentInterface {
  amount: number;
  handleModal: () => void;
  itemId: number;
  onCreate: (value: any) => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export enum PaymentMethods {
  CREDIT_CARD = "credit card",
  ETH = "eth",
  PROMO = "promo",
}

const StripePayment = ({
  amount,
  handleModal,
  itemId,
  onCreate,
}: StripePaymentInterface) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const notify = useNotification();

  useEffect(() => {
    console.log('tx fee in dollar', amount);
    const createPaymentIntent = async () => {
      const responseData = await httpService.post("/stripe/create-payment-intent", {
        price: amount,
        itemId: itemId,
        })
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          console.log('error', error)
          if (error.response) {
            notify(error.response.data.message, "error");
          } else if (error.request) {
            notify("request error", "error");
          } else {
            notify(error.message, "error");
          }
          handleModal();
        });
      // const responseData = (
      //   await httpService.post("/stripe/create-payment-intent", {
      //     price: amount,
      //     itemId: itemId,
      //   })
      // ).data;
      if (responseData) {
        setClientSecret(responseData.clientSecret);
        setPaymentIntent(responseData.payment_intent);
      }
    };

    createPaymentIntent();
  }, []);

  const appearance: any = {
    theme: "night",
    labels: "floating",
  };

  return (
    <Modal handleModal={handleModal} size={800}>
      <div>
        {clientSecret ? (
          <div>
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: clientSecret,
                appearance,
              }}
            >
              <ElementProvider
                itemId={itemId}
                handleModal={handleModal}
                onCreate={onCreate}
                paymentIntent={paymentIntent}
              />
            </Elements>
          </div>
        ) : (
          <SpinnerIcon />
        )}
      </div>
    </Modal>
  );
};

export default StripePayment;
