import React, {useEffect, useState} from "react";
import Input from "../../../elements/Input";
import Button from "../../../elements/Button";

interface IAccountStep4Props {
  onFrontStep: () => void;
}

const AccountStep4 = ({ onFrontStep }: IAccountStep4Props) => {
  const [userEmail, setUserEmail] = useState('');
  const [isEmail, setIsEmail] = useState(false);

  useEffect(() => {
    setIsEmail(userEmail.includes('.com')? true : userEmail.includes('.io')? true : userEmail.includes('.net'));
  }, [userEmail]);

  return (
    <div>
      <h2 className="mb-3 text-center text-xl text-dark-logo font-title">
        Connect your email
      </h2>
      <p className="mb-3 text-center">
        To stay up to date on the newest drops and events in Kingdom.
      </p>
      <div className="">
        <Input
          placeholder="Your email..."
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
      </div>
      <div className="my-4 text-center">
        {
          isEmail ? (
            <Button
              label="next"
              onClick={onFrontStep}
              classNames="rounded-2xl w-full font-title"
            />
          ) : (
            <Button
              label="complete"
              classNames="rounded-2xl w-full hover:bg-gray-100 font-title"
              bgColor="gray"
            />
          )
        }
        <Button
          variant="text"
          classNames="underline text-primary-light hover:text-primary mt-6 font-title"
          label="skip"
          onClick={onFrontStep}
        />
      </div>
    </div>
  );
};

export default AccountStep4;
