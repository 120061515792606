import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import './styles.scss';

interface ICustomLinkProps {
  href: string;
  label: string;
  classNames?: string;
}

const urls = [
  "/explore",
  "/users"
]

const CustomLink = ({
  href,
  label,
  classNames,
}: ICustomLinkProps) => {
  const history = useHistory();
  const [isActive, setIsActive] = useState(history.location.pathname === href);

  return (
    <Link
      to={href}
      className={`nav-link px-3 py-1 ${isActive ? "active" : ""} ${classNames}`}
    >
      {label}
    </Link>
  );
};

export default CustomLink;
