import React from "react";

const UploadIcon = () => {
  return (
    <svg
      className="mx-auto h-12 w-12 text-gray-400"
      stroke="currentColor"
      fill="none"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
      <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"/>
      <polyline points="9 15 12 12 15 15"/>
      <line x1="12" y1="12" x2="12" y2="21"/>
    </svg>
  );
};

export default UploadIcon;
