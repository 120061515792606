import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import history from "common/history";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";

import { store } from "./store/index";
import { Provider } from "react-redux";

import { HelmetProvider } from "react-helmet-async";
import { hydrate, render } from 'react-dom';
import { registerPlugin } from "react-filepond";

// Import the editor styles
import "@pqina/pintura/pintura.css";
 //Imprt filepond css
 import 'filepond/dist/filepond.min.css';

import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview)

const MyApp = (
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <Router history={history}>
          <SnackbarProvider maxSnack={3}>
            <App />
          </SnackbarProvider>
        </Router>
      </HelmetProvider>
    </Provider>
  </React.StrictMode>
)

const rootElement = document.getElementById('root');

if(rootElement.hasChildNodes){
  hydrate(MyApp, rootElement);
} else {
  render(MyApp, rootElement)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
