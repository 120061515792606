import React from "react";
import BadgeIcon from "../../../../elements/Icon/BadgeIcon";
import { Link } from 'react-router-dom';

interface IProfileCardProps {
  item: {
    count: number;
    no: number;
    title: string;
    floorPrice: number;
    likeCount: number,
    data: {
      value: string;
      color: string;
    };
    price: number;
    link: string;
    image_url: string;
  };
  index: number;
}

const ProfileCard = ({ item, index }: IProfileCardProps) => {
  return (
    <Link
      to={ item.link === "N/A" ? "" : `/pets${item.link}` }
      className="flex items-center p-4 w-full h-20 font-semibold leading-6 cursor-pointer box-border rounded profile-item-card"
    >
      <div className="count">{index + 1}</div>
      <div className="relative mx-4">
        <div className="mx-2 rounded-[50%]">
          <div className="flex overflow-hidden relative justify-center items-center w-13 max-w-full h-13 max-h-full cursor-pointer rounded-[50%]">
            <img
              src={item.image_url}
              alt="avatar"
              className="object-cover w-full"
            />
          </div>
        </div>
        {/* <div className="absolute h-2 right-[12.5%] bottom-[16%]">
          <BadgeIcon />
        </div> */}
      </div>
      <div className="flex overflow-hidden flex-col flex-1 justify-center items-start self-stretch mr-4">
        <h3 className="title w-full">
          {item.title}
        </h3>
        <span>
          <div className="flex items-center subtitle">
            <div className="mt-px mr-1 text-xs inner">{`${item.likeCount} likes`}</div>

            {/* <div className="flex items-center">
              <img
                src="/assets/images/eth.svg"
                alt="eth"
                className="object-contain w-3 h-3"
              />
              <span className="m-0 text-sm">{item.floorPrice}</span>
            </div> */}
          </div>
        </span>
      </div>
      {/* <div className="flex flex-col justify-end items-end">
        <span
          className={`m-0 text-sm font-normal leading-5 ${item.data.color}`}
        >
          {item.data.value}
        </span>
        <div className="flex items-center cursor-pointer">
          <img
            src="/assets/images/eth.svg"
            alt="eth"
            className="object-contain w-5 h-5"
          />
          <span className="text-sm leading-5 text-gray-400">{item.price}</span>
        </div>
      </div> */}
    </Link>
  );
};

export default ProfileCard;
