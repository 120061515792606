import React, { useEffect, useState } from "react";
import './style.scss';
import httpService from "services/http.service";
import { isInstagramLinked, isTiktokLinked, isTwitterLinked } from "common/utils";
import { Link } from "react-router-dom";


const ProfileInfoCard = ({ isPet, data }) => {
  const [type, setType] = useState('');
  const [breed, setBreed] = useState('');

  var getType = async (id) => {
    if (id) {
      const _type = (await httpService.get(`/type/by-id/${id}`)).data;
      if (_type) {
        setType(_type.name);
      }
    }
  };
  var getBreed = async (id) => {
    if (id) {
      const _breed = (await httpService.get(`/breed/by-id/${id}`)).data;
      if (_breed) {
        setBreed(_breed.name);
      }
    }
  };

  useEffect(() => {
    getType(data?.type_id);
    getBreed(data?.breed_id);
  }, [data])

  return (
    <div className="mb-6 rounded-2xl p-2 md:p-10 mt-6 border border-gray-300 infocard-container">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-4">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500 font-title text-poppins">Type</dt>
          <dd className="mt-1 text-sm">
            <span className="block py-2">
              <span className="border border-transparent border-l-0 h-6 block text-gothic">
                {type}
              </span>
            </span>
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500 font-title text-poppins">
            Breed
          </dt>
          <dd className="mt-1 text-sm">
            <span className="block py-2">
              <span className="border border-transparent border-l-0 h-6 block text-gothic">
                {breed}
              </span>
            </span>
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500 font-title text-poppins">
            Owner
          </dt>
          <dd className="mt-1 text-sm">
            <Link
              className={`inline-flex items-center pr-3 rounded-full border border-gray-300 bg-white space-x-2 hover:border-primary ${data?.owner?.display_name ? "" : "pointer-events-none"}`}
              to={
                {     
                  pathname: `/users/${data?.owner?.display_name}`,
                  state: {
                    address: data?.owner?.user_address
                  },
                }
              }
            >
              <img
                src={data?.owner?.profile_url ? data?.owner?.profile_url : "/assets/images/cat.png"}
                alt=""
                className="w-10 h-10 rounded-full"
              />{" "}
              <span className="text-gothic">{(data?.owner?.first_name || data?.owner?.last_name) ? `${data?.owner?.first_name} ${data?.owner?.last_name}` : 'N/A'}</span>
            </Link>
          </dd>{" "}
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500 font-title text-poppins">
            Get social
          </dt>{" "}
          {(isInstagramLinked(data?.instagram_link) || isTwitterLinked(data?.twitter_link) || isTiktokLinked(data?.tiktok_link)) ? 
          <dd className="mt-1 text-sm">
            <div className="flex justify-start space-x-6">
              {isInstagramLinked(data?.instagram_link) && 
                            <a
                            href={data?.instagram_link}
                            target={"_blank"}
                            rel="noreferrer"
                            className="text-primary w-10 h-10 border relative rounded-full flex items-center justify-center user-social-link"
                          >
                            <span className="sr-only">Instagram</span>{" "}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </a>
              }
              {" "}
              {isTiktokLinked(data?.tiktok_link) && 
              <a
                href={data?.tiktok_link}
                target={"_blank"}
                rel="noreferrer"
                className="text-primary w-10 h-10 border relative rounded-full flex items-center justify-center user-social-link"
              >
                <span className="sr-only">Tiktok</span>{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M20.84,10.18A8.28,8.28,0,0,1,16,8.63v7.06A6.42,6.42,0,1,1,9.58,9.27a6.51,6.51,0,0,1,.88.06v3.55a2.95,2.95,0,1,0,1.92,3.69,2.89,2.89,0,0,0,.14-.88V1.89H16a5.14,5.14,0,0,0,.08.88h0A4.81,4.81,0,0,0,18.2,5.94a4.75,4.75,0,0,0,2.64.79Z"></path>
                </svg>
              </a>}
              {" "}
              {isTwitterLinked(data?.twitter_link) &&
              <a
              href={data?.twitter_link}
              target={"_blank"}
              rel="noreferrer"
              className="text-primary w-10 h-10 border relative rounded-full flex items-center justify-center user-social-link"
            >
              <span className="sr-only">Twitter</span>{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
              </svg>
              </a>
              }
              
            </div>
          </dd> :
          <dd className="mt-1 text-sm">
          <span className="block py-2">
            <span className="border border-transparent border-l-0 h-6 block text-gothic">
              No social added
            </span>
          </span>
          </dd>
          }
          {" "}
        </div>
        <div className="sm:col-span-2 md:col-span-4">
          <dt className="text-sm font-medium text-gray-500 font-title text-poppins">
            About
          </dt>{" "}
          <dd className="mt-1 space-y-5 text-sm py-2 text-gothic">
            <p>
              {data?.description ? data?.description : 'N/A'}
            </p>
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default ProfileInfoCard;
