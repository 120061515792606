import React from "react";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";

const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // add action to an individual snackbar
  const action = (snackbarId) => (
    <>
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
      >
        Dismiss
      </Button>
    </>
  );

  const notify = (message, variant) => {
    try {
      enqueueSnackbar(message, {
        variant: variant,
        autoHideDuration: 2000,
        action,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
    } catch {}
  };

  return notify;
};

export default useNotification;
