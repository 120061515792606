import React, { useEffect, useState } from "react";
import Steps from "../../components/modules/Create/Steps";
import Step1 from "../../components/modules/Create/Step1";
import Step2 from "../../components/modules/Create/Step2";
import Step3 from "../../components/modules/Create/Step3";
import Step4 from "../../components/modules/Create/Step4";
import Step5 from "../../components/modules/Create/Step5";
import FullLayout from "../../components/layout/FullLayout";
import httpService from "services/http.service";
import ipfsService from "services/ipfs.service";
import imageService from "services/image.service";
import "./style.scss";
import useNotification from "hooks/useNotification";
import { IPetType } from "../../components/modules/Create/Step1";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import { Helmet } from "react-helmet-async";

const Create = () => {
  const [step, setStep] = useState(1);
  const [image, setImage] = useState<any>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [selected, setSelected] = useState<null | number>(null); // category
  const [profile, setProfile] = useState<IPetType>(null);
  const [item, setItem] = useState(null);
  const [isProcessingCurrentStep, setIsProcessingCurrentStep] = useState(false);
  const [topTags, setTopTags] = useState([]);
  const notify = useNotification();

  const createNft = async () => {
    setIsProcessingCurrentStep(true);
    // console.log('selectedTags', selectedTags);
    // console.log('profile', profile);
    // console.log('local', window.location.origin);
    const imageUrl = await imageService.uploadImage(image);
    let initialSubmitData = null;
    try {
      initialSubmitData = (
        await httpService.post(`/item/create-item`, {
          profileId: profile.petProfileId.toString(),
          categoryId: selected.toString(),
          name: title.toString(),
          url: imageUrl,
          description: description.toString(),
          tagIds: selectedTags,
          itemId: item ? item.item_id : 0,
          token_url: new Date().getTime().toString()
        })
      )?.data;
      
    } catch (error) {
      notify(error.response.data.error, "error");
      setIsProcessingCurrentStep(false);
      throw new Error(error.response.data.error);
    }
    setItem(initialSubmitData);

    console.log('initialSubmitData', initialSubmitData)
    const metadata = {
      name: title.toString(),
      description: description.toString(),
      image: imageUrl,
      external_url: window.location.origin+'/nfts/'+initialSubmitData.item_id,
    };
    const attributes = [];

    attributes.push({
      "trait_type": "Profile name", 
      "value": profile.petName,
    });
    attributes.push({
      "trait_type": "Profile Url", 
      "value": window.location.origin + '/pets' + profile.url,
    });
    attributes.push({
      "trait_type": "Pet type", 
      "value": profile.type.name,
    });
    if (profile.breed1) {
      if (profile.breed2){
        attributes.push({
          "trait_type": "Breed", 
          "value": profile.breed1?.name + ' ' + profile.breed2.name,
        });
      } else {
        attributes.push({
          "trait_type": "Breed", 
          "value": profile.breed1?.name,
        });
      }
    }
    if (selectedTags.length > 0) {
      let tags = '';
      selectedTags.forEach(_tag => {
        const t = topTags.filter((tag) => tag.tag_id === _tag);
        if (!tags) {
          tags = t[0].name;
        } else {
          tags += ', ' + t[0].name;
        }
      });
      if (tags){
        attributes.push({
          "trait_type": "Tags", 
          "value": tags,
        });
      }
    }

    metadata['attributes'] = attributes;
    console.log('metadata', metadata);

    const metaDataUrl = await ipfsService.uploadJSONToIpfs(JSON.stringify(metadata));
    console.log('metaDataUrl', metaDataUrl);


    let responseData = null;
    try {
      responseData = (
        await httpService.post(`/item/create-item`, {
          profileId: profile.petProfileId.toString(),
          categoryId: selected.toString(),
          name: title.toString(),
          url: imageUrl,
          description: description.toString(),
          tagIds: selectedTags,
          itemId: initialSubmitData.item_id,
          token_url: metaDataUrl
        })
      )?.data;
      setIsProcessingCurrentStep(false);
    } catch (error) {
      notify(error.response.data.error, "error");
      setIsProcessingCurrentStep(false);
      throw new Error(error.response.data.error);
    }
    setStep(step + 1);
    console.log("received ==> ", responseData);
    setItem(responseData);
  };

  const checkName = async () => {
    setIsProcessingCurrentStep(true);
    const responseData = (
      await httpService.get(`/item/name-available?profileId=${profile?.petProfileId}&name=${title}&itemId=${item ? item.item_id : 0}`)
    )?.data;

    console.log('name responseData', responseData)
    if (responseData) {
      setIsProcessingCurrentStep(false);
      setStep(step + 1);
    } else {
      setIsProcessingCurrentStep(false);
      notify('Name has already been taken.', 'error');
    }
  }

  const onChangeStep = () => {
    if (step === 3) {
      createNft();
    } else if (step === 2) {
      checkName();
    } else if (step < 4) {
      setStep(step + 1);
      setIsProcessingCurrentStep(false);
    }
  };

  const onBackStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const renderTitle = (step: number) => {
    switch (step) {
      case 1:
        return "Select Pet Profile";
      case 2:
        return "Details";
      case 3:
        return "Make it fun";
      case 4:
        return "Success!";
    }
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <Step1
            onChangeStep={onChangeStep}
            profileInfo={profile}
            handleProfile={(value: IPetType) => setProfile(value)}
          />
        );
      // case 2:
      //   return (
      //     <Step2
      //       onChangeStep={onChangeStep}
      //       selectedInfo={selected}
      //       handleSelected={(value: number) => setSelected(value)}
      //     />
      //   );
      case 2:
        return (
          <Step3
            selectedInfo={selected}
            onChangeStep={onChangeStep}
            imageInfo={image}
            handleImage={(value: any) => {
              setImage(value)
            }}
            titleInfo={title}
            handleTitle={(value: string) => setTitle(value)}
            descriptionInfo={description}
            handleDescription={(value: string) => setDescription(value)}
            tagInfo={selectedTags}
            handleTag={(tagIds) => setSelectedTags(tagIds)}
            profileInfo={profile}
            isProcessingCurrentStep={isProcessingCurrentStep}
            topTags={topTags}
            setTopTags={setTopTags}
          />
        );
      case 3:
        return (
          <Step4
            selectedInfo={selected}
            onChangeStep={onChangeStep}
            imageInfo={image}
            titleInfo={title}
            setImage={setImage}
            profileInfo={profile}
            isNextInProgress={isProcessingCurrentStep}
          />
        );
      case 4:
        return (
          <Step5
            selectedInfo={selected}
            onChangeStep={onChangeStep}
            imageInfo={image}
            titleInfo={title}
            item={item}
            profileInfo={profile}
          />
        );
    }
  };

  const getCategories = async () => {
    const responseData = (await httpService.get(`category/all-categories`))
      .data;
    setSelected(responseData[0]?.category_id)
  };

  useEffect(()=> {
    getCategories();
  }, []);

  return (
    <FullLayout footerLocked={true}>
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>Create | KINGDOM</title>
        <meta property="og:title" content="Make a new post" />
        <meta name="description" content="Add your pet's amazing content to KINGDOM." />
        <meta property="og:description" content="Add your pet's amazing content to KINGDOM." />
        <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
      <div className="container mx-auto mt-6 mb-28 create-page px-4">
        {
          isProcessingCurrentStep && <div className="h-screen w-screen fixed inset-0 flex items-center justify-center z-40 bg-opacity-75 bg-gray-500 !mt-0">
            <SpinnerIcon className="my-auto !text-white" />
          </div>
        }
        <div className="max-w-3xl mx-auto mt-12">
          <Steps step={step} onClick={onBackStep} />
          <h1 className="font-title font-bold text-3xl mt-3 page-title">
            {renderTitle(step)}
          </h1>
          {renderContent(step)}
        </div>
      </div>
      </>
    </FullLayout>
  );
};

export default Create;
