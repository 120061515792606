import React from "react";
import Button from "../../../elements/Button";
import CustomLink from "../../../elements/CustomLink";
import { useSelector } from "react-redux";
import { RootState } from "store";
import history from "common/history";
const BlockchainSection = () => {
  const userAddress = useSelector((state: RootState) => state.wallet.address);


  return (
    <div className="container mx-auto blockchain-section">
      <div className="grid grid-cols-1 md:grid-cols-2 mb-20">
        <div className="order-2 md:order-1 mt-4 sm:mt-5 md:mt-12">
          <h1 className="!leading-snug tracking-tight blockchain-title">
            Where pets live forever {" "}
            {/* <span className="ml-3 text-primary"> */}
              on the blockchain
            {/* </span> */}
          </h1>
          <p className="mt-3 sm:mt-5 max-w-md blockchain-subtitle">
          The next-gen social platform dedicated to your forever friends. Create your Kingdom and earn rewards along the way. Royalty has its perks.
          </p>
          <div className="mt-12 flex flex-col sm:justify-center lg:justify-start md:flex-row">
            <Button
              label="Create your kingdom"
              classNames="rounded btn-long colored create !mt-0"
              onClick={() => {
                if (userAddress) {
                  history.push('/create');
                } else {
                  document.getElementById("connectButton").click();
                }
              }}
            />
            <CustomLink
              href="/explore"
              label="Explore"
              classNames="!mt-0 ml-0- md:ml-6 rounded text-center btn-long colored blue explore flex items-center justify-center"
            />
          </div>
        </div>
        <div className="p-2 md:mt-6 order-1 md:order-2">
          <img
            src="/assets/images/kingdom2.png"
            alt="kingdom"
            className="object-cover w-full max-w-lg ml-auto rounded-3xl"
          />
        </div>
      </div>
      {/* <a
        href="/"
        className="cat-board w-full flex flex-col md:flex-row items-center justify-between bg-tertiary hover:bg-tertiary-light rounded-3xl transition-all duration-300 shadow hover:shadow-2xl "
      >
        <span className="font-title text-3xl p-8 font-bold tracking-wide leading-relaxed text-white max-w-xl block">
          Join the whitelist to receive our exclusive Paw Pass NFT
        </span>
        <img
          src="/assets/images/cat.png"
          alt="cat"
          className="md:mr-4 -mt-12 md:mt-0 w-44 h-44"
        />
      </a> */}
      <div className="rounded-cards overflow-hidden relative px-6 py-10 bg-primary shadow-xl join-us mt-20" style={{backgroundImage: `url("/assets/images/bg-gradient.png")`}}>
        <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
          <svg className="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
            <path className="text-primary text-opacity-20" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
            <path className="text-primary-light text-opacity-10" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
          </svg>
          </div> 
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:py-8 lg:px-8 lg:flex lg:items-center lg:justify-between relative z-10">
            <h2 className="text-3xl font-bold font-title tracking-tight text-white md:text-4xl">
              <span className="title">Stay up to date on Kingdom</span> 
              <span className="block title colored">Participate for Royal Rewards.</span>
            </h2>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <a href="https://discord.gg/5prWUU8wsY" target={"_blank"} rel="noreferrer" className="rounded btn-long colored blue flex items-center justify-center !w-[176px] !mt-0">Join us on Discord</a>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default BlockchainSection;
