import React, { useEffect, useState } from "react";
import Select from "../../../elements/Select";
import Button from "../../../elements/Button";
import httpService from "services/http.service";
import "./styles.scss";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";

interface IProfileStep2Props {
  onFrontStep: () => void;
  petTypeInfo: string;
  handlePetType: (value: string) => void;
  petBreedInfo: string;
  handlePetBreed: (value: string) => void;
  petSecondaryBreedInfo: string;
  handlePetSecondaryBreed: (value: string) => void;
  isCreateInProgress: boolean;
}

const ProfileStep2 = ({
  onFrontStep,
  petTypeInfo,
  handlePetType,
  petBreedInfo,
  handlePetBreed,
  petSecondaryBreedInfo,
  handlePetSecondaryBreed,
  isCreateInProgress,
}: IProfileStep2Props) => {
  const [breedsData, setBreedsData] = useState(null);
  const [petBreeds, setBreeds] = useState<string[]>([]);
  const [secondarybreedsData, setSecondaryBreedsData] = useState(null);
  const [petSecondaryBreeds, setSecondaryBreeds] = useState<string[]>([]);
  const [typesData, setTypesData] = useState(null);
  const [petTypes, setPetTypes] = useState<string[]>([]);
  const [showSecondaryBreed, setShowSecondaryBreed] = useState(false);
  let isCreateClicked = false;

  useEffect(() => {
    const getTypes = async () => {
      const responseData = (await httpService.get(`type/types`)).data;
      setTypesData(responseData);
      setPetTypes(responseData.map((typeElement) => typeElement.name));
    };
    getTypes();
  }, []);

  const getBreeds = async () => {
    const responseData = (
      await httpService.get(
        `/breed/breed-by-type-id?` + `typeId=${petTypeInfo}`
      )
    ).data;

    setBreedsData(responseData);
    setSecondaryBreedsData(responseData);

    setBreeds(responseData.map((breedEle) => breedEle.name));
    setSecondaryBreeds(responseData.map((breedEle) => breedEle.name));
  };
  useEffect(() => {
    if (petTypeInfo) {
      getBreeds();
    }
  }, [petTypeInfo]);

  const createPet = () => {
    if (!isCreateClicked) {
      isCreateClicked = true;
      onFrontStep();
    }
  };

  return (
    <div className="pet-profile-2">
      {
        isCreateInProgress && (
          <div className="h-screen w-screen fixed inset-0 flex items-center justify-center z-10 bg-opacity-75 bg-gray-500">
            <SpinnerIcon className="my-auto text-white" />
          </div>
        )
      }
      <h2 className="mb-3 text-center title">Pet details</h2>
      <Select
        options={petTypes}
        placeholder="Select pet type"
        title="Pet type"
        inputClass="!rounded-full !justify-center"
        value={
          typesData?.filter((type) => type.type_id === petTypeInfo)[0]?.name
        }
        handleValue={(value) =>
          handlePetType(
            typesData?.filter((type) => type.name === value)[0]?.type_id
          )
        }
      />
      {petTypeInfo && (
        <>
          <Select
            className="my-4"
            inputClass="!rounded-full !justify-center"
            options={petBreeds}
            placeholder={`Select ${
              typesData?.filter((type) => type.type_id === petTypeInfo)[0]?.name
            } breed`}
            title={`${
              typesData?.filter((type) => type.type_id === petTypeInfo)[0]?.name
            } breed`}
            handleValue={(value) =>
              handlePetBreed(
                breedsData?.filter((breed) => breed.name === value)[0]?.breed_id
              )
            }
            value={
              breedsData?.filter((breed) => breed.breed_id === petBreedInfo)[0]
                ?.name
            }
          />
          {petBreedInfo ? (
            <>
              {petBreedInfo !== "I don't know" && (
                <>
                  {showSecondaryBreed ? (
                    <Select
                      className="my-4"
                      inputClass="!rounded-full !justify-center"
                      options={petSecondaryBreeds}
                      placeholder={`Select ${
                        typesData?.filter(
                          (type) => type.type_id === petTypeInfo
                        )[0]?.name
                      } Secondary breed`}
                      title={`${
                        typesData?.filter(
                          (type) => type.type_id === petTypeInfo
                        )[0]?.name
                      } Secondary breed`}
                      handleValue={(value) =>
                        handlePetSecondaryBreed(
                          secondarybreedsData?.filter(
                            (breed) => breed.name === value
                          )[0]?.breed_id
                        )
                      }
                      value={
                        secondarybreedsData?.filter(
                          (breed) => breed.breed_id === petSecondaryBreedInfo
                        )[0]?.name
                      }
                    />
                  ) : (
                    <Button
                      variant="text"
                      label="add another breed"
                      classNames="add-another-breed-btn mx-auto flex"
                      onClick={() => {
                        setShowSecondaryBreed(true);
                      }}
                    />
                  )}
                </>
              )}
              <Button
                classNames="rounded w-full my-4 !h-[60px] btn-long colored"
                label="create"
                onClick={createPet}
              />
            </>
          ) : (
            <Button
              classNames="rounded w-full my-4 btn-long white-colored !h-[60px]"
              label="create"
              bgColor="gray"
            />
          )}
        </>
      )}
    </div>
  );
};

export default ProfileStep2;
