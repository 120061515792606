import React, {ChangeEventHandler, ReactElement, useState} from "react";
import './styles.scss';

interface ISocialInputProps {
  placeholder?: string;
  type?: 'text' | 'number' | 'password';
  className?: string;
  title?: string;
  value?: string | number;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  icon: ReactElement;
}

const SocialInput = ({
  placeholder = '',
  type = 'text',
  className,
  title,
  value,
  onChange,
  name,
  icon
}: ISocialInputProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  const handleBlur = (e: any) => {
    setFocus(false);
  };

  return (
    <div className="w-full relative social-input">
      {
        title && <label className="block font-medium text-sm text-gray-700 mb-1">{title}</label>
      }
      <input
        type={type}
        placeholder={placeholder}
        className={`border-2 w-full rounded-xl focus:outline-none pl-10 py-3 h-12 ${className}`}
        onChange={onChange}
        value={value}
        name={name}
        onBlur={handleBlur}
        onFocus={() => setFocus(true)}
      />
      <div className={`icon absolute top-10 left-4 ${focus ? 'focussed' : ''}`}>
        {icon}
      </div>
    </div>
  );
};

export default SocialInput;
