import React from "react";
import './style.scss';

export interface ITab {
  label: string;
  value: string;
}

interface ITabProps {
  tabs: ITab[];
  activeTab: string;
  onChange: (tab: string) => void;
  className?: string;
  containerClass?: string;
}

const Tabs = ({ tabs, activeTab, onChange, className, containerClass }: ITabProps) => {
  return (
    <div className={`flex justify-center items-center custom-tab ${containerClass}`}>
      {
        tabs.map((tab) => (
          <div
            className={`${
              activeTab === tab.value ? 'active' : ''
            } tab-label hover:text-dark-logo mr-8 flex cursor-pointer whitespace-nowrap mb-2 sm:mb-0 w-32 flex justify-center text-xl
            ${className}`
            }
            key={tab.value}
            onClick={() => onChange(tab.value)}
            aria-hidden
          >
            {tab.label}
          </div>
        ))
      }
    </div>
  );
};

export default Tabs;
