import React, {ReactNode} from "react";
import Header from "../../modules/Header";
import Footer from "../../modules/Footer";

interface IFullLayoutProps {
  children: ReactNode;
  headerLocked?: boolean;
  footerLocked?: boolean;
}

const FullLayout = ({
  children,
  headerLocked = false,
  footerLocked = false
}: IFullLayoutProps) => {
  return (
    <div className="flex flex-col justify-between min-h-screen">
      <Header locked={headerLocked} />
      <div className="flex-grow">
        {children}
      </div>
      {
        !footerLocked && <Footer />
      }
    </div>
  );
};

export default FullLayout;
