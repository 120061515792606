import React, { useEffect, useState } from "react";
import httpService from "services/http.service";
import CustomDrawer from "../../components/elements/CustomDrawer";
import FullLayout from "../../components/layout/FullLayout";
import ExploreItemsContainer from "../../components/modules/Explore/ExploreItemsContainer/ExploreItemsContainer";
import ExploreProfilesContainer from "../../components/modules/Explore/ExploreItemsContainer/ExploreProfilesContainer";
import ExploreSidebar from "../../components/modules/Explore/ExploreSidebar/ExploreSidebar";
import Pagination from "./../../components/elements/Pagination/Pagination";
import { useSelector } from "react-redux";
import { sortOptions } from "constants/filter";
import { Helmet } from "react-helmet-async";

const Explore = (props) => {
  const [open, setOpen] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(9);
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");
  const [types, setTypes] = useState([]);
  const [orderBy, setOrderBy] = useState(sortOptions[0]);
  const searchKeyword = useSelector((state) => state.search.value);
  const [isExploreDataFetching, setIsExploreDataFetching] = useState(false);

  const [data, setData] = useState(null);

  const strTags = () => {
    let str = "";
    tags.forEach((element) => {
      str += element + ",";
    });

    return str.slice(0, str.length - 1);
  };

  const strTypes = () => {
    let str = "";
    types.forEach((element) => {
      str += element + ",";
    });
    return str.slice(0, str.length - 1);
  };

  useEffect(() => {
    const fetchTagId = (tagId) => {
      setTags([tagId]);
    };
    const fetchTypeId = (typeId) => {
      setTypes([typeId]);
    };

    if (props?.match?.params?.tagId) fetchTagId(props.match.params.tagId);
    if (props?.match?.params?.typeId) fetchTypeId(props.match.params.typeId);
  }, []);

  const getExploreData = async () => {
    setIsExploreDataFetching(true);
    console.log('getExploreData', orderBy)
    const responseData = (
      await httpService.get(
        `/item/items-for-explore?` +
          `skip=${pageNumber}` +
          `&take=${itemPerPage}` +
          `&tagIds=${strTags()}` +
          `&name=${search}` +
          `&typeIds=${strTypes()}` +
          `&orderBy=${orderBy.value}`
      )
    ).data;
    // console.log(responseData);
    setData(responseData);
    setIsExploreDataFetching(false);
  };

  useEffect(() => {
    getExploreData();
  }, [types, tags, search, orderBy, itemPerPage, pageNumber]);

  const handlePageClick = (event) => {
    console.log('handlePageClick', event);
    setPageNumber(event.selected);
  };

  return (
    <FullLayout>
      <>
        <Helmet>
          <title>Explore | KINGDOM</title>
          <meta property="og:title" content="Explore pet posts" />
          <meta name="description" content="See top posts from pets around the world on KINGDOM, The next-gen social platform dedicated to your forever friends." />
          <meta property="og:description" content="See top posts from pets around the world on KINGDOM, The next-gen social platform dedicated to your forever friends." />
          <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
        </Helmet>

        <div className="page-container">
        <div className="flex px-4">
          <CustomDrawer
            open={open}
            setOpen={setOpen}
            classNames="block md:hidden"
          >
            <ExploreSidebar
              tags={tags}
              types={types}
              setTags={setTags}
              search={search}
              setSearch={setSearch}
              setTypes={setTypes}
            />
          </CustomDrawer>
          <div className="hidden md:block p-0 m-0 h-full bg-white lg:w-2/6 md:w-1/2">
            <ExploreSidebar
              tags={tags}
              types={types}
              setTags={setTags}
              search={search}
              setSearch={setSearch}
              setTypes={setTypes}
            />
          </div>
          <div className="flex flex-col w-full py-4 md:py-12 md:pl-12 md:w-1/2 lg:w-3/4">
            {/* <ExploreProfilesContainer
              setDrawerOpen={setOpen}
            /> */}

            <ExploreItemsContainer
              setDrawerOpen={setOpen}
              setOrderBy={setOrderBy}
              setPageNumber={setPageNumber}
              setItemPerPage={setItemPerPage}
              data={data}
              pageNumber={pageNumber}
              itemPerPage={itemPerPage}
              isExploreDataFetching={isExploreDataFetching}
            />
          </div>
        </div>
        <div className="block w-full px-4">
          <hr />
          <Pagination
            nextLabel=">"
            prevLabel="<"
            total={data?.count}
            handlePageClick={handlePageClick}
            itemPerPage={itemPerPage}
            pageSelected={pageNumber}
          />
        </div>
      </div>
      </>
      
    </FullLayout>
  );
};

export default Explore;
