import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import React, {useRef, useState} from "react";
import './style.scss'

interface IAvatarSelectProps {
  avatarInfo: any;
  handleAvatar;
  showLabel?: Boolean;
  className?: string;
  isAvatarLoading?:boolean;
}

const AvatarSelect = ({ avatarInfo, handleAvatar, showLabel=true, className="", isAvatarLoading=false }: IAvatarSelectProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const onFileSelected =(e: any, type: string)=>{
    let image: any;
    if(type === "drop"){
      e.preventDefault();
      image = e.dataTransfer.files[0];
    } else {
      image = e.target.files[0];
    }
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      handleAvatar(reader.result);
    };
  };

  function doit() {
    ref.current?.click();
  }

  return (
    <>
      <div className={`w-full mb-3 avatar-select ${className}`}>
        {
          showLabel && (
            <label
              htmlFor="Pet avatar"
              className="block font-medium text-sm text-gray-700 dark:text-neutral-300"
            >
              Pet avatar
            </label>
          )
        }

        

        <div 
          onClick={doit} 
          onDrop={(e) => onFileSelected(e, "drop")} 
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragEnter={(e) => {
            e.preventDefault();
          }}
          className="image-container h-24 w-24 rounded-full cursor-pointer avatar-container overflow-hidden">
          {
            isAvatarLoading ? (
              <div className="h-24 w-24 rounded-full absolute flex items-center justify-center">
                <SpinnerIcon className="my-auto  !h-8 !w-8" />
              </div>
            ) : avatarInfo ? (
              <>
                <img
                  src={avatarInfo}
                  className="rounded-full h-24 w-24 cursor-pointer"
                  alt="avatar"
                /> 
                <button className="absolute inset-0 bg-white/70 opacity-0 group-hover:opacity-100 flex items-center justify-center text-gothic rounded-full change h-24 w-24">
                  <span className="w-16">Change avatar</span>
                </button>
              
              </>
            ): <p className="avatar-title">Choose</p>
          }
          
        </div>
      </div>
      <input
        onChange={(e) => onFileSelected(e, "")}
        className="hidden"
        type="file"
        ref={ref}
      />
    </>
  );
};

export default AvatarSelect;
