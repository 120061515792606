import React from "react";

const DiamondIcon = () => {
  return (
    <svg className="h-16 w-16 diamond-icon" xmlns="http://www.w3.org/2000/svg" fill="none" strokeWidth="1.5"
         viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
      <path d="M6 5h12l3 5l-8.5 9.5a0.7 .7 0 0 1 -1 0l-8.5 -9.5l3 -5z"/>
    </svg>
  );
};

export default DiamondIcon;
