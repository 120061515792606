import React from 'react';
import SingleSection from './SingleSection';

const sections = [
    {
        id: 1,
        topText: 'Transform your pet',
        title: 'Create your Kingdom',
        subtitle: "Make a profile for your pet & use the Kingdom Pet Editor to design, mint and post your pet's content on the blockchain.",
        btnColor: 'primary',
        btnText: ['Create your Kingdom'],
        imageUrl: '/assets/images/static-content-1.svg',
    },
    {
        id: 2,
        topText: 'View & Like other pets',
        title: 'Explore profiles',
        subtitle: "Browse blockchain pet content posted on Kingdom. Search by type, breed and tags.",
        btnColor: 'primary',
        btnText: ['Explore'],
        imageUrl: '/assets/images/static-content-2.svg',
    },
    {
        id: 3,
        topText: 'GEt likes & earn $UPI',
        title: 'Earn $UPI rewards',
        subtitle: "Your minted blockchain pet content can be included in our ongoing Kingdom Pet Competitions to win $UPI (Universal Pet Income) tokens.",
        btnColor: '',
        btnText: [],
        imageUrl: '/assets/images/static-content-3.svg',
    },
    {
        id: 4,
        topText: 'Powered by pawtocol',
        title: 'Grow your $UPI',
        subtitle: "Make your winnings count by trading $UPI or donating it to animal welfare organizations in need through the Pawtocol Pet Fund. This helps Pawtocol to grow and do more good for pets and animals everywhere.",
        btnColor: 'secondary',
        btnText: ["Donate $UPI"],
        imageUrl: '/assets/images/static-content-4.svg',
    },
]

const StepsSection = () => {
    return (
        <>
        {
            sections.map((item: any, index: number) => <SingleSection section={item}/>)
            
        }
        </>
    );
};

export default StepsSection;