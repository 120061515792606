import S3 from "react-aws-s3";

const S3Config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_ID,
};

const uploadImageToS3 = async (imageDataUrl: string) => {
  var image = new Image();
  image.src = imageDataUrl;

  const url = await new Promise<string>((resolve, reject) => {
    fetch(image.src)
      .then((res) => res.blob())
      .then(async (blob) => {
        const file = new File([blob], new Date().getTime().toString(), blob);
        const ReactS3Client = new S3(S3Config);
        await ReactS3Client.uploadFile(file, file.name)
          .then(async (data) => {
            console.log("data", data);
            if (data.status === 204) {
              console.log("1", data.location);
              resolve(data.location);
            }
          })
          .catch((error) => {
            console.log("2", error);
            reject(error);
          });
      });
  });
  console.log("4", url);
  return url;
};

export default {
  uploadImageToS3,
};
