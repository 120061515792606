export const profileCards = [
  {
    title: 'Walk me!',
    pet: 'Bella',
    price: '.12',
    likes: 52,
    image: 'fake-1'
  },
  {
    title: 'Sitting Pretty',
    pet: 'Lucy',
    price: '.05',
    likes: 41,
    image: 'fake-2'
  },
  {
    title: 'Wanna play?',
    pet: 'Charlie',
    price: '.99',
    likes: 12,
    image: 'fake-3'
  },
];

export const purchasedCards = [{
  title: 'Kingdom',
  image: 'kingdom',
  pet: 'Kingdom',
  price: '.12',
  likes: '220K'
}];

export const likedCards = [{
  title: 'Oops',
  image: 'oops',
  pet: 'Clara',
  price: '.12',
  likes: '7.5K'
},
{
  title: 'Sup',
  image: 'sup',
  pet: 'Lucy',
  price: '.05',
  likes: '15.9K'
}
];

export const editProfileItems = [
  {
    title: "Display name",
    value: "simple-gold",
    name: "display",
    sluggish: true,
  },
  {
    title: "First name",
    value: "Simple",
    name: "first"
  },
  {
    title: "Last name",
    value: "Gold",
    name: "last"
  },
  {
    title: "Email",
    name: "email",
    placeholder: "Enter email here",
    hint: "Enter your email to receive marketplace notifications"
  }
];

export const editAccountItems = [
  {
    title: "Pet profile name",
    name: "name"
  },
  {
    title: "Pet profile URL",
    name: "url"
  },
]
