import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface WalletState {
  type: string,
  address: string,
  signer: any,
  provider: any
}
export interface WalletReconnect {
  signer: any,
  provider: any
}

const initialState: WalletState = {
  type: '',
  address: '',
  signer: '',
  provider: '',
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    connect: (state, action: PayloadAction<WalletState>) => {
      state.type = action.payload.type
      state.address = action.payload.address
      state.signer = action.payload.signer
      state.provider = action.payload.provider
    },
    disconnect: (state) => {
      state.type = ""
      state.address = ""
      state.signer = null
      state.provider = null
    },
    reconnect: (state, action: PayloadAction<WalletReconnect>) => {
      state.signer = action.payload.signer
      state.provider = action.payload.provider
    }
  }
})

export const { connect, disconnect, reconnect } = walletSlice.actions

export default walletSlice.reducer