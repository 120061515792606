import React, {ReactElement, useMemo} from "react";
import classnames from 'classnames';

type ButtonVariant = 'outlined' | 'text' | 'standard';
type ButtonBgColor = 'standard' | 'gray' | 'red';

interface IButtonProps {
  id?: string;
  onClick?: () => void;
  label?: string;
  variant?: ButtonVariant;
  bgColor?: ButtonBgColor;
  classNames?: string;
  leftIcon?: ReactElement;
  rightIcon?: ReactElement;
  isDisabled?: boolean;
}

const Button = ({
  onClick,
  label = '',
  variant = 'standard',
  bgColor = 'standard',
  classNames,
  leftIcon,
  rightIcon,
  isDisabled = false,
  id = null
}: IButtonProps) => {
  const buttonTheme = useMemo(() => {
    const classes: string[] = [];
    switch (variant) {
      case 'standard':
        if (bgColor === 'standard') {
          classes.push('bg-primary hover:bg-primary-light text-white border border-transparent tracking-wide');
        } else if (bgColor === 'gray') {
          classes.push('bg-gray-100 hover:bg-red-200 text-black border border-transparent tracking-wide');
        } else if (bgColor === 'red') {
          classes.push('bg-red-200 hover:bg-red-300 text-black border border-transparent tracking-wide');
        }
        break;
      case 'text':
        if (bgColor === 'standard') {
          classes.push('');
        } else if (bgColor === 'gray') {
          classes.push('hover:bg-gray-100');
        }
        break;
      case 'outlined':
        if (bgColor === 'standard') {
          classes.push('bg-transparent hover:bg-primary text-primary hover:text-white border border-primary');
        } else if (bgColor === 'gray') {
          classes.push('');
        }
        break;
    }
    return classes;
  }, [bgColor, variant]);

  return (
    <button
      id={id}
      onClick={onClick}
      className={classnames(
        'outline-none px-6 h-12',
        classNames,
        buttonTheme
      )}
      disabled = {isDisabled}
    >
      {
        !!leftIcon && (
          <div>
            {leftIcon}
          </div>
        )
      }
      {label}
      {
        !!rightIcon && (
          <div>
            {rightIcon}
          </div>
        )
      }
    </button>
  );
};

export default Button;
