import safeJsonStringify from 'safe-json-stringify';
import { RootState } from 'store';

export const loadState = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

export const saveState = (state: RootState) => {
    try {
        const storageObject = {
            wallet: {
                type: state.wallet.type,
                address: state.wallet.address,
            }
        }
        const serializedState = safeJsonStringify(storageObject);
        sessionStorage.setItem('state', serializedState);
    } catch (error) {
        // console.log(error)
    }
};