import React from "react";
import BlockchainSection from "../../components/modules/Dashboard/BlockchainSection";
import CreateStepSection from "../../components/modules/Dashboard/CreateStepSection";
import SpotlightSection from "../../components/modules/Dashboard/SpotlightSection";
import WelcomeSection from "../../components/modules/Dashboard/DetailSection/WelcomeSection";
import TypesSection from "../../components/modules/Dashboard/DetailSection/TypesSection";
import TopProfilesSection from "../../components/modules/Dashboard/TopProfilesSection";
import BrowseByCategorySection from "../../components/modules/Dashboard/BrowseByCategorySection";
import BrowseByTagSection from "../../components/modules/Dashboard/BrowseByTagSection";
import FullLayout from "../../components/layout/FullLayout";
import "./styles.scss";
import SubscriptionSection from "components/modules/Dashboard/Subscription";
import ApproachingSection from "components/modules/Dashboard/ApproachingSection";
import StepsSection from "components/modules/Dashboard/StepsSection";
import MostLovedPets from "components/modules/Dashboard/MostLovedPet/index";
import GrowingSection from "components/modules/Dashboard/GrowingSection";
import { Helmet } from "react-helmet-async";

const Dashboard = () => {
  return (
    <FullLayout>
      <>
        <Helmet>
          <title>KINGDOM | The Next-gen Social Platform for Pets</title>
          <meta property="og:title" content="The next-gen Social Platform for Pets" />
          <meta name="description" content="The next-gen social platform dedicated to your forever friends. Create your Kingdom and earn rewards along the way. Royalty has its perks." />
          <meta property="og:description" content="The next-gen social platform dedicated to your forever friends. Create your Kingdom and earn rewards along the way. Royalty has its perks." />
          <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
        </Helmet>
        <div className="mt-6 mb-20 px-4 dashboard-page">
          <BlockchainSection />
          <CreateStepSection />
          <TopProfilesSection />
          <div className="container mx-auto">
            <MostLovedPets />
          </div>
          <div className="container mx-auto">
            <StepsSection />

            {/* <BrowseByCategorySection /> */}
            <SubscriptionSection />
            <GrowingSection />
            {/* <ApproachingSection /> */}
          </div>
          {/* <BrowseByTagSection />
        <SpotlightSection />
        <div className="bg-light-bg pb-20 pt-1 mt-20">
          <div className="container mx-auto">
            <WelcomeSection />
            <TypesSection />
          </div>
        </div> */}
        </div>
      </>
    </FullLayout>
  );
};

export default Dashboard;
