export const sortOptions = [
  {
    title: "Most popular",
    value: "most_popular",
  },
  {
    title: "Least popular",
    value: "least_popular",
  },
  {
    title: "Newest first",
    value: "newest",
  },
  {
    title: "Oldest first",
    value: "oldest",
  },
];

export const numPerPageOptions = [10, 20, 50, 100];
