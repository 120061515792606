import React from 'react';
import './style.scss';
import Drawer from '@material-ui/core/Drawer';

const CustomDrawer = ({open, setOpen, anchor="left", variant="persistent", children, width=333, classNames=""}) => {
    return (
        <Drawer
            className={`custom-drawer relative w-${width} ${classNames}`}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <svg onClick={()=> setOpen(false)} className="drawer-close absolute right-[16px] top-[16px] z-40" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            <div className="w-full">
                {children}
            </div>
        </Drawer>
    );
};

export default CustomDrawer;