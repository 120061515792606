import React, { useEffect, useState } from "react";
import Button from "../../../elements/Button";
import PreviewCard from "../Step3/PreviewCard";
// Import the editor default configuration
import { getEditorDefaults } from "@pqina/pintura";

// Import the editor component from `react-pintura`
import { PinturaEditor } from "@pqina/react-pintura";
import Modal from "../../../elements/Modal";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";

type IPetType = {
  petName: string;
  petHandle: string;
  petIg: string;
  avatar: string;
  petType: string;
  petBreed: string;
};

interface IStep4Props {
  selectedInfo: null | number;
  onChangeStep: () => void;
  imageInfo: string;
  titleInfo: string;
  setImage: (value: string) => void;
  profileInfo;
  isNextInProgress: boolean;
}

const Step4 = ({
  selectedInfo,
  onChangeStep,
  imageInfo,
  titleInfo,
  setImage,
  profileInfo,
}: IStep4Props) => {
  const [pets, setPets] = useState<IPetType[]>([]);
  const editorConfig = getEditorDefaults();
  const [isShowingEditor, setIsShowingEditor] = useState(false);

  // console.log(imageInfo);

  useEffect(() => {
    const petList = localStorage.getItem("pets");
    if (petList != null) {
      setPets(JSON.parse(petList));
    }
  }, []);

  const handleEditorModal = () => {
    setIsShowingEditor(!isShowingEditor);
  };

  const handleEditorOnProcess = (imageState: any) => {
    setIsShowingEditor(false);
    let reader = new FileReader();
    reader.readAsDataURL(imageState.dest);
    reader.onload = () => {
      if (typeof reader.result === "string") {
        setImage(reader.result);
      }
    };
  };

  return (
    <>
      <div className="relative min-h-[500px] pb-20 step-4 pt-5">
        <div className="flex flex-col-reverse md:flex-row gap-10">
          <div className="relative space-y-6 min-w-auto sm:min-w-[500px]">
            <p className="subtitle">
              Add a little extra flare to your pet's pic. Crop, filter, and add
              fun stickers by clicking below.
            </p>
            <Button
              classNames="rounded btn-long colored !h-[60px]"
              label="Launch the Royal Editor"
              onClick={handleEditorModal}
            />
          </div>
          <div className="relative">
            <div className="rounded-xl min-w-[250px] min-h-[284px] bg-gray-100">
              {selectedInfo !== null && (
                <PreviewCard
                  selected={selectedInfo}
                  title={titleInfo}
                  pet={pets[selectedInfo]}
                  image={imageInfo}
                  profileInfo={profileInfo}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 bg-white rounded-t-xl shadow-md p-3 border border-gray-200 w-full flex justify-end max-w-4xl mx-auto left-1/2 -translate-x-1/2 z-30">
        <Button
          classNames="rounded btn-long colored auto w-24"
          label="next"
          onClick={()=> {
            onChangeStep();
          }}
        />
      </div>
      {isShowingEditor && (
        <Modal
          handleModal={handleEditorModal}
          disableOverlay={false}
          size={800}
        >
          <div className="h-[500px] md:w-[800px]">
            <PinturaEditor
              {...editorConfig}
              src={imageInfo}
              imageCropAspectRatio={1}
              onProcess={handleEditorOnProcess}
            ></PinturaEditor>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Step4;
