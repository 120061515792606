import React from 'react';
import ImageLoader from 'react-load-image';
import SpinnerIcon from '../Icon/SpinnerIcon';

const ImageWithLoader = ({ src, classNames = "" }) => {
    return (
        <ImageLoader src={src}>
            <img src={src} alt="loaded" className={classNames} />
            <p>No Image found</p>
            <div className="h-full flex items-center justify-center">
                <SpinnerIcon className="my-auto" />
            </div>
        </ImageLoader>
    );
};

export default ImageWithLoader;