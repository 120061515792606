import ipfsService from "./ipfs.service";
import s3Service from "./s3.service";

const uploadImage = async (imageDataUrl: string) => {
  if (process.env.REACT_APP_USE_S3 === "true") {
    return s3Service.uploadImageToS3(imageDataUrl);
  } else {
    return ipfsService.uploadImageToIpfs(imageDataUrl);
  }
};

export default {
  uploadImage,
};
