import React, { useState, useRef, useEffect } from "react";
import Input from "../../../elements/Input";
import Textarea from "../../../elements/Textarea";
import UploadIcon from "../../../elements/Icon/UploadIcon";
import Button from "../../../elements/Button";
import TimesIcon from "../../../elements/Icon/TimesIcon";
import SpinnerIcon from "../../../elements/Icon/SpinnerIcon";
import PreviewCard from "./PreviewCard";
import httpService from "services/http.service";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";

type IPetType = {
  petName: string;
  petHandle: string;
  petIg: string;
  avatar: string;
  petType: string;
  petBreed: string;
};

interface IStep3Props {
  selectedInfo: null | number;
  onChangeStep: () => void;
  imageInfo: string;
  handleImage;
  titleInfo: string;
  handleTitle: (value: string) => void;
  descriptionInfo: string;
  handleDescription: (value: string) => void;
  tagInfo;
  handleTag: (tagIds: []) => void;
  profileInfo;
  isProcessingCurrentStep: boolean;
  topTags;
  setTopTags;
}

const Step3 = ({
  selectedInfo,
  onChangeStep,
  imageInfo,
  handleImage,
  titleInfo,
  handleTitle,
  descriptionInfo,
  handleDescription,
  tagInfo,
  handleTag,
  profileInfo,
  isProcessingCurrentStep,
  topTags,
  setTopTags
}: IStep3Props) => {
  const ref = useRef<HTMLInputElement>(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  
  const [topTenTags, setTopTenTags] = useState([]);
  const [pets, setPets] = useState<IPetType[]>([]);
  const [isNextInProgress, setIsNextInProgress] = useState(false);

  const [value, setValue] = useState([]);

  const getTags = async () => {
    // const responseData = (await httpService.get(`tag/all-tags`)).data;
    const responseData = (
      await httpService.get(`/tag/top-tags?take=20&skip=0`)
    ).data;
    setTopTags(responseData);
    setTopTenTags(responseData.slice(0, 10));
  };
  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    tagInfo.forEach(id => {
      const t = topTags.filter((tag) => tag.tag_id === id);
      if (t.length && !value.includes(t[0].name)) {
        setValue((prev) => {    
         return [...prev, ...[t[0].name]];  
        });
      }
    });
  }, [tagInfo, topTags]);


  const uploadFile = () => {
    ref.current?.click();
  };

  const onFileSelected = (e: any, type?: string) => {
    setFileUploading(true);
    setFileUploaded(true);
    let image:any;
    if(type === "drop"){
      e.preventDefault();
      image = e.dataTransfer.files[0];
    } else {
      image = e.target.files[0];
    }
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      handleImage(reader.result);
    };
    setFileUploading(false);
  };

  const reset = () => {
    setFileUploaded(false);
    setFileUploading(false);
    handleImage(null);
  };

  const inputTags = async () => {
    setIsNextInProgress(true);
    const selectedTags = (
      await httpService.post(`/tag`, {tags: value})
    ).data;
    const mergedArray = [...topTags, ...selectedTags];
    var clean = mergedArray.filter((arr, index, self) =>
    index === self.findIndex((t) => (t.tag_id === arr.tag_id)));
    setTopTags(clean);
    handleTag(selectedTags.map(tag => tag.tag_id));
    onChangeStep();
    setIsNextInProgress(false);
  }

  return (
    <>
      <div className="relative min-h-[500px] w-full sm:w-auto min-w-auto sm:min-w-[500px] space-y-6 pb-20 step-3 pt-5">
        {
          (isNextInProgress || isProcessingCurrentStep) && <div className="h-screen w-screen fixed inset-0 flex items-center justify-center z-40 bg-opacity-75 bg-gray-500 !mt-0">
            <SpinnerIcon className="my-auto !text-white" />
          </div>
        }
        <div className="flex flex-col-reverse md:flex-row gap-10">
          <div className="relative space-y-6 min-w-auto sm:min-w-[500px]">
            <div className="grid grid-cols-2 gap-3">
              {fileUploading ? (
                <button
                  type="button"
                  className="relative block w-full border-2 bg-gray-100 border-gray-300 border-dashed rounded-xl h-48 py-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light"
                >
                  <SpinnerIcon />
                  <span className="mt-2 block text-sm font-medium text-gray-900">
                    {" "}
                    Uploading{" "}
                  </span>
                </button>
              ) : (
                <>
                  {imageInfo ? (
                    <div 
                      className="relative" 
                      onDrop={(e) => onFileSelected(e, "drop")} 
                      onDragOver={(e) => {
                        e.preventDefault();
                      }}
                      onDragEnter={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <button
                        className="absolute top-1 right-1 z-10 h-6 w-6 rounded-full bg-red-400 text-white flex items-center justify-center"
                        onClick={reset}
                      >
                        <TimesIcon />
                      </button>
                      <img
                        className="relative block w-full border-2 object-cover bg-gray-100 rounded-xl text-center h-48 hover:border-gray-400 border-primary-light pointer-events-none"
                        src={imageInfo}
                        alt=""
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className="w-[240px] relative block w-full border-2 bg-gray-100 border-gray-300 border-dashed rounded-xl h-48 py-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-light dropdown-section"
                        onClick={uploadFile}
                        onDrop={(e) => onFileSelected(e, "drop")} 
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        onDragEnter={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <UploadIcon/>
                        <span className="mt-2 block title pointer-events-none">
                          {" "}
                          Choose image{" "}
                        </span>
                        <small className="subtitle leading-tight block mt-2 pointer-events-none">
                          JPG, PNG or WEBP <br /> Max 10MB
                        </small>
                      </div>
                      <input
                        ref={ref}
                        onChange={(e) => onFileSelected(e)}
                        type="file"
                        className="hidden"
                      />
                    </>
                  )}
                </>
              )}
            </div>
            <Input
              placeholder="Ex. Mega Fluffy"
              title="Title"
              value={titleInfo}
              onChange={(e) => handleTitle(e.target.value)}
            />
            <Textarea
              placeholder="Ex. Fluffy is gigantic"
              title="Caption"
              value={descriptionInfo}
              onChange={(e) => handleDescription(e.target.value)}
            />
            <div className="autocomplete">
              <label className="block font-medium font-title text-sm text-gray-700">
                Tags
              </label>
              <div className="flex gap-1 flex-wrap tags-container mb-2">
                {topTenTags.map((tag) => (
                  <div
                    key={tag.tag_id}
                    className="tag-item"
                    onClick={() => {
                      if (!value.includes(tag.name)) {
                        setValue([...value, tag.name]);
                      } else {
                        setValue(value.filter((name) => name !== tag.name));
                      }
                    }}
                  >
                    {tag.name}
                  </div>
                ))}
              </div>
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                // options={topTags.map((tag) => tag.name)}
                freeSolo
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      deleteIcon={<CloseIcon color="action" />}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e: any) => {
                        if(e.code === "Space"){
                          setValue(prev => {
                            return [...prev, e.target.value]
                          })
                        }
                      }
                    }}
                    variant="outlined"
                    placeholder="Add Kingdom tags"
                  />
                )}
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
              />
            </div>
          </div>
          <div className="relative hidden sm:block">
            <div className="rounded-xl min-w-auto sm:min-w-[250px] min-h-[284px] bg-gray-100">
              {selectedInfo !== null && (
                <PreviewCard
                  selected={selectedInfo}
                  pet={pets[selectedInfo]}
                  image={imageInfo}
                  title={titleInfo}
                  profileInfo={profileInfo}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 bg-white rounded-t-xl shadow-md p-3 border border-gray-200 w-full flex justify-end max-w-4xl mx-auto left-1/2 -translate-x-1/2 z-30">
        {titleInfo && descriptionInfo ? (
          <Button
            classNames="rounded btn-long colored auto w-24"
            label="next"
            onClick={inputTags}
          />
        ) : (
          <Button
            classNames="rounded btn-long white-colored auto py-2 w-24 !text-white"
            bgColor="gray"
            label="next"
          />
        )}
      </div>
    </>
  );
};

export default Step3;
