import React, { useEffect, useState } from "react";
import LeftArrowIcon from "../../components/elements/Icon/LeftArrowIcon";
import Button from "../../components/elements/Button";
import { editAccountItems, editProfileItems } from "../../constants/profile";
import Input from "../../components/elements/Input";
import Textarea from "../../components/elements/Textarea";
import Modal from "../../components/elements/Modal";
import SocialInput from "../../components/elements/SocialInput";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import TiktokIcon from "../../components/elements/Icon/TiktokIcon";
import FullLayout from "../../components/layout/FullLayout";
import "./style.scss";
import AvatarSelect from "../../components/modules/CreateProfile/ProfileStep1/AvatarSelect";
import httpService from "services/http.service";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "store";
// import ipfsService from "services/ipfs.service";
import imageService from "services/image.service";
import useNotification from "hooks/useNotification";
import history from "common/history";
import { Helmet } from "react-helmet-async";
import { isInstagramLinked, isTiktokLinked, isTwitterLinked } from "common/utils";

type IEditInput = {
  title: string;
  value?: string;
  placeholder?: string;
  hint?: string;
  name: string;
};

const EditAccount = (props) => {
  const profileUrl = props.match.params.url;
  const [profileInfo, setProfileInfo] = useState(null);
  const [profileUrls, setProfilesUrls] = useState([]);
  const [formState, setFormState] = useState<{ [key: string]: string }>({
    name: "",
    url: "",
    bio: "",
  });
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const [avatar, setAvatar] = useState<any>(null);
  const notify = useNotification();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const updateProfileData = async () => {
    console.log('updateProfileData', avatar);
    let imageUrl = avatar;
    if (!avatar.includes('http')) {
      // const loadedImageUrl = await ipfsService.uploadImageToIpfs(avatar);
      const loadedImageUrl = await imageService.uploadImage(avatar);
      console.log('loadedImageUrl', loadedImageUrl);
      imageUrl = loadedImageUrl;
    }

    if (formState.twitterLink && !isTwitterLinked(formState.twitterLink)) {
      notify('Invalid twitter url', "error");
      return;
    }

    if (formState.tiktokLink && !isTiktokLinked(formState.tiktokLink)) {
      notify('Invalid tiktok url', "error");
      return;
    }

    if (formState.instagramLink && !isInstagramLinked(formState.instagramLink)) {
      notify('Invalid instagram url', "error");
      return;
    }
    
    const responseData = (
      await httpService.put(`/profile/edit-profile`, {
        profileId: profileInfo.profile_id.toString(),
        imageUrl: imageUrl,
        name: formState.name,
        profileUrl: "/" + formState.url,
        description: formState.bio || "",
        twitterLink: formState.twitterLink,
        tiktokLink: formState.tiktokLink,
        instagramLink: formState.instagramLink,
      })
    ).data;
    if (responseData) {
      setIsSuccessModalOpened(!isSuccessModalOpened);
    }
  };

  const handleSuccessModal = () => {
    updateProfileData();
  };

  const handleOKModal = () => {
    window.location.href = "/pets/" + formState.url;
  };

  const onInputChange = (e: any) => {
    if (e.name === "url") {
      e.value = e.value.replace("/", "");
    }
    setFormState({
      ...formState,
      [e.name]: e.value,
    });
  };

  const handleAvatar = (value) => {
    setAvatar(value);
  };

  useEffect(() => {
    const getProfileInfo = async () => {
      const responseData = (
        await httpService.get(`profile/profile-by-name?url=${"/" + profileUrl}&userAddress=${userAddress ? userAddress : ''}`)
      ).data;
      setProfileInfo(responseData[0]);
      setFormState({
        name: responseData[0].name,
        url: responseData[0].url.slice(1, responseData[0].url.length),
        bio: responseData[0].description,
        twitterLink: responseData[0].twitter_link,
        tiktokLink: responseData[0].tiktok_link,
        instagramLink: responseData[0].instagram_link
      });
      setAvatar(responseData[0].image_url);

    };
    getProfileInfo();
  }, []);

  useEffect(() => {
    const getProfileUrls = async () => {
      const responseData = (await httpService.get(`/profile/all-url`)).data;
      setProfilesUrls(
        responseData
          .filter((item) => item.url !== profileInfo?.url)
          .map((item) => item.url)
      );
      console.log(profileUrls);
    };
    getProfileUrls();
  }, [profileInfo]);

  const deleteProfile = async () => {
    const responseData = await httpService.put(`/profile/soft-delete/${profileInfo?.profile_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      if (error.response) {
        notify(error.response?.data?.message ? error.response?.data?.message : error.response?.data?.error, "error");
      } else if (error.request) {
        notify("request error", "error");
      } else {
        notify(error.message, "error");
      }
    });

    if (responseData) {
      history.push('/users');
    }
  }

  const handleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <FullLayout>
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>Edit Pet Profile | KINGDOM</title>
        <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
        {isSuccessModalOpened && (
          <Modal handleModal={handleSuccessModal} disableOverlay={false}>
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h3 className="leading-6 text-center mt-3 modal-text">
                Information saved
              </h3>
            </div>
            <div className="mt-3 sm:mt-4 flex justify-center">
              <Button
                label="Great"
                classNames="btn-long colored rounded !w-44"
                onClick={handleOKModal}
              />
            </div>
          </Modal>
        )}
        <div className="container mx-auto mt-6 mb-20 edit-account-container">
          <div className="max-w-2xl mx-auto">
            <Link
              to={`/pets/${profileUrl}`}
              className="flex items-center text-primary font-semibold -ml-1 back"
            >
              <LeftArrowIcon />
              Back
            </Link>
            <h1 className="page-title">Edit {profileInfo?.name}'s profile</h1>
            <div className="mt-6 space-y-6">
              <AvatarSelect
                avatarInfo={avatar}
                handleAvatar={(e) => handleAvatar(e)}
              />
              <Input
                key="Pet profile name"
                name="name"
                value={formState.name}
                onChange={(e) => onInputChange(e.target)}
                title="Pet profile name"
              />
              <Input
                key="Pet profile URL"
                name="url"
                value={formState.url}
                onChange={(e) => onInputChange(e.target)}
                title="Pet profile URL"
                className={
                  profileUrls.filter((url) => url === formState.url).length > 0
                    ? "input-error"
                    : ""
                }
              />
              <Textarea
                title="Bio"
                name="bio"
                placeholder="Enter Bella's public bio here"
                value={formState.bio}
                onChange={(e) => onInputChange(e.target)}
              />
            </div>

            <p className="font-medium text-sm text-primary-700 mt-4">
            {profileInfo?.name}'s socials
            </p>
            <div className="bg-light-bg rounded-xl mt-2 space-y-6 p-3">
              <SocialInput
                title="Twitter"
                name="twitterLink"
                placeholder="https://twitter.com/abcd"
                value={formState.twitterLink}
                onChange={(e) => onInputChange(e.target)}
                icon={<FaTwitter />}
              />
              <SocialInput
                title="Tiktok"
                name="tiktokLink"
                placeholder="https://tiktok.com/@abcd"
                value={formState.tiktokLink}
                onChange={(e) => onInputChange(e.target)}
                icon={<TiktokIcon />}
              />
              <SocialInput
                title="Instagram"
                name="instagramLink"
                placeholder="https://instagram.com/abcd"
                value={formState.instagramLink}
                onChange={(e) => onInputChange(e.target)}
                icon={<FaInstagram />}
              />
            </div>

            <div className="flex justify-between w-full mt-12">
              <Button
                label="delete profile"
                classNames={`rounded-md btn-long auto orange-colored`}
                onClick={handleDeleteModal}
              />

              <Button
                label="save"
                isDisabled={
                  profileUrls?.filter((url) => url === formState?.url).length >
                  0
                }
                classNames={`rounded-md btn-long auto ml-4 ${
                  profileUrls?.filter((url) => url === formState?.url).length >
                  0
                    ? "light-colored"
                    : "colored"
                }`}
                onClick={handleSuccessModal}
              />
            </div>
          </div>
        </div>
        {isDeleteModalOpen && (
          <Modal
            handleModal={handleDeleteModal}
            disableOverlay={false}
            size={800}
          >
            <div className="delete-modal">
              <h1 className="modal-title">Are you sure?</h1>
              <p className="modal-text">This action cannot be undone.</p>
              <div className="action-button-container">
                <button
                  className="py-3 px-6 rounded-md mt-4 long-btn mint"
                  onClick={handleDeleteModal}
                >
                  No, Keep it
                </button>
                <button
                  className="py-3 px-6 rounded-md mt-4 long-btn delete-nft"
                  onClick={deleteProfile}
                >
                  Yes, delete it
                </button>
              </div>
            </div>
          </Modal>
        )}
      </>
    </FullLayout>
  );
};

export default EditAccount;
