import Input from "components/elements/Input";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import useNotification from "hooks/useNotification";
import httpService from "services/http.service";

const SubscriptionSection = () => {
  const [subscriberEmail, setSubscriberEmail] = useState("");
  const notify = useNotification();

  const registerSubscriber = async (e) => {
    e.preventDefault();
    if (!subscriberEmail) {
      notify("Input your email.", "error");
      return;
    }

    try {
      const data = await httpService
        .post("/subscriber/create", {
          subscriber_email: subscriberEmail,
          subscriber_type: 1,
        })
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          if (error.response) {
            notify(error.response?.data?.message ? error.response?.data?.message : error.response?.data?.error, "error");
          } else if (error.request) {
            notify("request error", "error");
          } else {
            notify(error.message, "error");
          }
        });
        if (data) {
          notify("Your email has been registered, glad to see you in our community.", "success");
          setSubscriberEmail('');
        }
    } catch (ex) {
      console.log(ex);
    }    
  };
  return (
    <div className="bg-white py-12 sm:py-16 dashboard-subscription-section">
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div
            style={{ backgroundImage: `url("/assets/images/bg-gradient.png")` }}
            className="relative bg-cover rounded-cards px-6 py-10 bg-primary overflow-hidden shadow-xl sm:px-12 sm:py-20"
          >
            <div
              aria-hidden="true"
              className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0"
            >
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-primary text-opacity-20"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                ></path>
                <path
                  className="text-primary-light text-opacity-10"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                ></path>
              </svg>
            </div>
            <div className="relative">
              <svg
                className="w-32 mx-auto mb-4 -mt-6 text-white overflow-visible"
                viewBox="0 0 38.14 36.06"
              >
                <path
                  fill="currentColor"
                  d="m31.92 20.49-5.71-6.6c-.24-.28-.6-.45-.97-.45H12.91c-.37 0-.73.16-.97.45l-5.71 6.6c-.21.24-.31.54-.31.84 0 .31.11.61.32.85l11.87 13.44c.26.29.61.43.97.43s.71-.14.96-.43l11.87-13.44c.22-.24.32-.55.32-.85 0-.3-.1-.6-.31-.84ZM13.22 0h-.29c-.96.1-1.78.62-2.39 1.43-.6.81-.97 1.91-.97 3.14 0 .25.02.5.05.76.32 2.67 2.26 4.64 4.25 4.63h.29c.96-.1 1.78-.62 2.39-1.43.6-.81.97-1.91.97-3.14 0-.25-.02-.5-.05-.76-.32-2.67-2.26-4.65-4.25-4.63ZM7.91 10.19C7.28 7.72 5.29 6.01 3.4 6.02c-.2 0-.4.02-.6.06C1.12 6.4 0 8.04 0 10.13c0 .49.06 1 .19 1.51.63 2.47 2.62 4.18 4.51 4.17.2 0 .4-.02.6-.06 1.67-.32 2.78-1.96 2.79-4.05 0-.49-.06-1-.19-1.51Zm12.76-5.56c-.03.26-.05.51-.05.76 0 1.22.37 2.33.97 3.14s1.43 1.32 2.39 1.41h.29c1.99.03 3.93-1.94 4.25-4.61.03-.26.05-.51.05-.76 0-1.22-.37-2.33-.97-3.14S26.17.11 25.21.02h-.29c-1.99-.03-3.93 1.94-4.25 4.61m9.57 5.56c-.13.52-.19 1.03-.19 1.51 0 2.09 1.12 3.73 2.79 4.05.2.04.4.06.6.06 1.89.01 3.89-1.7 4.51-4.17.13-.52.19-1.03.19-1.51 0-2.09-1.12-3.73-2.79-4.05-.2-.04-.4-.06-.6-.06-1.89-.01-3.89 1.71-4.51 4.17Z"
                ></path>
                <path
                  fill="currentColor"
                  className="d logo-shadow"
                  d="m31.92 20.49-5.71-6.6c-.24-.28-.6-.45-.97-.45H12.91c-.37 0-.73.16-.97.45l-5.71 6.6c-.21.24-.31.54-.31.84 0 .31.11.61.32.85l11.87 13.44c.26.29.61.43.97.43s.71-.14.96-.43l11.87-13.44c.22-.24.32-.55.32-.85 0-.3-.1-.6-.31-.84ZM13.22 0h-.29c-.96.1-1.78.62-2.39 1.43-.6.81-.97 1.91-.97 3.14 0 .25.02.5.05.76.32 2.67 2.26 4.64 4.25 4.63h.29c.96-.1 1.78-.62 2.39-1.43.6-.81.97-1.91.97-3.14 0-.25-.02-.5-.05-.76-.32-2.67-2.26-4.65-4.25-4.63ZM7.91 10.19C7.28 7.72 5.29 6.01 3.4 6.02c-.2 0-.4.02-.6.06C1.12 6.4 0 8.04 0 10.13c0 .49.06 1 .19 1.51.63 2.47 2.62 4.18 4.51 4.17.2 0 .4-.02.6-.06 1.67-.32 2.78-1.96 2.79-4.05 0-.49-.06-1-.19-1.51Zm12.76-5.56c-.03.26-.05.51-.05.76 0 1.22.37 2.33.97 3.14s1.43 1.32 2.39 1.41h.29c1.99.03 3.93-1.94 4.25-4.61.03-.26.05-.51.05-.76 0-1.22-.37-2.33-.97-3.14S26.17.11 25.21.02h-.29c-1.99-.03-3.93 1.94-4.25 4.61m9.57 5.56c-.13.52-.19 1.03-.19 1.51 0 2.09 1.12 3.73 2.79 4.05.2.04.4.06.6.06 1.89.01 3.89-1.7 4.51-4.17.13-.52.19-1.03.19-1.51 0-2.09-1.12-3.73-2.79-4.05-.2-.04-.4-.06-.6-.06-1.89-.01-3.89 1.71-4.51 4.17Z"
                ></path>
              </svg>
              <div className="sm:text-center">
                <h2 className="title tracking-tight sm:tracking-tight">
                  Stay up to date with Kingdom
                </h2>
                <p className="mt-6 mx-auto max-w-2xl subtitle">
                  New features, cute pets, and fresh contests. All this and more
                  in our newsletter.
                </p>
              </div>
              <form className="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                <div className="min-w-0 flex-1">
                  <label className="sr-only">Email address</label>
                  <Input
                    name="email"
                    type="text"
                    value={subscriberEmail}
                    className=""
                    placeholder="Enter your email"
                    onChange={(e) => {
                      setSubscriberEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="rounded btn-long auto sm:px-10"
                    onClick={registerSubscriber}
                  >
                    Subscribe
                  </button>
                </div>
              </form>
              <p className="text-center mt-4 text-opacity-50 -mb-6 bottom-text">
                We respect your privacy, read our
                <Link
                  className="underline text-white hover:text-gray-300 font-medium end"
                  to="/privacy"
                >
                  privacy policy
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSection;
