import React from "react";
import { Link } from "react-router-dom";

interface IUserCardProps {
  item: {
    userId: number;
    displayName: string;
    firstName: string;
    lastName: string;
    likeCount: number;
    link: string;
    image_url: string;
    userAddress: string;
    title: string;
  };
  index: number;
}

const UserCard = ({ item, index }: IUserCardProps) => {
  return (
    <Link
      // to={item.link === "N/A" ? "" : `/users/${item.link}`}
      to={{     
        pathname: item?.link ?  `/users/${item.link}` : (item.firstName ? `/users/${item.firstName}` : `/users/${item.lastName}`),
        state: {
          address: item.userAddress
        }
      }}
      className="flex items-center p-4 w-full h-20 font-semibold leading-6 cursor-pointer box-border rounded profile-item-card"
    >
      <div className="count">{index + 1}</div>
      <div className="relative mx-4">
        <div className="mx-2 rounded-[50%]">
          <div className="flex overflow-hidden relative justify-center items-center w-13 max-w-full h-13 max-h-full cursor-pointer rounded-[50%]">
            <img
              src={item?.image_url ? item.image_url : "/assets/images/avatar.png" }
              alt="avatar"
              className="object-cover w-full"
            />
          </div>
        </div>
      </div>
      <div className="flex overflow-hidden flex-col flex-1 justify-center items-start self-stretch mr-4">
        <h3 className="title w-full">{item.title}</h3>
        <span>
          <div className="flex items-center subtitle">
            <div className="mt-px mr-1 text-xs inner">{`${item.likeCount} likes`}</div>
          </div>
        </span>
      </div>
    </Link>
  );
};

export default UserCard;
