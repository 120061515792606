import React, {useState} from "react";
import Button from "../../components/elements/Button";
import MyProfileDetailSection from "../../components/modules/ProfileDetailSection/MyProfileDetail";
import Modal from "../../components/elements/Modal";
import {FaInstagram, FaTwitter} from "react-icons/fa";
import TiktokIcon from "../../components/elements/Icon/TiktokIcon";
import FullLayout from "../../components/layout/FullLayout";

const MyProfileDetail = () => {
  const [isSocialModalOpened, setIsSocialModalOpened] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleSocialModal = () => {
    setIsSocialModalOpened(!isSocialModalOpened);
  };

  const handleCopy = (url: string) => {
    setIsCopied(prev => !prev);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setIsCopied(prev => !prev)
    }, 2000)
  }

  return (
    <FullLayout>
      <div className="container mx-auto mt-6 mb-20">
        {
          isSocialModalOpened && (
            <Modal handleModal={handleSocialModal}>
              <div className="space-y-2">
                <Button
                  classNames="w-full rounded-md flex items-center"
                  leftIcon={<FaTwitter className="mr-4" />}
                  label="Share on twitter"
                />
                {/* <Button
                  classNames="w-full rounded-md flex items-center"
                  leftIcon={<TiktokIcon className="mr-4" />}
                  label="Share on tiktok"
                />
                <Button
                  classNames="w-full rounded-md flex items-center"
                  leftIcon={<FaInstagram className="mr-4" />}
                  label="Share on instagram"
                /> */}
                <Button
                  classNames="w-full rounded-md flex items-center copy-btn px-4 py-2 !h-auto"
                  onClick={()=>handleCopy(window.location.href)}
                  leftIcon={
                    <svg className="mr-2" height="1.5em" width="1.5em" viewBox="0 0 24 24" stroke-width="1.5" stroke="#444" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                    </svg>
                  }
                  label={isCopied ? "Copied to clipboard" : "Copy link"}
                /> 
              </div>
              <div className="mt-5 sm:mt-6">
                <Button
                  label="close"
                  classNames="w-full rounded-md hover:bg-gray-300"
                  bgColor="gray"
                  onClick={handleSocialModal}
                />
              </div>
            </Modal>
          )
        }
        <div className="w-full h-64 bg-gray-100 group relative text-gothic font-medium">
          <img
            src="/assets/images/cover.png"
            alt="cover"
            className="absolute inset-0 object-cover h-full w-full"
          />
          <div className="group-hover:opacity-100 opacity-0 absolute inset-0 bg-white/70 flex flex-col justify-center items-center">
            <p>
              PNG or JPEG Max 50MB
            </p>
            <small>
              Recommended size 1440 x 256px
            </small>
            <Button
              label="Change cover"
              variant="outlined"
              classNames="rounded-full mt-4 border-2 change-cover"
            />
          </div>
        </div>
        <MyProfileDetailSection
          handleSocialModal={handleSocialModal}
        />
      </div>
    </FullLayout>
  );
};

export default MyProfileDetail;
