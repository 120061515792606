import FullLayout from "components/layout/FullLayout";
import React from "react";
import { Helmet } from "react-helmet-async";
import "./style.scss";

const pageContent = {
  pageTitle: "Terms of Service",
  content: [
    {
      id: 1,
      title: "",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 2,
      title: "Heading One",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, laborum. Doloribus reiciendis, ullam ipsum commodi tenetur illo eos voluptates aperiam voluptas cupiditate perspiciatis voluptate cum aspernatur enim dolor exercitationem nisi molestiae a odit, soluta similique voluptatibus dicta necessitatibus quia? Molestiae tenetur dicta ex, laboriosam quod perspiciatis magnam, vero rem nobis vel nihil animi ut sunt excepturi non explicabo voluptatum illo! Voluptates consequuntur at ullam ab libero explicabo quod temporibus eum? Quisquam dolor, aspernatur expedita ullam cumque molestiae necessitatibus inventore. Accusamus eveniet cum, recusandae totam tempora possimus quo dolorum. sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 3,
      title: "Heading Two",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, laborum. Doloribus reiciendis, ullam ipsum commodi tenetur illo eos voluptates aperiam voluptas cupiditate perspiciatis voluptate cum aspernatur enim dolor exercitationem nisi molestiae a odit, soluta similique voluptatibus dicta necessitatibus quia? Molestiae tenetur dicta ex, laboriosam quod perspiciatis magnam, vero rem nobis vel nihil animi ut sunt excepturi non explicabo voluptatum illo! Voluptates consequuntur at ullam ab libero explicabo quod temporibus eum? Quisquam dolor, aspernatur expedita ullam cumque molestiae necessitatibus inventore. Accusamus eveniet cum, recusandae totam tempora possimus quo dolorum. sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 4,
      title: "The Third Heading",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 4,
      title: "Another Heading",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 5,
      title: "A very long heading nobody is ready for because law",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores velit accusantium nihil reprehenderit, ipsum deserunt officia fugiat veniam ut, minima explicabo quaerat. Iusto nemo necessitatibus optio! Placeat facere sint perspiciatis nisi consectetur sed adipisci earum libero dolorum labore fugit laudantium, ea provident ullam fuga dignissimos nemo veritatis magnam? Odio, beatae? Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
  ],
};

const TermsOfServices = () => {
  return (
    <FullLayout>
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>Terms of Use | KINGDOM</title>
        <meta property="og:title" content="Terms of Use" />
        <meta name="description" content="Read KINGDOM's Terms of Service to understand the rules governing your access to all KINGDOM services." />
        <meta property="og:description" content="Read KINGDOM's Terms of Service to understand the rules governing your access to all KINGDOM services." />
        <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
      <div className="static-page">
        <div className="mt-20 mb-20 px-4 max-w-4xl mx-auto space-y-3">
          <div className="mt-20 mb-20 px-4 max-w-4xl mx-auto space-y-3">
            <h1 className="page-title mb-12">
              Terms of Service
            </h1>{" "}
            <span className="text-sm bg-gray-200 rounded p-2 !-mt-6 !mb-6 max-w-[max-content] block">
              Last updated: November 1, 2022
            </span>{" "}
            <p>
              These Terms of Use (“<strong>Terms</strong>”) constitute an
              agreement between you (“<strong>you</strong>” or the “
              <strong>user</strong>”) and Pawtocol and its affiliates or assigns
              (collectively, “<strong>Pawtocol</strong>,” “
              <strong>KINGDOM</strong>,” “<strong>we</strong>,” “
              <strong>us</strong>,” or “<strong>our</strong>”). By accessing our
              website, www.enterkingdom.io, (the <strong>“Website</strong>”),
              using our Platform, KINGDOM (the “<strong>Platform</strong>”),
              interacting with us on social media, or using our services in any
              way, including as an end user (together, the “
              <strong>Services</strong>”), you signify that you have read,
              understand, and agree to be bound by these Terms, including the
              arbitration provision and class action waiver. The Services allow
              users the opportunity to create unique blockchain-tracked,
              non-fungible tokens (“<strong>KINGDOM Pet Posts</strong>”) that
              serve as digital collectibles and are originally minted by the
              user on the KINGDOM platform. The Services may be accessible via
              websites or applications, whether owned and/or operated by us or
              third parties, including without limitation, through our website
              at <a href="http://www.enterkingdom.io">www.enterkingdom.io</a>,
              and any of our related applications. Please read these Terms
              carefully. You should print a copy of these Terms for your
              records.
            </p>{" "}
            <p>
              ARBITRATION NOTICE: EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED
              IN THE “ARBITRATION PROVISION AND CLASS ACTION WAIVER” SECTION
              BELOW, AND UNLESS YOU OPT-OUT AS SET FORTH IN THAT SAME SECTION,
              YOU AGREE THAT DISPUTES BETWEEN YOU AND KINGDOM OR OTHER PARTIES
              DESCRIBED HEREIN WILL BE RESOLVED BY BINDING, INDIVIDUAL
              ARBITRATION AT THE ELECTION OF EITHER PARTY, AND YOU WAIVE YOUR
              RIGHT TO A JURY TRIAL AND YOUR RIGHT TO BRING OR RESOLVE ANY
              DISPUTE AS OR TO PARTICIPATE IN A CLASS, PRIVATE ATTORNEY GENERAL,
              REPRESENTATIVE, OR COLLECTIVE ACTION IN COURT OR IN ARBITRATION.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#your-information-and-disclosure-to-third-parties"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="your-information-and-disclosure-to-third-parties"
              >
                Your Information and Disclosure to Third Parties
              </h2>
            </a>{" "}
            <p>
              KINGDOM has made a commitment to protecting the privacy of those
              who use the Services. Please review our{" "}
              <a
                className="text-blue-600 font-medium"
                href="https://enterkingdom.io/privacy"
              >
                Privacy Policy
              </a>
              . Our Privacy Policy is incorporated by reference in these Terms.
              By using the Services, you are consenting to and agreeing to be
              bound by the Privacy Policy.
            </p>{" "}
            <p>
              When you use the Services, you consent to receive communications
              from us electronically via the Services, email, or otherwise. You
              agree to provide accurate, current, and complete information about
              yourself when you use the Services and to update such information
              while you continue to use the Services.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#account-registration"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="account-registration"
              >
                Account Registration
              </h2>
            </a>{" "}
            <p>
              You may be given the opportunity to register to create a user
              account (“<strong>Your Account</strong>”). By registering you
              represent and warrant that all information that you provide on the
              registration form is current, complete and accurate to the best of
              your knowledge. You agree to maintain and promptly update your
              registration information on the Services so that it remains
              current, complete and accurate. During the registration process,
              you may be required to choose a user name and enter your email
              address. You acknowledge and agree that KINGDOM may rely on this
              email address or user name to identify you. You shall be
              responsible for protecting the confidentiality of your user
              name(s) and/or password(s), if any. You are responsible for all
              use of Your Account, regardless of whether you authorized such
              access or use, and for ensuring that all use of Your Account
              complies fully with the provisions of these Terms of Use.
            </p>{" "}
            <p>
              Any conduct that in our sole discretion restricts or inhibits
              anyone else from using or enjoying the Services will not be
              permitted. We reserve the right in our sole discretion to remove
              or edit any content and to terminate Your Account for any reason.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#mobile-devices-and-mobile-applications"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="mobile-devices-and-mobile-applications"
              >
                Mobile Devices and Mobile Applications
              </h2>
            </a>{" "}
            <p>
              If you use a mobile device to access the Services optimized for
              mobile-viewing, or use a mobile application, the following
              additional terms and conditions (“<strong>Mobile Terms</strong>”)
              also apply to you. Your access to the Services via your mobile
              device or use of a mobile application confirms your agreement to
              these Mobile Terms, as well as the rest of the Terms.&nbsp;
            </p>{" "}
            <p>
              You may opt in to receive SMS text messages on your mobile device.
              Your consent is not required as a condition of purchasing any
              goods or services from us. Data obtained from you in connection
              with this text messaging service may include your mobile phone
              number, your carrier’s name, and the date, time, and content of
              your messages and other information you provide to KINGDOM as part
              of this service. We may use this information to contact you and
              provide services you request from us.
            </p>{" "}
            <p>
              KINGDOM will not be liable for any delays in the receipt of any
              text messages. Delivery is subject to effective transmission from
              your network operator. You understand that wireless service
              through Wi-Fi or a participating mobile service provider may not
              be available in all areas at all times and may be affected by
              product, software, coverage or other service changes made by your
              mobile service provider or otherwise. You agree that you are
              solely responsible for all message and data charges that apply to
              use of your mobile device to access the Platform or use of a
              mobile application. All such charges are billed by and payable to
              your mobile service provider. Please contact your participating
              mobile service provider for pricing plans, participation status
              and details.&nbsp;
            </p>{" "}
            <p>
              If you use our mobile application, you can delete your account by
              submitting a request to team@pawtocol.com or by deleting your
              account through the application itself.
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#eligibility">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="eligibility"
              >
                Eligibility
              </h2>
            </a>{" "}
            <p>
              You represent and warrant that you: (a) are above the legal age of
              majority in your jurisdiction of residence; (b) have not
              previously been suspended or removed from the Website, Platform,
              or our Services; (c) will only provide us with true, accurate,
              current and complete information if you register for an account—if
              we believe or suspect that your information is not true, accurate,
              current or complete, we may deny or terminate your access to the
              Services (or any portion thereof); and (d) have full power and
              authority to enter into these Terms and in doing so will not
              violate any other agreement to which you are a party.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#purchasing-kingdom-pet-posts"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="purchasing-kingdom-pet-posts"
              >
                &nbsp;Purchasing KINGDOM Pet Posts
              </h2>
            </a>{" "}
            <p>
              TOKENS ARE HIGH-RISK ASSETS AND YOU SHOULD NEVER USE FUNDS THAT
              YOU CANNOT AFFORD TO LOSE TO PURCHASE TOKENS.
            </p>{" "}
            <p>
              Purchase Terms. The Pawtocol KINGDOM Pet Post collection is
              unlimited will be available to mint and purchase on the Website
              and/or Platform. Purchases may be made by using one or more
              currencies or cryptocurrencies that we may elect to accept from
              time to time. We retain the right in our discretion to limit the
              dollar (or equivalent in other currencies) amount and number of
              any transactions on the Website. KINGDOM is under no obligation to
              accept cryptocurrencies and we retain the right in our sole
              discretion to determine what currencies or payment option to
              accept at any time.
            </p>{" "}
            <p>
              You are responsible for all payments, fees and costs when engaging
              in any transactions involving KINGDOM , including, without
              limitation, the purchase price, transaction fees (e.g., “gas”
              fees) and all other fees associated with your use of the
              Services.You also are solely responsible for payment of all
              national, federal ,state, local or other taxes of any
              jurisdiction, of whatever nature whether now in effect or imposed
              in the future by any national federal, state, local, international
              or any other governmental authority or taxing jurisdiction,
              including, without limitation, any income, sales, use, value-added
              (“VAT”), goods and services and other taxes and duties associated
              with your use of the Services and your purchase of your KINGDOM
              Pet Post from any party. You are solely responsible for any tax
              reporting for transactions in which you may be a seller of KINGDOM
              Pet Post.
            </p>{" "}
            <p>
              KINGDOM Pet Post Ownership Terms. You may have the limited right
              to sell or transfer your KINGDOM Pet Post on a secondary sales
              platforms. We do not monitor or control any marketplace purchases
              or sales, or any other activity beyond that on the Website.&nbsp;
            </p>{" "}
            <p>
              KINGDOM Pet Posts may not be wrapped, imported, or altered to
              mirroring derivative projects and sold on a Blockchain.
            </p>{" "}
            <p>
              You assume all risks of purchasing KINGDOM Pet Posts, including
              without limitation KINGDOM Pet Post price fluctuations. AS A
              RESULT OF PRICE VOLATILITY, YOUR TOKENS MAY LOSE ALL VALUE AND
              BECOME WORTHLESS.
            </p>{" "}
            <p>
              You also assume all risks associated with using a digital
              currency, including without limitation (1) the risk that a third
              party may obtain unauthorized access to information stored in your
              wallet, and (2) the risk that regulations governing NFTs—which are
              undetermined and subject to change at any time—could impact
              KINGDOM, digital currencies or other mechanisms that may affect
              your wallet and transactions.&nbsp;
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#contests">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="contests"
              >
                Contests
              </h2>
            </a>{" "}
            <p>
              Occasionally, we may hold contests in which you may participate.
              We reserve the sole right to determine the rules of each contest,
              as laid out in the rules specific to such contest (“
              <strong>Contest Rules</strong>” ), including the requirements of
              eligibility. You must comply with the Contest Rules, which are
              incorporated by reference in these Terms. There is no guarantee of
              success or winning in any contest.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#user-content"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="user-content"
              >
                User Content
              </h2>
            </a>{" "}
            <p>
              You are solely responsible for the content and information
              (collectively referred to as “<strong>User Content</strong>”) that
              you post or upload on the Services. By submitting User Content to
              the Services, you automatically grant KINGDOM the royalty-free,
              perpetual, irrevocable, non-exclusive right and license, but not
              the obligation, to use, publish, reproduce, modify, adapt, edit,
              translate, create derivative works from, incorporate into other
              works, distribute, sub-license and otherwise exploit such User
              Content (in whole or in part) worldwide in any form, media or
              technology now known or hereafter developed for the full term of
              any copyright that may exist in such User Content, without payment
              to you or to any third parties.
            </p>{" "}
            <p>
              User Content uploaded or otherwise communicated on the Services
              shall comply with all applicable law and regulations, which you
              are responsible for knowing and understanding. You are prohibited
              from posting or otherwise communicating to us, or any other user
              of the Services any offensive, inaccurate, incomplete, abusive,
              obscene, profane, threatening, intimidating, harassing, racially
              offensive, or illegal material, or any material that infringes or
              violates another person’s rights (including intellectual property
              rights, and rights of privacy and publicity).
            </p>{" "}
            <p>
              You understand and agree that we, without any obligation to do so,
              may monitor or review any User Content you post, upload or
              otherwise communicate through the Services. We reserve the right
              to remove any such User Content, in whole or in part, at our sole
              discretion, that violates these Terms, is likely to harm our
              reputation, is deemed inappropriate or otherwise poses a risk of
              harm to any other person. We reserve the right to deactivate Your
              Account or otherwise prevent your use and/or participation on
              Services at any time and for any reason.
            </p>{" "}
            <p>
              You agree that we may access, preserve and disclose your account
              and any User Content posted, uploaded, received or otherwise
              communicated if required to do so by law or with the good faith
              belief that such access, preservation or disclosure is reasonably
              necessary to: (i) comply with an enforceable subpoena or other
              legal process; (ii) enforce these Terms; (iii) respond to claims
              that any information violates the rights of third parties; (iv)
              respond to your requests for customer service or allow you to use
              the Services in the future; or (v) protect the rights, property or
              personal safety of KINGDOM or its members, employees, agents,
              affiliates, partners, communities or any other person.
            </p>{" "}
            <p>
              If it is determined that you retain moral rights (including rights
              of attribution or integrity) in the User Content, you hereby
              declare that (a) you do not require that any personal data,
              personal information, personally identifying information, or
              equivalent term be used in connection with the User Content, or
              any derivative works of or upgrades or updates thereto; (b) you
              have no objection to the publication, use, modification, deletion
              and exploitation of the User Content by KINGDOM or its licensees,
              successors and assigns; (c) you forever waive and agree not to
              claim or assert any entitlement to any and all moral rights of an
              author in any of the User Content; and (d) you forever release
              KINGDOM, and its licensees, successors and assigns, from any
              claims that you could otherwise assert against KINGDOM, or any of
              its assigns or affiliates, by virtue of any such moral rights. You
              also permit any other user to access, view, store, or reproduce
              the User Content for that user’s personal use.
            </p>{" "}
            <p>
              User Content submitted by you will be nonexclusive and KINGDOM is
              under no obligation to treat such User Content as proprietary
              information. Without limiting the foregoing, we reserve the right
              to use any User Content as it deems appropriate, including,
              without limitation, deleting, editing, modifying, rejecting, or
              refusing to post it. KINGDOM is under no obligation to edit,
              delete or otherwise modify User Content once it has been submitted
              to us. We shall have no duty to attribute authorship of User
              Content to you, and shall not be obligated to enforce any form of
              attribution by third parties.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#intellectual-property"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="intellectual-property"
              >
                Intellectual Property
              </h2>
            </a>{" "}
            <p>
              The Services, the content, any materials or information
              downloaded, and all intellectual property pertaining to or
              contained on the Services (including but not limited to
              copyrights, patents, database rights, graphics, designs, text,
              logos, trade dress, trademarks, and service marks) are owned by
              KINGDOM or third parties; all rights, title, and interest will
              remain the property of KINGDOM and/or such third-party owner, as
              applicable. All content is protected by trade dress, copyright,
              patent, and trademark laws, as well as various other intellectual
              property and unfair competition laws.
            </p>{" "}
            <p>
              You are authorized to view and retain a copy of pages of the
              Services only for your own personal, non-commercial use. You may
              also view and make copies of relevant documents, pages, images, or
              other materials on the Services for the purpose of transacting
              business with us. You may not tokenize copies of the pages of the
              Services or any other content on the Services. You agree that you
              will not duplicate, publish, modify, create derivative works from,
              participate in the transfer of, or in any way distribute or
              exploit the Services, or any portion of the Services, for any
              public or commercial use, without our prior express written
              consent. Additionally, you agree that you: (a) will not remove or
              alter any author, trademark, other proprietary notice, or legend
              displayed on the Services (or printed pages produced from the
              Services), and (b) will not make any other modifications to any
              documents obtained from the Services other than in connection with
              completing information required to transact business with KINGDOM.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#use-of-information-and-materials"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="use-of-information-and-materials"
              >
                Use of Information and Materials
              </h2>
            </a>{" "}
            <p>
              The information and materials contained on the Services, and these
              Terms, policies, and descriptions on the Services, are subject to
              change. You accept sole responsibility for all of your activities
              using the Services. Your use of the Services is limited to the
              intended function of the Services. Unauthorized use of the
              Services and systems, including but not limited to unauthorized
              entry into our systems or misuse of any information posted on the
              Services, is strictly prohibited. You may not use the Services in
              a manner that:
            </p>{" "}
            <ol className="list-[lower-alpha] list-inside ml-6">
              <li>
                harasses, abuses, stalks, threatens, defames, or otherwise
                infringes or violates the rights of any party (including but not
                limited to rights of publicity or other proprietary rights);
              </li>{" "}
              <li>is unlawful, fraudulent, or deceptive;</li>{" "}
              <li>
                uses technology or other means to access content or systems of
                KINGDOM in a manner that we do not authorized;
              </li>{" "}
              <li>
                uses or launches any automated system, including, without
                limitation, “robots,” “spiders,” or “offline readers,” to access
                our content or systems;
              </li>{" "}
              <li>
                attempts to introduce viruses or any other computer code, files,
                or programs that interrupt, destroy, or limit the functionality
                of any computer software, hardware, or telecommunications
                equipment;
              </li>{" "}
              <li>
                attempts to gain unauthorized access to our computer network or
                user accounts;
              </li>{" "}
              <li>
                encourages conduct that would constitute a criminal offense or
                that gives rise to civil liability;
              </li>{" "}
              <li>violates these Terms or any of our other policies;</li>{" "}
              <li>
                attempts to damage, disable, overburden, or impair our servers
                or networks;
              </li>{" "}
              <li>fails to comply with applicable third-party terms; or</li>{" "}
              <li>
                constitutes any other inappropriate conduct, as determined by us
                in our sole discretion.
              </li>
            </ol>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#system-outages-slowdowns-and-capacity-limitations"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="system-outages-slowdowns-and-capacity-limitations"
              >
                System Outages, Slowdowns, and Capacity Limitations
              </h2>
            </a>{" "}
            <p>
              At times you may experience difficulty accessing the Services or
              communicating with KINGDOM through the Internet, or other
              electronic wireless services, as a result of high Internet
              traffic, transmission problems, systems capacity limitations, or
              other problems. Any computer system or other electronic device,
              whether it is yours, an Internet service provider’s, or ours, can
              experience unanticipated outages or slowdowns or have capacity
              limitations. We are not responsible for failure or delay of
              performance caused by such problems.
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#security">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="security"
              >
                Security
              </h2>
            </a>{" "}
            <p>
              KINGDOM makes no warranty whatsoever to you, express or implied,
              regarding the security of the Services, including with respect to
              the ability of unauthorized persons to intercept or access
              information transmitted by you through the Services. You
              acknowledge and agree that you are solely responsible for
              maintaining the security of your devices and passwords. You are
              also solely responsible for maintaining the security of keys,
              passwords, or similar access restrictions to your digital wallet.
              We are not responsible for any losses resulting from the loss or
              theft of your device, the loss or theft of your information
              transmitted from or stored on your devices, or any losses arising
              from the use of your device by someone whom you have given access,
              including without limitation loss of your KINGDOM Pet Post. You
              are required to notify us of any unauthorized use of the Services
              by using the contact information in the{" "}
              <strong>“Contact Us</strong>” section below.
            </p>{" "}
            <p>
              To protect the security of your information, we may require you to
              authenticate your identity (<em>i.e.</em>, prove that you are who
              you say you are) when you use the Services.&nbsp;
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#links-to-other-websites-and-services"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="links-to-other-websites-and-services"
              >
                Links to Other Websites and Services
              </h2>
            </a>{" "}
            <p>
              The Services may contain links to outside services and resources,
              the availability and content of which we do not control. We are
              not responsible for examining or evaluating, and we do not warrant
              the offering of these services and resources or the content of
              these websites.&nbsp;
            </p>{" "}
            <p>
              We do not assume any responsibility or liability for the actions,
              products, and content of these and any other websites. Any
              concerns regarding any such services or resources should be
              directed to the service or resource.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#severabilityno-waiver"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="severabilityno-waiver"
              >
                Severability/No Waiver
              </h2>
            </a>{" "}
            <p>
              If any provision of these Terms shall be deemed unlawful, void, or
              unenforceable for any reason, then such provision shall be deemed
              severable from these Terms and shall not affect the validity and
              enforceability of any remaining provisions.&nbsp;
            </p>{" "}
            <p>
              KINGDOM’s failure to enforce the strict performance of any
              provision of these Terms or the additional terms and conditions
              for any product or service will not constitute a waiver of our
              right to subsequently enforce such provision or any other
              provisions of these Terms or the additional terms and conditions.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#limitation-of-liability"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="limitation-of-liability"
              >
                Limitation of Liability
              </h2>
            </a>{" "}
            <p>
              To the maximum extent permitted by law, KINGDOM, and its
              subsidiaries, affiliates, officers, directors, employees,
              investors, and agents, will not be liable to you for any lost
              profits, revenues, financial losses, or any indirect, special,
              consequential, exemplary, or punitive damages of any kind. To the
              maximum extent permitted by law, the total liability of KINGDOM,
              and its subsidiaries, affiliates, officers, directors, employees,
              and agents, for any claim, cost, damage, or loss arising out of or
              relating to the Services, including for any warranties that may
              not be excluded, shall not exceed the lesser of the amount you
              paid to us during the twelve months prior to the event giving rise
              to the liability or $10.00 (ten dollars) (the “
              <strong>Liability Cap</strong>”). KINGDOM and you agree that the
              Liability Cap shall include all forms of damages.
            </p>{" "}
            <p>
              Some jurisdictions do not allow the exclusion or limitation of
              liability for certain types of damages. In such jurisdictions, our
              liability is limited to the greatest extent permitted by law.
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#disclaimers">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="disclaimers"
              >
                Disclaimers
              </h2>
            </a>{" "}
            <p>
              THE SERVICES ARE PROVIDED TO YOU ON AN “AS-IS” AND “AS-AVAILABLE”
              BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND,
              WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE
              IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, AND NON-INFRINGEMENT. KINGDOM MAKES NO WARRANTY THAT (A)
              THE SERVICES WILL MEET YOUR REQUIREMENTS, (B) THAT OPERATION OF
              THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
              OR (C) THE CONTENT THAT MAY BE OBTAINED FROM THE USE OF THE
              SERVICES WILL BE ACCURATE OR RELIABLE. KINGDOM UNDERTAKES NO
              OBLIGATION TO UPDATE THE SERVICES. NO ADVICE OR INFORMATION,
              WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US, OR THROUGH THE
              SERVICES SHALL CREATE ANY WARRANTY REGARDING THE SERVICES NOT
              EXPRESSLY STATED IN THESE TERMS. YOU UNDERSTAND AND ACKNOWLEDGE
              THAT ADDITIONAL DISCLAIMERS, LIMITATIONS, AND NOTICES REGARDING
              THE SERVICES AND ITS CONTENT AND DATA MAY BE PROVIDED BY KINGDOM
              FROM TIME TO TIME WITHIN THE SERVICES.
            </p>{" "}
            <p>
              WE DO NOT WARRANT THAT THE SERVICES WILL OPERATE ERROR-FREE OR
              THAT THE SERVICES AND ITS SERVERS ARE FREE OF COMPUTER VIRUSES OR
              OTHER HARMFUL CONTENT. IF YOUR USE OF THE SERVICES, THE MATERIAL,
              OR THE SOFTWARE RESULTS IN THE NEED FOR SERVICING OR REPLACING
              EQUIPMENT OR DATA, WE ARE NOT RESPONSIBLE FOR THOSE COSTS.
            </p>{" "}
            <p>
              NOTHING IN THE SERVICES SHALL BE CONSTRUED AS LEGAL, FINANCIAL,
              MEDICAL, OR OTHER ADVICE, AND YOU ARE SOLELY RESPONSIBLE FOR
              MAKING ALL DECISIONS TO COMPLY WITH ALL APPLICABLE LAWS,
              REGULATIONS, CODES, OR STANDARDS.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#indemnification"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="indemnification"
              >
                Indemnification
              </h2>
            </a>{" "}
            <p>
              You will defend, indemnify, and hold us harmless from and against
              any actual or threatened suit, actions, proceedings (at law or in
              equity), claims, damages, payments, deficiencies, fines,
              judgments, settlements, liabilities, losses, costs, and expenses
              (including reasonable attorneys’ fees, costs, penalties, interest,
              and disbursements) arising from or related to your conduct with
              respect to the Services or violation (or alleged violation) of
              these Terms. Under no circumstance will we be liable for damages
              of any kind that result from your use of, or the inability to use,
              the Services.&nbsp;
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#termination">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="termination"
              >
                Termination
              </h2>
            </a>{" "}
            <p>
              We may terminate or suspend your access to the Services, delete
              your account and any content or information or prohibit you from
              using or accessing the Services (or any portion, aspect or feature
              of the Services) if you violate these Terms, effective
              immediately, which may result in the forfeiture and destruction of
              all information associated with you and your activities in
              connection with the Services. We may also terminate your account
              without cause for convenience upon written notice. If you wish to
              terminate your account, you may do so by following the
              instructions on the Services. Any fees paid hereunder are
              non-refundable. In the event of termination, you will still be
              bound by your obligations under these Terms. We reserve the right
              to retain and use data associated with Your Account after
              termination to the extent permissible by law.
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#changes">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="changes"
              >
                Changes
              </h2>
            </a>{" "}
            <p>
              You are responsible for reviewing these Terms regularly. KINGDOM
              reserves the right, at any time, without notice to you, and in its
              sole discretion, to modify or discontinue the Services, these
              Terms, or any of our policies related to use of the Services. We
              will not amend the Arbitration Provision or Class Action Waiver,
              described below, in a manner that adversely affects your rights or
              responsibilities in a material manner unless we give you a right
              to reject the amendment and/or the Arbitration Provision and Class
              Action Waiver in its entirety. Revisions to these Terms or our
              policies may be provided through the Services, including by
              posting the revisions on the Services and updating the “Last
              Updated” date. Such revisions will go into immediate effect once
              posted to the Services. Continued use of the Services following
              such modifications to the Services, these Terms, additional terms
              and conditions for any service, or our other policies will
              constitute your acceptance of such modifications and revisions.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#arbitration-provision-and-class-action-waiver-the-arbitration-provision"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="arbitration-provision-and-class-action-waiver-the-arbitration-provision"
              >
                ARBITRATION PROVISION AND CLASS ACTION WAIVER (“the Arbitration
                Provision”)
              </h2>
            </a>{" "}
            <p>
              <strong>
                THIS ARBITRATION PROVISION AFFECTS YOUR RIGHTS; PLEASE READ
                CAREFULLY BEFORE AGREEING TO THESE TERMS OF USE.
              </strong>
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#agreement-to-arbitrate"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="agreement-to-arbitrate"
              >
                Agreement to Arbitrate
              </h2>
            </a>{" "}
            <p>
              Except as otherwise explicitly provided in this Arbitration
              Provision, KINGDOM, and any bank or financial institution with
              which KINGDOM partners, together with their parent companies,
              wholly or majority-owned subsidiaries, affiliates, commonly-owned
              companies, successors, assigns, and any of these entities’
              employees, officers, directors, and agents and their successors,
              assigns, affiliates, and service providers (collectively, the “
              <strong>Transaction Parties</strong>”) and you can elect to
              resolve any past, present, or future dispute or claim (“
              <strong>Dispute</strong>”) arising from or relating in any way to
              (i) the use of the Services, (ii) these Terms of Use, or (iii) the
              relationship between you and any Transaction Party in connection
              with any of the foregoing that cannot be resolved directly between
              you and a Transaction Party, by binding arbitration under the
              Consumer Arbitration Rules (“<strong>the Consumer Rules</strong>”)
              of the American Arbitration Association (“<strong>AAA</strong>”),
              rather than in court. (Solely for purposes of this Arbitration
              Provision, the term Transaction Parties also includes any third
              party providing any goods and/or services in connection with the
              use of the Site or any of the foregoing on behalf of a Transaction
              Party, if that third party is named as a defendant along with a
              Transaction Party in the same proceeding or a related proceeding.)
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#dispute-defined"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="dispute-defined"
              >
                “Dispute” Defined
              </h2>
            </a>{" "}
            <p>
              Except as otherwise explicitly provided in this Arbitration
              Provision, “Dispute” broadly includes, without limitation: any
              claims based in contract, statute, constitution, ordinance, tort,
              fraud, consumer rights, misrepresentation, equity, or any other
              legal theory; initial claims, counterclaims, cross-claims, and
              third-party claims; federal, state, and local claims; and claims
              which arose before the date of your use of the Services,
              including, but not limited to, any dispute or claim arising before
              the date you accessed this Site or agreed to these Terms of Use
              and any dispute or claim relating to (by way of example and not
              limitation): (i) the use, denial, or termination of the Services
              and/or the events leading up thereto; (ii) any disclosure,
              advertisement, application, solicitation, promotion, or oral or
              written statement, warranty, or representation made by or on
              behalf of a Transaction Party; (iii) any product or service
              provided by or through a Transaction Party or third parties in
              connection with the use of the Services or the relationship
              between you and a Transaction Party and any associated fees; (iv)
              a Transaction Party’s use or failure to protect any personal
              information you give a Transaction Party in connection with the
              use of the Services or your relationship with the Transaction
              Party; (v) enforcement of any and all of the obligations a party
              may have to another party in connection with the use of the
              Services or agreement governing the same; or (viii) compliance
              with applicable laws and/or regulations.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#exceptions-to-dispute"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="exceptions-to-dispute"
              >
                Exceptions to “Dispute”
              </h2>
            </a>{" "}
            <p>
              Notwithstanding any of the foregoing: (i) disputes or
              controversies about the validity, enforceability, coverage, or
              scope of this Arbitration Provision or any part thereof are for a
              court and not an arbitrator to decide; however, disputes or
              controversies about these Terms of Use or your agreements
              governing the use of the Services or with Transaction Parties as a
              whole are for an arbitrator and not a court to decide; (ii) any
              Disputes seeking to enforce or protect, or concerning the validity
              of intellectual property rights, will not be subject to binding
              arbitration under this Arbitration Provision; and (iii) any party
              may proceed with their individual claims in small claims court (or
              an equivalent court) if that option is available in the applicable
              jurisdiction and the amount in controversy falls within the small
              claims court’s (or the equivalent court’s) jurisdictional limits;
              but if that action is transferred, removed, or appealed to a
              different court, arbitration can be elected. Moreover, this
              Arbitration Provision will not apply to any Dispute that was
              already pending in court before this Arbitration Provision took
              effect.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#governing-law"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="governing-law"
              >
                Governing Law
              </h2>
            </a>{" "}
            <p>
              The Federal Arbitration Act (“<strong>FAA</strong>”), 9 U.S.C. 1
              et seq., and federal arbitration law apply to this Arbitration
              Provision. There is no judge or jury in arbitration and court
              review of an arbitration award is limited, but an arbitrator can
              award an individual the same damages and relief as a court in an
              individual case and must apply and follow applicable substantive
              law, consistent with the FAA, and the terms of these Terms of Use
              and any agreement governing the use of the Site. The arbitrator
              shall apply applicable statutes of limitations and honor privilege
              rules. Any judgment on the award rendered by the arbitrator will
              be final, subject to any appeal rights under the FAA, and may be
              entered in any court of competent jurisdiction. No arbitration
              award involving the parties will have any preclusive effect as to
              issues or claims in any dispute involving anyone who is not a
              party to the arbitration, nor will an arbitration award in prior
              disputes involving other parties have preclusive effect in an
              arbitration between the parties to this Arbitration Provision.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#jury-trial-waiver"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="jury-trial-waiver"
              >
                JURY TRIAL WAIVER
              </h2>
            </a>{" "}
            <p>
              YOU UNDERSTAND THAT ABSENT YOUR CONSENT TO ARBITRATION, YOU WOULD
              HAVE THE RIGHT TO SUE IN COURT AND HAVE A TRIAL BY JURY.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#arbitration-procedures"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="arbitration-procedures"
              >
                Arbitration Procedures
              </h2>
            </a>{" "}
            <p>
              Either you or a Transaction Party can initiate arbitration through
              the AAA or by filing a motion to compel arbitration of claims
              filed in court. Regardless of who elected arbitration or how
              arbitration was elected, the party asserting the claim (i.e., the
              party seeking money damages or other relief from a court or an
              arbitrator) is responsible for starting the arbitration
              proceeding. If the AAA cannot serve and we cannot agree on a
              substitute, a court with jurisdiction shall select the arbitrator,
              who will apply the AAA rules and the procedures specified in this
              Arbitration Provision. Any arbitrator must be a practicing
              attorney with ten or more years of experience practicing law or a
              retired judge. Any arbitration will be governed by the
              then-current Consumer Rules, and its Procedures for the Resolution
              of Disputes through Document Submission (“
              <strong>Document Submission Procedures</strong>”). For more
              information about the AAA and its rules, you may contact the AAA
              at American Arbitration Association, 120 Broadway, Floor 21, New
              York, N.Y. 10271, 1-800-778-7879, www.adr.org. The Document
              Submission Procedures are included in the Consumer Rules. The
              parties shall pay filing, administrative and arbitrator fees in
              accordance with the Consumer Rules. If you cannot afford to pay
              your share of the fees and cannot obtain a waiver from the AAA,
              you can make a written good faith request for a Transaction Party
              to pay or advance such fees. Each party will bear the expense of
              its own attorneys, experts and witnesses, regardless of which
              party prevails, unless applicable law, these Terms of Use or an
              applicable agreement gives a party the right to recover such
              expenses from the other party. If the arbitrator determines that
              any party’s claim or defense is frivolous or wrongfully intended
              to oppress or harass the other party, the arbitrator may award
              sanctions in the form of fees and expenses reasonably incurred by
              the other party if such sanctions could be imposed under Rule 11
              of the Federal Rules of Civil Procedure. The arbitration will be
              conducted by a single arbitrator solely based on written
              submissions and will not require any personal appearance by the
              parties or witnesses unless the arbitrator determines that a
              telephonic or in-person conference or hearing is necessary based
              on the request of one or more of the parties. If an in-person
              hearing is necessary, it will be held in the federal judicial
              district in which you reside or at another location that is
              reasonably convenient to all parties.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#class-action-waiver"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="class-action-waiver"
              >
                CLASS ACTION WAIVER
              </h2>
            </a>{" "}
            <p>
              You and the Transaction Parties each agree that if arbitration of
              a Dispute is elected, the Dispute will be resolved in arbitration
              (not in court) only on an individual basis and not as a class,
              collective or other representative action. To the fullest extent
              permitted under the FAA: (a) no arbitration will be joined or
              consolidated with any other unless all parties otherwise agree in
              writing; (b) there is no right or authority for any Dispute to be
              arbitrated on a class-action, collective action or private
              attorney general basis or to utilize class action procedures; and
              (c) there is no right or authority for any Dispute to be brought
              in a purported representative capacity on behalf of the general
              public or any other persons. No arbitrator shall have the
              authority to issue any relief that applies to any person or entity
              other than Transaction Parties and/or you individually.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#miscellaneous-conflicts"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="miscellaneous-conflicts"
              >
                Miscellaneous; Conflicts
              </h2>
            </a>{" "}
            <p>
              Even if all parties have opted to litigate a Dispute in court, a
              party may elect arbitration with respect to any claim made by a
              new party or any claim later asserted by a party in that or any
              related or unrelated lawsuit (including a claim initially asserted
              on an individual basis but modified to be asserted on a class,
              representative, or multi-party basis). Nothing in that litigation
              shall constitute a waiver of any rights under this Arbitration
              Provision. If any portion of this Arbitration Provision is
              inconsistent with the Consumer Rules, with these Terms of Use, or
              your agreements governing the use of the Services, or with an
              arbitration provision in any agreement with a Transaction Party,
              this Arbitration Provision shall govern.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#survival-severance"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="survival-severance"
              >
                Survival; Severance
              </h2>
            </a>{" "}
            <p>
              This Arbitration Provision shall survive termination of the Site
              or these Terms of Use or of any agreement into which you enter
              with a Transaction Party; and, further, you understand and agree
              that this Arbitration Provision applies not only to these Terms of
              Use but also to any subsequent agreement (including without
              limitation any agreement governing the use of the Site or
              Platform) into which you enter with a Transaction Party. If any
              portion of this Arbitration Provision is deemed invalid or
              unenforceable, it shall not invalidate the remaining portions of
              this Arbitration Provision, except that: (a) if the Class Action
              Waiver is limited, voided or found unenforceable with respect to a
              Dispute that does not seek public injunctive relief and that
              determination becomes final after all appeals have been exhausted,
              then this Arbitration Provision (except for this sentence) shall
              be null and void with respect to such proceeding. The parties
              acknowledge and agree that under no circumstances will a class
              action be arbitrated; and (b) if a claim is brought seeking public
              injunctive relief and a court determines that the restrictions in
              the Class Action Waiver or elsewhere in this Arbitration Provision
              prohibiting the arbitrator from awarding relief on behalf of third
              parties are unenforceable with respect to such claim and that
              determination becomes final after all appeals have been exhausted,
              the claim for public injunctive relief will be determined in court
              and any individual claims seeking monetary relief will be
              arbitrated. In such a case the parties will request that the court
              stay the claim for public injunctive relief until the arbitration
              award pertaining to individual relief has been entered in court.
              In no event will a claim for public injunctive relief be
              arbitrated.
            </p>{" "}
            <p>
              <strong>
                RIGHT TO OPT OUT: If you do not want this Arbitration Provision
                to apply, you must send us a signed notice within 30 calendar
                days of the date on which you electronically submit an
                application for a product or service offered by a Transaction
                Party through this Site or Platform. You must send the notice in
                writing (and not electronically) to team@pawtocol.com. You must
                provide your name, address, telephone number, and state that you
                “opt out” of the Arbitration Provision. Opting out will not
                affect the other provisions of these Terms of Use or any other
                agreement governing the Site or with a Transaction Party. If you
                do not opt out, you will be bound by this Arbitration Provision
                in these Terms of Use and any other agreement governing a
                Service or with a Transaction Party. Please note that if you
                enter an agreement with a Transaction Party that contains a
                different arbitration provision with a right to opt out, opting
                out of this Arbitration Provision is not a rejection of the
                arbitration provision in the other agreement. You will need to
                separately opt out of the arbitration provision in the other
                agreement if you do not want it to apply.
              </strong>
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#miscellaneous-provisions"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="miscellaneous-provisions"
              >
                Miscellaneous Provisions
              </h2>
            </a>{" "}
            <p>
              These Terms constitute the entire agreement of the parties with
              respect to the subject matter hereof and supersede all previous
              written or oral agreements between the parties with respect to
              such subject matter. No waiver by either party of any breach
              hereunder shall be deemed to be a waiver of any preceding or
              subsequent breach. The section headings used herein are for
              convenience only and shall not be given any legal import. Anything
              not covered by the Arbitration Provision shall be governed by and
              construed in accordance with the law (without regard to provisions
              relating to conflicts of law), except as to copyright and
              trademark matters, which are covered by federal laws. You agree
              that any legal action or proceeding between KINGDOM and you for
              any purpose concerning these Terms or the parties' obligations
              hereunder shall be resolved individually, without resort to any
              form of class action, exclusively in the courts of Florida, and
              you agree to submit to the jurisdiction of these courts.
            </p>{" "}
            <p>
              Our failure to insist upon or enforce strict performance of any
              provision of these Terms shall not be construed as a waiver of any
              provision or right. Neither the course of conduct between the
              parties nor trade practice shall act to modify any of these Terms.
            </p>{" "}
            <p>
              We may assign its rights and duties under these Terms to any party
              at any time without notice to you.
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#contact-us">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="contact-us"
              >
                Contact Us
              </h2>
            </a>{" "}
            <p>
              If you have any questions regarding the Services or these Terms,
              please feel free to send us an email at team@pawtocol.com
            </p>
          </div>
        </div>
      </div>
      </>
    </FullLayout>
  );
};

export default TermsOfServices;
