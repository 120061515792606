import FullLayout from "components/layout/FullLayout";
import React from "react";
import { Helmet } from "react-helmet-async";
import "./style.scss";

const pageContent = {
  pageTitle: "Privacy",
  content: [
    {
      id: 1,
      title: "",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 2,
      title: "Heading One",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, laborum. Doloribus reiciendis, ullam ipsum commodi tenetur illo eos voluptates aperiam voluptas cupiditate perspiciatis voluptate cum aspernatur enim dolor exercitationem nisi molestiae a odit, soluta similique voluptatibus dicta necessitatibus quia? Molestiae tenetur dicta ex, laboriosam quod perspiciatis magnam, vero rem nobis vel nihil animi ut sunt excepturi non explicabo voluptatum illo! Voluptates consequuntur at ullam ab libero explicabo quod temporibus eum? Quisquam dolor, aspernatur expedita ullam cumque molestiae necessitatibus inventore. Accusamus eveniet cum, recusandae totam tempora possimus quo dolorum. sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 3,
      title: "Heading Two",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, laborum. Doloribus reiciendis, ullam ipsum commodi tenetur illo eos voluptates aperiam voluptas cupiditate perspiciatis voluptate cum aspernatur enim dolor exercitationem nisi molestiae a odit, soluta similique voluptatibus dicta necessitatibus quia? Molestiae tenetur dicta ex, laboriosam quod perspiciatis magnam, vero rem nobis vel nihil animi ut sunt excepturi non explicabo voluptatum illo! Voluptates consequuntur at ullam ab libero explicabo quod temporibus eum? Quisquam dolor, aspernatur expedita ullam cumque molestiae necessitatibus inventore. Accusamus eveniet cum, recusandae totam tempora possimus quo dolorum. sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 4,
      title: "The Third Heading",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 4,
      title: "Another Heading",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 5,
      title: "A very long heading nobody is ready for because law",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores velit accusantium nihil reprehenderit, ipsum deserunt officia fugiat veniam ut, minima explicabo quaerat. Iusto nemo necessitatibus optio! Placeat facere sint perspiciatis nisi consectetur sed adipisci earum libero dolorum labore fugit laudantium, ea provident ullam fuga dignissimos nemo veritatis magnam? Odio, beatae? Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
  ],
};

const Privacy = () => {
  return (
    <FullLayout>
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>Privacy Policy | KINGDOM</title>
        <meta property="og:title" content="Privacy Policy" />
        <meta name="description" content="Read KINGDOM's Privacy Policy to learn about the information we collect, how it's used, and the control you have over it." />
        <meta property="og:description" content="Read KINGDOM's Privacy Policy to learn about the information we collect, how it's used, and the control you have over it." />
        <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
      <div className="static-page">
        <div className="mt-20 mb-20 px-4 max-w-4xl mx-auto space-y-3">
          
          {/* {pageContent.content.map((item) => (
            <div key={item.id}>
              {item?.title && <h3 className="title">{item.title}</h3>}
              {item?.para?.length > 0 &&
                item.para.map((singlePara) => (
                  <p className="subtitle" key={`${item.id}-${singlePara.id}`}>
                    {singlePara.text}
                  </p>
                ))}
            </div>
          ))} */}
          <div className="mt-20 mb-20 px-4 max-w-4xl mx-auto space-y-3">
            {/* <h1 className="text-3xl md:text-5xl font-bold font-title mb-12">
              Privacy Policy
            </h1> */}
            <h1 className="page-title mb-12">Privacy Policy</h1>
            <span className="text-sm bg-gray-200 rounded p-2 !-mt-6 !mb-6 max-w-[max-content] block">
              Last updated: November 1, 2022
            </span>
            <p>
              Your privacy is important to us at Kingdom and its affiliates
              (collectively, “<strong>Pawtocol</strong>” “
              <strong>Kingdom</strong>,” “<strong>we</strong>,” “
              <strong>us</strong>,” or “<strong>our</strong>”), and we are
              committed to safeguarding, preserving, and respecting your privacy
              rights. This online privacy statement (the “
              <strong>Statement</strong>” or “<strong>Policy</strong>”)
              describes how we collect, use, disclose, and secure the personal
              information we gather about you through our website,
              www.enterkingdom.io (the “<strong>Site</strong>”), and when you
              interact with us as a customer or otherwise (collectively, the “
              <strong>Services</strong>”).
            </p>
            <p>
              For purposes of this Statement, personal information means data
              that classifies as personal information, personal data, personally
              identifiable information, or similar terms under applicable data
              privacy and security laws and regulations. It does not include
              data excluded or exempted from those laws and regulations,
              including aggregated, anonymized, or de-identified data. Nothing
              in this Statement will constitute an admission or evidence that
              any particular data privacy or information security law or
              regulation applies to KINGDOM generally or in any specific
              context.
            </p>
            <a
              className="no-underline text-gray-800 block"
              href="#you-consent-to-this-statement"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="you-consent-to-this-statement"
              >
                You Consent to This Statement
              </h2>
            </a>
            <p>
              You should read this Statement carefully. We recommend printing
              and retaining a copy for your future reference. By accessing,
              browsing, or otherwise using the Services, you confirm that you
              have read, understood, and agreed with this Statement. If you do
              not agree to this Statement, you may not use the Services.
            </p>
            <p>
              This Statement applies regardless of how the Services are accessed
              and will cover any technologies or devices by which we make the
              Services available to you.
            </p>{" "}
            <p>
              We may provide you with additional privacy notices where we
              believe it is appropriate to do so. It is important that you read
              this Statement together with any other privacy notice or terms we
              may provide on specific occasions, so that you are fully aware of
              how and why we are using your data. This Statement supplements
              these other notices and is not intended to override them.
            </p>
            <p>
              Your use of our Services, and any dispute over privacy, is subject
              to this Policy and our Terms of Use incorporated by reference into
              this Policy.
            </p>
            <p>
              If you have any questions or concerns about our personal
              information policies or practices, you can contact us in the
              methods described in the<strong>“Contact Us</strong>” section
              below.
            </p>
            <a
              className="no-underline text-gray-800 block"
              href="#what-information-do-we-collect-about-you"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="what-information-do-we-collect-about-you"
              >
                What Information Do We Collect About You?
              </h2>
            </a>
            <p>
              We collect the information you voluntarily provide directly to us,
              such as when you complete a form or submit information to us. We
              also collect information when you interact with the Services, for
              example, through our use of cookies.&nbsp;
            </p>
            <h3 className="text-lg font-bold">Information You Provide</h3>
            <ul className="list-disc list-outside ml-6">
              <li>
                <em>Creating an Account.</em>&nbsp;We require you to create an
                account to access certain areas, features, or products of our
                Sites. If you create an account with us, we collect information
                &nbsp;such as your name, phone number, email address, and street
                address.
              </li>
              <li>
                <em>When You Use our Services.</em>&nbsp;If you use our
                Services, we may collect certain analytical information as
                outlined below.
              </li>
              <li>
                <em>When You Contact Us.</em> If you send us an email or
                otherwise contact us, we will collect your email address and any
                information that you provide in the email.
              </li>
              <li>
                <em>Social Media</em>. If you interact with our Services by
                sharing on a blog or another social media platform, we may
                collect information that you post. Please note that your
                comments will be visible to the public, so you should never
                share personal information that you would like to keep private.
              </li>
              <li>
                <em>When You Submit Content</em>. If you post, upload, comment,
                or otherwise submit content on the Services, we may collect the
                public information you submit. While the information will vary
                depending on what you submit, it may include profile name,
                social media handles, email and pet information. Be aware that
                as a default, any information you post on the Services will be
                available to and searchable by all users of the Services.
              </li>
              <li>
                <em>Job Applications and Employment</em>. If you apply for a job
                with or are employed or contracted by us, we will collect
                various pieces of information about you.
              </li>
            </ul>
            <h3 className="text-lg font-bold">
              Information We Collect as You Navigate Our Services
            </h3>
            <p>
              We automatically collect certain information as you use the
              Services, such as the following:
            </p>
            <ul className="list-disc list-outside ml-6">
              <li>
                <em>Usage Information</em>. We may collect information
                automatically through your use of the Services, such as which of
                the pages on the Services you access, the frequency of access,
                interactions with our email messages (including information
                about the devices used to access the messages), and referring
                URL.
              </li>{" "}
              <li>
                <em>Device Information.</em> We may collect information about
                the device you are using, such as hardware model, operating
                system, application version number, browser, and IP addresses.
              </li>{" "}
              <li>
                <em>Mobile Device Information</em>. When you access our Services
                via a browser on your mobile device, we may also collect mobile
                network information, including mobile carrier, operating system,
                geolocation information, and other device attributes.
              </li>
            </ul>{" "}
            <h3 className="text-lg font-bold">
              Information We Collect From Other Sources
            </h3>{" "}
            <h4 className="font-bold">
              Information you provide directly to us.&nbsp;
            </h4>{" "}
            <p>
              For certain activities, such as when you register, subscribe to
              our alerts, or contact us directly, we may collect some or all of
              the following types of information:
            </p>{" "}
            <p>
              Contact information, such as your full name or email address; and
              any other information you provide to us.
            </p>{" "}
            <h4 className="font-bold">Information we collect automatically</h4>{" "}
            <p>
              We may collect certain information automatically when you use our
              Sites, such as your Internet protocol (IP) address, device and
              advertising identifiers, browser type, operating system, Internet
              service provider, pages that you visit before and after using the
              Sites, the date and time of your visit, information about the
              links you click, the pages you view, the general manner in which
              you navigate the Sites, and other standard server log information.
              We may also collect certain location information when you use our
              Sites, such as your computer’s IP address, your mobile device’s
              GPS signal, or information about nearby WiFi access points and
              cell towers.
            </p>{" "}
            <p>
              We and third parties that provide content or functionality on our
              Sites, may use cookies, pixel tags, Local Shared Objects, and
              similar technologies to automatically collect this information.
              For more information about cookies, pixel tags, Local Shared
              Objects, and similar technologies, please see the “Cookies”
              section below.
            </p>{" "}
            <p>
              We may also collect technical data to address and fix technical
              problems and improve our Sites, including the memory state of your
              device when a system or app crash occurs while using our Sites.
              Your device or browser settings may permit you to control the
              collection of this technical data. This data may include parts of
              a document you were using when a problem occurred, or the contents
              of your communications. By using the Sites, you are consenting to
              the collection of this technical data.
            </p>{" "}
            <p>
              We may also receive information about you from other sources,
              including through third-party services and organizations. For
              example, if you access third-party services, such as Facebook,
              Google, or Twitter, through the Sites to login to the Sites or to
              share information about your experience on the Sites with others,
              we may collect information from these third-party services.
            </p>{" "}
            <h3 className="text-lg font-bold">
              How Do We Use “Cookies” and Other Tracking Technologies?&nbsp;
            </h3>{" "}
            <p>
              We may send one or more Cookies to your computer or other device.
              We may also use other similar technologies such as tracking
              pixels, tags, or similar tools when you visit our Services. These
              technologies can collect data regarding your operating system,
              browser type, device type, screen resolution, IP address, and
              other technical information, as well as navigation events and
              session information as you interact with our Services. This
              information allows us to understand how you use the Services.
            </p>{" "}
            <h3 className="text-lg font-bold">What Are Cookies?</h3>{" "}
            <p>
              Cookies are small files created by websites, including our
              Services, that reside on your computer’s hard drive and that store
              information about your use of a particular website. When you
              access our Services, we use Cookies and other tracking
              technologies to:
            </p>{" "}
            <ul className="list-disc list-outside ml-6">
              <li>Estimate our audience size and usage patterns;</li>{" "}
              <li>
                Store information about your preferences, allowing us to
                customize our Services according to your individual needs;
              </li>{" "}
              <li>
                Contact you to provide you with information or services that you
                request from us;
              </li>{" "}
              <li>
                Advertise new content, events, and services that relate to your
                interests;
              </li>{" "}
              <li>
                Provide you with more personalized content that is most relevant
                to your interest areas; and
              </li>{" "}
              <li>Recognize when you return to our Services.</li>
            </ul>{" "}
            <p>
              We set some Cookies ourselves and others are set by third parties.
              You can manage your Cookies preference as described in the “
              <strong>Managing Your Cookies</strong>” section below.
            </p>{" "}
            <h3 className="text-lg font-bold">
              What Types of Cookies Do We Use and Why?
            </h3>{" "}
            <p>
              The following lists the different types of Cookies that we and our
              service providers use on the Services, examples of who serves
              those Cookies and links to the privacy notices and opt-out
              information of those Cookie servers.
            </p>{" "}
            <p>
              Kingdom and online services may use “cookies.” Cookies enable you
              to use shopping carts and to personalize your experience on our
              sites, tell us which parts of our websites people have visited,
              help us measure the effectiveness of ads and web searches, and
              give us insights into user behavior so we can improve our
              communications and products.
            </p>{" "}
            <p>
              Because cookies are used throughout our websites, disabling them
              may prevent you from using certain parts of the sites.
            </p>{" "}
            <p>
              We use the following categories on our websites and other online
              services:
            </p>{" "}
            <h4 className="font-bold">
              Category 1 — Strictly Necessary Cookies
            </h4>{" "}
            <p>
              These cookies are essential to enable you to browse around our
              websites and use their features. Without these cookies, services
              like shopping baskets and e-billing cannot be provided.
            </p>{" "}
            <h4 className="font-bold">Category 2 — Performance Cookies</h4>{" "}
            <p>
              These cookies collect information about how you use our websites —
              for instance, which pages you go to most. This data may be used to
              help optimize our websites and make them easier for you to
              navigate. These cookies are also used to let affiliates know if
              you came to one of our websites from an affiliate and if your
              visit resulted in the use or purchase of a product or service from
              us, including details of the product or service purchased. These
              cookies don’t collect information that identifies you. All
              information these cookies collect is aggregated and therefore
              anonymous.
            </p>{" "}
            <h4 className="font-bold">Category 3 — Functionality Cookies</h4>{" "}
            <p>
              These cookies allow our websites to remember choices you make
              while browsing. For instance, we may store your geographic
              location in a cookie to ensure that we show you our website
              localized for your area. We may also remember preferences such as
              text size, fonts, and other customizable site elements. They may
              also be used to keep track of what featured products or videos
              have been viewed to avoid repetition. The information these
              cookies collect will not personally identify you, and they cannot
              track your browsing activity on non-Kingdom websites.
            </p>{" "}
            <p>
              We do not use any cookies, pixels, or other tracking that
              discloses to any third party information that identifies a person
              as having viewed specific video materials.&nbsp;
            </p>{" "}
            <h3 className="text-lg font-bold">
              How Long Do Cookies Stay on My Device?
            </h3>{" "}
            <p>
              Some Cookies operate from the time you visit the Services until
              the end of that particular browsing session. These Cookies, which
              are called “Session Cookies,” expire and are automatically deleted
              when you close your Internet browser.
            </p>{" "}
            <p>
              Some Cookies will stay on your device between browsing sessions
              and will not expire or automatically delete when you close your
              Internet browser. These Cookies are called “Persistent Cookies”
              and the length of time they will remain on your device will vary
              from Cookie to Cookie. Persistent Cookies are used for a number of
              purposes, such as storing your preferences so that they are
              available for your next visit and to keep a more accurate account
              of how often you visit the Services, how your use of the Services
              may change over time, and the effectiveness of advertising
              efforts.
            </p>{" "}
            <h3 className="text-lg font-bold">Managing Your Cookies</h3>{" "}
            <p>
              It may be possible to block Cookies by changing your Internet
              browser settings to refuse all or some Cookies. If you choose to
              block all Cookies (including Essential Cookies), you may not be
              able to access all or parts of the Services.
            </p>{" "}
            <p>
              You can find out more about Cookies and how to manage them by
              visiting{" "}
              <a href="http://www.aboutcookies.org">www.AboutCookies.org</a>.
            </p>{" "}
            <h4 className="font-bold">
              Does the Site Respond to “Do Not Track” Signals?
            </h4>{" "}
            <p>
              At this time, our Site does not respond differently based on a
              user’s Do Not Track signal.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#how-we-use-the-personal-information"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="how-we-use-the-personal-information"
              >
                How We Use the Personal Information
              </h2>
            </a>{" "}
            <p>
              We use personal information we collect about you or that you
              provide to us in the following ways:
            </p>{" "}
            <ul className="list-disc list-outside ml-6">
              <li>
                To present our Services and its contents in a suitable and
                effective manner for you and your device;
              </li>{" "}
              <li>To fulfill orders;</li>{" "}
              <li>
                To contact you to provide you with information or services that
                you request from us;
              </li>{" "}
              <li>To build profiles of the users of our Services;</li>{" "}
              <li>
                To advertise opportunities, services, or special events that we
                think may be of interest to you;
              </li>{" "}
              <li>
                To provide customer support, troubleshoot issues, manage
                accounts, and respond to requests, questions, or comments;
              </li>{" "}
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts; and
              </li>{" "}
              <li>
                To notify you about any additions, upgrades, or changes in our
                services.
              </li>
            </ul>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#how-we-share-personal-information"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="how-we-share-personal-information"
              >
                How We Share Personal Information
              </h2>
            </a>{" "}
            <h3 className="text-lg font-bold">
              Access and use by third-party service providers:&nbsp;
            </h3>{" "}
            <p>
              From time to time, Kingdom enters into arrangements with companies
              whose expertise is essential for our own services to function
              properly. We disclose to these service providers only such
              personal information as is reasonably necessary to perform these
              functions, and any such third parties will be under an obligation
              to us to maintain the confidentiality of your personal
              information.&nbsp;
            </p>{" "}
            <h3 className="text-lg font-bold">
              Access and use by subsidiaries or affiliated companies:&nbsp;
            </h3>{" "}
            <p>
              We may provide such information to our subsidiaries, affiliated
              companies or other trusted businesses or persons, solely for the
              purpose of processing that information on our behalf.
            </p>{" "}
            <h4 className="font-bold">For legal purposes:&nbsp;</h4>{" "}
            <p>
              We reserve the right to cooperate with local, provincial, state,
              federal and international officials in any investigation requiring
              either personal information or reports about lawful or unlawful
              user activity on this site. We also reserve the right to share
              your personal information to establish, exercise, or defend our
              legal and property rights, including providing information to
              others for the purposes of fraud prevention. We may also share
              your personal information with any person who we reasonably
              believe may apply to a court or other competent authority for
              disclosure of that personal information where, in our reasonable
              opinion, such court or authority would be reasonably likely to
              order disclosure of that personal information.
            </p>{" "}
            <p>
              If we are ordered by a legal process or government to provide data
              about one or more individual users, we will notify those users of
              our compliance with that order, to the extent permitted by law.
            </p>{" "}
            <h4 className="font-bold">
              Business transactions or mergers:&nbsp;
            </h4>{" "}
            <p>
              We reserve the right to share your personal information to third
              parties as part of any potential business or asset sale, merger,
              acquisition, investment, round of funding, or similar type of
              transaction.
            </p>{" "}
            <h4 className="font-bold">Bankruptcy or insolvency:</h4>{" "}
            <p>
              In the event of bankruptcy, insolvency, or dissolution
              proceedings, we may share your personal information with third
              parties as part of the sale or reorganization process.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#advertising-and-marketing-choice"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="advertising-and-marketing-choice"
              >
                Advertising and Marketing Choice
              </h2>
            </a>{" "}
            <p>
              We respect your rights in how your personal information is used
              and shared. If at any time you would like to unsubscribe from
              receiving future emails, you can email us at{" "}
              <span className="text-blue-600 font-medium select-all">
                team@pawtocol.com
              </span>{" "}
              or follow the instructions at the bottom of each email, and we
              will promptly remove you from marketing correspondence. Please
              note, however, that we may still need to contact you regarding
              other matters.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#how-long-is-your-personal-information-kept"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="how-long-is-your-personal-information-kept"
              >
                How Long Is Your Personal Information Kept?
              </h2>
            </a>{" "}
            <p>
              We will retain your personal information until the personal
              information is no longer necessary to accomplish the purpose for
              which it was provided. We may retain your personal information for
              longer periods for specific purposes to the extent that we are
              obliged to do so in accordance with applicable laws and
              regulations, to protect you, other people, and us from fraud,
              abuse, and unauthorized access, as necessary to protect our legal
              rights, or for certain business requirements.
            </p>{" "}
            <p>
              We will delete your personal information when it is no longer
              necessary for the purpose for which it was collected, or upon your
              request, subject to exceptions as discussed in this Statement or
              under applicable law, contract, or regulation.
            </p>{" "}
            <p>
              If you use our mobile application, you can delete your account by
              submitting a request to{" "}
              <span className="text-blue-600 font-medium select-all">
                team@pawtocol.com
              </span>{" "}
              or by deleting your account through the application itself.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#our-commitment-to-data-security"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="our-commitment-to-data-security"
              >
                Our Commitment to Data Security
              </h2>
            </a>{" "}
            <p>
              The security of your personal information is important to us. We
              take various reasonable organizational, administrative, and
              technical measures to protect your personal information from
              unauthorized access, disclosure, alteration, or destruction. If
              required by law to do so, we will notify you and/or the relevant
              supervisory authority in the event of a data breach.
            </p>{" "}
            <p>
              However, we cannot and do not guarantee complete security, as it
              does not exist on the Internet.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#third-party-links"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="third-party-links"
              >
                Third Party Links
              </h2>
            </a>{" "}
            <p>
              Kingdom’s Services may contain links to third-party websites. When
              we provide links, we do so only as a convenience, and we are not
              responsible for any content of any third-party website or any
              links contained within. It is important to note that this
              Statement only applies to Kingdom’s Services. We are not
              responsible and assume no responsibility for any personal
              information collected, stored, or used by any third party as a
              result of you visiting third-party websites. We also advise that
              you read the privacy notice of any third-party websites you choose
              to visit carefully.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#childrens-privacy"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="childrens-privacy"
              >
                Children’s Privacy
              </h2>
            </a>{" "}
            <p>
              Protecting the privacy of the very young is especially important.
              Our Services are not intended for children under 13 years of age,
              and we do not knowingly collect personal information from children
              under 13. In the event that we learn that we have collected
              personal information from a child under age 13 without
              verification or parental consent, we will immediately delete that
              information. If you believe that we might have any information
              from or about a child under 13, please contact us using the
              information provided in the “<strong>Contact Us</strong>” section
              below.
            </p>{" "}
            <a
              className="no-underline text-gray-800 block"
              href="#policy-changes"
            >
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="policy-changes"
              >
                Policy Changes
              </h2>
            </a>{" "}
            <p>
              This Statement may change from time to time. We will post any
              changes to this Statement on this page, and you should check these
              terms when you use the Site. Your continued use of the Services
              constitutes acceptance of the most current version of this
              Statement
            </p>{" "}
            <a className="no-underline text-gray-800 block" href="#contact-us">
              <h2
                className="font-title font-semibold text-xl md:text-2xl"
                id="contact-us"
              >
                Contact Us
              </h2>
            </a>{" "}
            <p>
              If you have any questions about this Privacy Policy, please
              contact us by email at{" "}
              <span className="text-blue-600 font-medium select-all">
                team@pawtocol.com
              </span>{" "}
              or regular mail at:
            </p>{" "}
            <address>
              2875 NE 91st St. Ste 500 Aventura, FL 33180 United States
            </address>
          </div>
        </div>
      </div>
      </>
    </FullLayout>
  );
};

export default Privacy;
