import React, { useState } from "react";
import LeftArrowIcon from "../../components/elements/Icon/LeftArrowIcon";
import Button from "../../components/elements/Button";
import { editProfileItems } from "../../constants/profile";
import Input from "../../components/elements/Input";
import Textarea from "../../components/elements/Textarea";
import Modal from "../../components/elements/Modal";
import SocialInput from "../../components/elements/SocialInput";
import { FaTwitter, FaInstagram } from "react-icons/fa";
import TiktokIcon from "../../components/elements/Icon/TiktokIcon";
import FullLayout from "../../components/layout/FullLayout";
import { useEffect } from "react";
import httpService from "services/http.service";
import "./styles.scss";
import { Link } from 'react-router-dom';
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import useNotification from "hooks/useNotification";
import { Helmet } from "react-helmet-async";
import { isInstagramLinked, isTiktokLinked, isTwitterLinked } from "common/utils";

type IEditInput = {
  title: string;
  value?: string;
  placeholder?: string;
  hint?: string;
  name: string;
  sluggish?: boolean;
};

const EditProfile = () => {
  const [formState, setFormState] = useState<{ [key: string]: string }>({
    display: "",
    first: "",
    last: "",
    email: "",
    bio: "",
    twitter: "",
    tiktok: "",
    instagram: "",
  });
  const [isSuccessModalOpened, setIsSuccessModalOpened] = useState(false);
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const notify = useNotification();

  useEffect(() => {
    const getProfileData = async () => {
      setIsDataProcessing(true);
      const responseData: any = (await httpService.get(`/user/user-info`)).data;
      console.log(responseData);
      setFormState({
        display: responseData.display_name || "",
        first: responseData.first_name || "",
        last: responseData.last_name || "",
        email: responseData.email || "",
        bio: responseData.description || "",
        twitter: responseData.twitter_link || "",
        tiktok: responseData.tiktok_link || "",
        instagram: responseData.instagram_link || "",
      });
      setIsDataProcessing(false);
    };
    getProfileData();
  }, []);

  const handleSuccessModal = async () => {
    setIsSuccessModalOpened(!isSuccessModalOpened);
  };

  const save = async () => {

    if (formState.twitter && !isTwitterLinked(formState.twitter)) {
      notify('Invalid twitter url', "error");
      return;
    }

    if (formState.tiktok && !isTiktokLinked(formState.tiktok)) {
      notify('Invalid tiktok url', "error");
      return;
    }

    if (formState.instagram && !isInstagramLinked(formState.instagram)) {
      notify('Invalid instagram url', "error");
      return;
    }
    setIsDataProcessing(true);


    const responseData = await httpService.put(`/user/edit-user`, {
      displayName: formState.display,
      firstName: formState.first,
      lastName: formState.last,
      description: formState.bio,
      email: formState.email,
      twitterLink: formState.twitter,
      tiktokLink: formState.tiktok,
      instagramLink: formState.instagram,
    }).then((res) => {
      return res.data;

    })
    .catch(function (error) {
      if (error.response) {
        notify(error.response?.data?.message ? error.response?.data?.message : error.response?.data?.error, "error");
      } else if (error.request) {
        notify("request error", "error");
      } else {
        notify(error.message, "error");
      }
    });
    setIsDataProcessing(false);
    if (responseData) {
      handleSuccessModal();
    }
  };

  const onInputChange = (e: any) => {
    setFormState({
      ...formState,
      [e.name]: e.value,
    });
  };

  const onSluggishInputChange = (e: any) => {
    setFormState({
      ...formState,
      [e.name]: slugify(e.value),
    });
  };

  const slugify = (text: string) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/^-+/, ""); // Trim - from start of text
    // .replace(/-+$/, ""); // Trim - from end of text
  };

  return (
    <FullLayout>
      <>
        <Helmet>
          <meta name="robots" content="noindex"/>
          <title>Edit Account | KINGDOM</title>
          <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
        </Helmet>
        {isSuccessModalOpened && (
          <Modal handleModal={handleSuccessModal} disableOverlay={false}>
            <div>
              <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <svg
                  className="h-6 w-6 text-green-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <h3 className="leading-6 text-center mt-3 sm:mt-5 modal-text">
                Information saved
              </h3>
            </div>
            <div className="mt-3 sm:mt-4 flex justify-center">
              <Button
                label="Great"
                classNames="btn-long colored rounded !w-44"
                onClick={handleSuccessModal}
              />
            </div>
          </Modal>
        )}
        {
          isDataProcessing && (
            <div className="h-screen w-screen fixed inset-0 flex items-center justify-center z-40 bg-opacity-75 bg-gray-500 !mt-0">
              <SpinnerIcon className="my-auto !text-white" />
            </div>
          )
        }
        <div className="container mx-auto mt-6 mb-20">
          <div className="max-w-2xl mx-auto">
            <Link to="/users" className="flex items-center back -ml-1">
              <LeftArrowIcon />
              Back
            </Link>
            <h1 className="page-title">Edit your profile</h1>
            <div className="mt-6 space-y-6">
              {editProfileItems.map((item: IEditInput) =>
                item.sluggish ? (
                  <Input
                    key={item.title}
                    name={item.name}
                    placeholder={item.placeholder}
                    value={slugify(formState[item.name])}
                    onChange={(e) => onSluggishInputChange(e.target)}
                    title={item.title}
                    hint={item.hint}
                    coloredHint
                  />
                ) : (
                  <Input
                    key={item.title}
                    name={item.name}
                    placeholder={item.placeholder}
                    value={formState[item.name]}
                    onChange={(e) => onInputChange(e.target)}
                    title={item.title}
                    hint={item.hint}
                    coloredHint
                  />
                )
              )}
              <Textarea
                title="Bio"
                name="bio"
                placeholder="Enter your public bio here"
                value={formState.bio}
                onChange={(e) => onInputChange(e.target)}
              />
            </div>
            <p className="font-medium text-sm text-primary-700 mt-4">
              Add your socials to build trust on the marketplace
            </p>
            <div className="bg-light-bg rounded-xl mt-2 space-y-6 p-3">
              <SocialInput
                title="Twitter"
                name="twitter"
                placeholder="https://twitter.com/abcd"
                value={formState.twitter}
                onChange={(e) => onInputChange(e.target)}
                icon={<FaTwitter />}
              />
              <SocialInput
                title="Tiktok"
                name="tiktok"
                placeholder="https://tiktok.com/@abcd"
                value={formState.tiktok}
                onChange={(e) => onInputChange(e.target)}
                icon={<TiktokIcon />}
              />
              <SocialInput
                title="Instagram"
                name="instagram"
                placeholder="https://instagram.com/abcd"
                value={formState.instagram}
                onChange={(e) => onInputChange(e.target)}
                icon={<FaInstagram />}
              />
            </div>
            <div className="flex justify-end w-full mt-12">
              <Button
                label="save"
                classNames="rounded-full btn-long auto colored"
                onClick={save}
              />
            </div>
          </div>
        </div>
      </>
    </FullLayout>
  );
};

export default EditProfile;
