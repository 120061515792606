import React from "react";

const SuccessAnimationIcon = () => {
  return (
    <div className="mx-auto mt-8 mb-12">
      <svg className="checkmark success-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle className="checkmark__circle success-circle" cx="26" cy="26" r="25" fill="none"/>
        <path className="success-path" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
      </svg>
    </div>
  );
};

export default SuccessAnimationIcon;
