import React from 'react';
const data = [
    {
        id: 1,
        name: 'Phase 1',
        quarter: 'Q3 2022',
        items: [
            "Develop decentralized architecture based on Ethereum & IPFS protocol",
            "Smart contracts for minting Kingdom Pets blockchain content",
            "Lazy minting for Kingdom Pets blockchain content",
            "Kingdom Editor for designing blockchain content in browser",
            "Capability to create Pet Profiles",
            "Explore & like Kingdom Pet content and profiles",
            "Release Discord community",
            "Release Kingdom Paw Park in Decentraland",
            "Quality Assurance testing & smart contract auditing",
        ]
    },
    {
        id: 2,
        name: 'Phase 2',
        quarter: 'Q4 2022 - Q3 2023',
        items: [
            "Incorporate user feedback from Phase 1",
            "Release in-app rewards",
            "Release leaderboards and rankings",
            "Release Kingdom Mobile App for exploring pet content on a mobile device",
            "Implement social feed for pet content",
            "Implement moderation features",
            "Optimize Explore functionality for searching pet content",
            "Additional mechanisms to earn rewards",
            "Quality Assurance testing & smart contract auditing"
        ]
    },
    {
        id: 3,
        name: 'Phase 3',
        quarter: 'Q4 2023',
        items: [
            'Incorporate user feedback from Phase 2',
            "Enhance mobile app experience for location-based features",
            "Release Marketplace features",
            "Strategic gaming & crypto partnerships",
            "Additional mechanisms to earn rewards",
            "Quality Assurance testing & smart contract auditing",
            "More coming soon!",
        ]
    }
]

const index = () => {
    return (
        <div className="growing-section py-15">
            <div className="container mx-auto">
                <div className="pl-4">
                    <h2 className="section-title">Growing Kingdom</h2>
                    <div className="mt-10 text-center flex justify-center">
                        <a href="https://pawtocol.gitbook.io/kingdom-whitepaper" target="_blank" rel="noreferrer" className="h-14 sm:h-12 flex items-center justify-center rounded tracking-wide btn-long colored auto px-6">
                            Read our whitepaper
                        </a>
                    </div>
                </div>
                <div className="mt-2">
                    <ol className="md:flex items-start px-4 md:px-0 mt-12">
                        {
                            data.map(item => (
                                <li className="relative mb-6 sm:mb-0 md:w-1/3">
                            <div className="flex ">
                                <div class="flex z-10 justify-center items-center w-8 h-8 rounded-full ring-0 ring-white sm:ring-8 shrink-0">
                                    <svg class="w-12 h-12 box-border" viewBox="0 0 64.7 64.7">
                                        <circle cx="32.4" cy="32.4" r="32.4" fill="#667eea"></circle>
                                        <path d="m45.2 34.8-5.7-6.6a1.2 1.2 0 0 0-1-.4H26.2a1.3 1.3 0 0 0-1 .4l-5.7 6.6a1.6 1.6 0 0 0-.3.9 1.1 1.1 0 0 0 .3.8L31.4 50a1.3 1.3 0 0 0 1 .4 1.1 1.1 0 0 0 .9-.4l11.9-13.5a1.1 1.1 0 0 0 .3-.8 1.6 1.6 0 0 0-.3-.9M26.5 14.3h-.3a3.2 3.2 0 0 0-2.4 1.4 5.2 5.2 0 0 0-.9 3.1v.8c.3 2.6 2.3 4.6 4.3 4.6h.3a3.3 3.3 0 0 0 2.3-1.4 4.7 4.7 0 0 0 1-3.2V19c-.4-2.7-2.3-4.7-4.3-4.7m-5.3 10.2c-.6-2.4-2.6-4.2-4.5-4.1h-.6c-1.7.3-2.8 2-2.8 4.1a7.7 7.7 0 0 0 .2 1.5c.6 2.4 2.6 4.2 4.5 4.1h.6c1.7-.3 2.8-2 2.8-4.1a8.9 8.9 0 0 0-.2-1.5ZM34 19a1.7 1.7 0 0 0-.1.7 5.3 5.3 0 0 0 1 3.2 3.3 3.3 0 0 0 2.4 1.4h.3c2 0 3.9-2 4.2-4.6 0-.3.1-.5.1-.8a5.2 5.2 0 0 0-1-3.1 3 3 0 0 0-2.4-1.4h-.3c-2 0-3.9 2-4.2 4.7Zm9.3 7c0 2.1 1.2 3.8 2.8 4.1h.6c1.9.1 3.9-1.7 4.5-4.1a3.9 3.9 0 0 0 .2-1.5c0-2.1-1.1-3.8-2.7-4.1H48c-1.8-.1-3.8 1.7-4.5 4.1a8.9 8.9 0 0 0-.2 1.5" fill="#fff"></path>
                                    </svg>
                                </div>
                                <div className="hidden sm:flex w-full mt-3 bg-gray-200 h-0.5">
                                </div>
                            </div>
                            <div className="mt-3 sm:pr-8">
                                <h3 className="text-lg font-semibold text-gray-900">{item.name}</h3>
                                <time class="block mb-2 text-sm font-normal leading-none text-gray-400">{item.quarter}</time>
                                <ul className="text-base font-medium text-gray-800">
                                    {
                                        item.items.map(innerItem => (
                                            <li className="rounded-buttons mb-1 py-1 px-2 bg-light-bg">{innerItem}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </li>
                            ))
                        }
                        
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default index;