import React from "react";
import StepButton from "./StepButton";
import CheckIcon from "../../../elements/Icon/CheckIcon";
import './style.scss';

interface IStepsProps {
  step: number;
  onClick?: () => void;
}

const Steps = ({
  step,
  onClick,
}: IStepsProps) => {
  return (
    <div className="flex items-center space-x-4 step-container">
      <StepButton step={step} onClick={onClick} />
      {
        new Array(4).fill(1).map((item, index) => (
          index + 1 < step ? <>
            <div key={index} className="flex items-center text-white font-semibold h-5 w-5 justify-center rounded-full step-checkbox completed">
              <CheckIcon />
            </div>
          </> : <>
            <div
              key={index}
              className={`flex items-center bg-gray-300 border text-white font-semibold h-5 w-5 justify-center rounded-full step-checkbox ${index + 1 === step ? '' : ''}`}
            />
          </>
        ))
      }
    </div>
  );
};

export default Steps;
