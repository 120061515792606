import React, { useEffect } from "react";
import Button from "../../../elements/Button";
import { profilesItems } from "../../../../constants/dashboard";
import ProfileCard from "./ProfileCard";
import httpService from "services/http.service";
import { useState } from "react";
import history from "common/history";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import './style.scss';

const TopProfilesSection = () => {
  const [profileData, setProfileData] = useState<any>([]);
  const [isFetchingProfiles, setIsFetchingProfiles] = useState(false);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(12);

  useEffect(() => {
    const getTopProfiles = async () => {
      setIsFetchingProfiles(true);
      const responseData: any = (
        await httpService.get(`profile/top-profiles-in-days?take=${limit}&skip=${skip}&orderBy=most_popular&days=365`)
      ).data;
      setProfileData((prev: any) => {
        const initialResponse = responseData.data.map((element) => {
          return {
            no: element.profile_id,
            title: element.name,
            floorPrice: 0,
            likeCount: element.likes_count,
            data: {
              value: "--",
              color: "text-grey-600",
            },
            price: 0,
            link: element.url,
            image_url: element.image_url
          };
        })

        if(initialResponse.length === limit){
          return initialResponse;
        } else {
           let  tempResponse = [...Array(limit - initialResponse.length).fill(0)].map(item => {
            return {
              no: 0,
              title: "Coming Soon",
              floorPrice: 0,
              likeCount: 0,
              data: {
                value: "--",
                color: "text-grey-600",
              },
              price: 0,
              link: 'N/A',
              image_url: "/assets/images/avatar.png",
            }
           })

           return [...initialResponse, ...tempResponse]
        }

        // let finalData = []
        // for (let i = 0; i<12; i++){
        //   if(responseData.length >= i){
        //     finalData = [...finalData, {
        //       count: i+1,
        //       no: -1,
        //       title: "Coming Soon",
        //       floorPrice: "",
        //       data: {
        //         value: "--",
        //         color: "text-grey-600",
        //       },
        //       price: 0,
        //       link: "",
        //     }]
        //   } else {
        //     const element: any = responseData[i]
        //     debugger;
        //     finalData = [...finalData, {
        //       count: i,
        //       no: element.profile_id,
        //       title: element.name,
        //       floorPrice: 0,
        //       data: {
        //         value: "--",
        //         color: "text-grey-600",
        //       },
        //       price: 0,
        //       link: element.url,
        //     }]
        //   }
        // }

        // return finalData;
      }
        
      );
      setIsFetchingProfiles(false);
    };

    getTopProfiles();
  }, []);

  console.log("profileData", profileData)

  return (
    <div className="mt-20 top-profile-section py-15">
      <div className="container mx-auto">
      <div className="pl-4">
          <h2 className="section-title">Top Kingdom Pet Profiles</h2> 
          <p className="section-subtitle">last 30 days</p>
      </div>
      
        {
          isFetchingProfiles ? (
                <div className="h-4 w-full py-28 flex items-center justify-center">
                  <SpinnerIcon className="my-auto" />
                </div>
          ) : <div className="grid grid-cols-1 md:grid-cols-4 md:grid-rows-3 md:grid-flow-rows mt-12">
              {profileData.map((item: any, index: number) => (
                <ProfileCard key={`${item.no}-${index}`} item={item} index={index} />
              ))}
            </div>
        }
      
      {/* <div className="mt-10 text-center flex justify-center">
                <button className="h-14 sm:h-12 flex items-center justify-center rounded tracking-wide btn-long colored auto px-6"
                 onClick={() => {
                  history.push('/explore')
                }}
                >Browse pet profiles</button>
            </div> */}
      {/* <div className="mt-10 text-center flex justify-center">
        <Button label="Browse Rankings" classNames="rounded-full font-title" />
      </div> */}
      </div>
      
    </div>
  );
};

export default TopProfilesSection;
