import React from "react";

const CreateStepSection = () => {
  return (
    <div className="my-20 lg:my-32 container mx-auto dashboard-create-step-section">
      <h2 className="text-center title">
        <span className="normal">Transform your pet.</span> Create your
        Kingdom.
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 mt-2 sm:mt-12 gap-4 item-list">
        <div className="text-center flex flex-col items-center px-2 relative mt-12 py-6 md:mt-0 bg-light-bg rounded-cards">
          <img
            className="w-full max-w-[200px] mb-0"
            src="/assets/images/wallet.gif"
            alt=""
          />
          <h3 className="item-title">
            Connect your wallet
          </h3>
          <p className="mt-4 max-w-xs px-6 item-subtitle">
            Set up your crypto wallet of choice &amp; connect it to Kingdom to
            start creating blockchain pet content
          </p>
        </div>
        <div className="text-center flex flex-col items-center px-2 relative mt-12 py-6 md:mt-0 bg-light-bg rounded-cards">
          <img className="w-full max-w-[200px] mb-0" src="/assets/images/profile.gif" alt="" />
          <h3 className="item-title">
            Create your pet profiles
          </h3>
          <p className="mt-4 max-w-xs px-6 item-subtitle">
            Create a profile for your pet &amp; use the Kingdom Pet Editor to
            design custom blockchain pet content or upload your own content
          </p>
        </div>
        <div className="text-center flex flex-col items-center px-2 relative mt-12 py-6 md:mt-0 bg-light-bg rounded-cards">
          <img className="w-full max-w-[200px] mb-0" src="/assets/images/mint.gif" alt="" />
          <h3 className="item-title">
            Mint and earn rewards
          </h3>
          <p className="mt-4 max-w-xs px-6 item-subtitle">
            Mint your blockchain pet content to unlock monthly competitions.
            Give and receive likes to win rewards.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CreateStepSection;
