import React, { useEffect, useState } from "react";
import PlusIcon from "../../../elements/Icon/PlusIcon";
import Button from "../../../elements/Button";
import httpService from "services/http.service";
import { Link } from 'react-router-dom';
import Pagination from "../../../elements/Pagination/Pagination";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import { useSelector } from "react-redux";
import { RootState } from "store";
import history from "common/history";
export type IPetType = {
  petProfileId: number;
  petName: string;
  petHandle: string;
  petIg: string;
  url: string;
  avatar: string;
  petType: string;
  petBreed: string;
  type;
  breed1;
  breed2;
};

interface IStep1Props {
  onChangeStep: () => void;
  profileInfo: IPetType | null;
  handleProfile: (value: IPetType) => void;
}

const Step1 = ({ onChangeStep, profileInfo, handleProfile }: IStep1Props) => {
  const [pets, setPets] = useState<IPetType[]>([]);
  // const [selected, setSelected] = useState<IPetType>(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const [isFetchingPets, setIsFetchingPets] = useState(false);
  const userAddress = useSelector((state: RootState) => state.wallet.address);

  const getMyProfiles = async () => {
    setIsFetchingPets(true);
    const responseData = (await httpService.get(`profile/my-profiles?take=10&skip=0&orderBy=newest`)).data;
    setTotalCount(responseData?.count);
    setPets(
      responseData?.data.map((profile) => {
        return {
          petName: profile.name,
          petHandle: "",
          petIg: "",
          avatar: profile.image_url,
          petType: profile.type_id,
          petBreed: profile.breed_id,
          petProfileId: profile.profile_id,
          url: profile.url,
          type: profile.types,
          breed1: profile.breeds_breedsToprofiles_breed_id,
          breed2: profile.breeds_breedsToprofiles_secondary_breed_id
        };
      })
    );
    setIsFetchingPets(false);
  };
  useEffect(() => {
    getMyProfiles();
  }, [pageNumber]);

  const handlePageClick = (event) => {
    console.log('handlePageClick', event);
    setPageNumber(event.selected);
  };
  const onSelect = (_profile) => {
    // setSelected(_profile);
    handleProfile(_profile);
  };

  return (
    <>
      <div className="relative min-h-[500px] pb-20 space-y-6 step-1 pt-5">
        <p className="info">Choose a pet profile or create a new one.</p>
        {
          isFetchingPets && (
            <div className="h-screen w-screen fixed inset-0 flex items-center justify-center z-40 bg-opacity-75 bg-gray-500 !mt-0">
              <SpinnerIcon className="my-auto !text-white" />
            </div>
          )
        }
        <div className="flex flex-wrap gap-3 h-32">
        <button
            onClick={() => {
              if (userAddress) {
                history.push("/create-profile");
              } else {
                document.getElementById("connectButton").click();
              }

            }}
            className="pet-profile-item rounded-xl flex flex-col items-center justify-center w-[116px] h-[128px]"
          >
            <PlusIcon />
            Create new
          </button>
          {pets.map((pet, index) => (
            <button
              key={pet.petProfileId}
              className={`pet-profile-item rounded-xl flex flex-col relative items-center w-[116px] h-[128px] overflow-hidden ${
                profileInfo?.petProfileId === pet?.petProfileId
                  ? "selected"
                  : ""
              }`}
              onClick={() => onSelect(pet)}
            >
              <img
                src={pet.avatar}
                alt={pet.avatar}
                className="object-cover h-[80%] w-full absolute inset-0"
              />
              <p className="relative z-[2] mt-auto text-center w-full overflow-hidden text-ellipsis whitespace-nowrap px-1">
                {pet.petName}
              </p>
            </button>
          ))}
          
        </div>
      </div>
      <div className="fixed bottom-0 bg-white rounded-t-xl shadow-md p-3 border border-gray-200 w-full flex justify-end max-w-4xl mx-auto left-1/2 -translate-x-1/2 z-30">
      {profileInfo !== null ? (
          <Button
            classNames="rounded btn-long colored auto w-24"
            label="next"
            onClick={onChangeStep}
          />
        ) : (
          <Button
            classNames="rounded btn-long white-colored auto py-2 w-24 !text-white"
            bgColor="gray"
            label="next"
          />
        )}
      </div>
      <Pagination
            nextLabel=">"
            prevLabel="<"
            total={totalCount}
            handlePageClick={handlePageClick}
            itemPerPage={itemPerPage}
            pageSelected={pageNumber}
          />
    </>
  );
};

export default Step1;
