import FullLayout from "components/layout/FullLayout";
import React from "react";
import "./style.scss";

const pageContent = {
  pageTitle: "Community Guidelines",
  content: [
    {
      id: 1,
      title: "",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 2,
      title: "Heading One",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, laborum. Doloribus reiciendis, ullam ipsum commodi tenetur illo eos voluptates aperiam voluptas cupiditate perspiciatis voluptate cum aspernatur enim dolor exercitationem nisi molestiae a odit, soluta similique voluptatibus dicta necessitatibus quia? Molestiae tenetur dicta ex, laboriosam quod perspiciatis magnam, vero rem nobis vel nihil animi ut sunt excepturi non explicabo voluptatum illo! Voluptates consequuntur at ullam ab libero explicabo quod temporibus eum? Quisquam dolor, aspernatur expedita ullam cumque molestiae necessitatibus inventore. Accusamus eveniet cum, recusandae totam tempora possimus quo dolorum. sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 3,
      title: "Heading Two",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor Lorem ipsum dolor sit amet consectetur adipisicing elit. Deserunt, laborum. Doloribus reiciendis, ullam ipsum commodi tenetur illo eos voluptates aperiam voluptas cupiditate perspiciatis voluptate cum aspernatur enim dolor exercitationem nisi molestiae a odit, soluta similique voluptatibus dicta necessitatibus quia? Molestiae tenetur dicta ex, laboriosam quod perspiciatis magnam, vero rem nobis vel nihil animi ut sunt excepturi non explicabo voluptatum illo! Voluptates consequuntur at ullam ab libero explicabo quod temporibus eum? Quisquam dolor, aspernatur expedita ullam cumque molestiae necessitatibus inventore. Accusamus eveniet cum, recusandae totam tempora possimus quo dolorum. sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 4,
      title: "The Third Heading",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 4,
      title: "Another Heading",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
    {
      id: 5,
      title: "A very long heading nobody is ready for because law",
      para: [
        {
          id: 1,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
        {
          id: 2,
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores velit accusantium nihil reprehenderit, ipsum deserunt officia fugiat veniam ut, minima explicabo quaerat. Iusto nemo necessitatibus optio! Placeat facere sint perspiciatis nisi consectetur sed adipisci earum libero dolorum labore fugit laudantium, ea provident ullam fuga dignissimos nemo veritatis magnam? Odio, beatae? Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio quis incidunt perspiciatis amet sunt voluptates ab a quas possimus voluptatum.",
        },
      ],
    },
  ],
};

const CommunityGuidelines = () => {
  return (
    <FullLayout>
      <div className="static-page">
        <div className="mt-20 mb-20 px-4 max-w-4xl mx-auto space-y-3">
          <h1 className="page-title mb-12">{pageContent.pageTitle}</h1>
          {pageContent.content.map((item) => (
            <div key={item.id}>
              {item?.title && <h3 className="title">{item.title}</h3>}
              {item?.para?.length > 0 &&
                item.para.map((singlePara) => (
                  <p className="subtitle" key={`${item.id}-${singlePara.id}`}>
                    {singlePara.text}
                  </p>
                ))}
            </div>
          ))}
        </div>
      </div>
    </FullLayout>
  );
};

export default CommunityGuidelines;
