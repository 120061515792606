import React, { useEffect, useState } from "react";
import ProfileStep1 from "../../components/modules/CreateProfile/ProfileStep1";
import ProfileStep2 from "../../components/modules/CreateProfile/ProfileStep2";
import ProfileStepSuccess from "../../components/modules/CreateProfile/ProfileStepSuccess";
import FullLayout from "../../components/layout/FullLayout";
import httpService from "services/http.service";
// import ipfsService from "services/ipfs.service";
import imageService from "services/image.service";
import useNotification from "hooks/useNotification";
import { Helmet } from "react-helmet-async";

const CreateProfile = () => {
  const [step, setStep] = useState(1);
  const [petName, setPetName] = useState("");
  const [petHandle, setPetHandle] = useState("");
  const [petIg, setPetIg] = useState("https://instagram.com");
  const [avatar, setAvatar] = useState<any>("/assets/images/1.png");
  const [petType, setPetType] = useState<string>("");
  const [petBreed, setPetBreed] = useState<string>("");
  const [petSecondaryBreed, setPetSecondaryBreed] = useState<string>("");
  const [petData, setPetData] = useState({
    petName: petName,
    petHandle: petHandle,
    petIg: petIg,
    avatar: avatar,
    petType: petType,
    petBreed: petBreed,
    petSecondaryBreed: petSecondaryBreed,
  });
  const [isCreateInProgress, setIsCreateInProgress] = useState(false);
  const notify = useNotification();

  useEffect(() => {
    setPetData({
      ...petData,
      petName: petName,
      petHandle: petHandle,
      petIg: petIg,
      avatar: avatar,
      petType: petType,
      petBreed: petBreed,
      petSecondaryBreed: petSecondaryBreed,
    });
  }, [petName, petHandle, petIg, avatar, petType, petBreed, petSecondaryBreed]);

  // useEffect(() => {
  //   localStorage.setItem("pets", JSON.stringify([]));
  // }, []);

  const onFrontStep = () => {
    setStep(step + 1);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <ProfileStep1
            onFrontStep={onFrontStep}
            petNameInfo={petName}
            handlePetName={(name: string) => setPetName(name)}
            petHandleInfo={petHandle}
            handlePetHandle={(value: string) => setPetHandle(value)}
            petIgInfo={petIg}
            handlePetIg={(value: string) => setPetIg(value)}
            avatarInfo={avatar}
            handleAvatar={(value: any) => {
              setAvatar(value)
            }}
          />
        );
      case 2:
        return (
          <ProfileStep2
            onFrontStep={createPet}
            petTypeInfo={petType}
            handlePetType={(value: string) => setPetType(value)}
            petBreedInfo={petBreed}
            handlePetBreed={(value: string) => setPetBreed(value)}
            petSecondaryBreedInfo={petSecondaryBreed}
            handlePetSecondaryBreed={(value: string) =>
              setPetSecondaryBreed(value)
            }
            isCreateInProgress={isCreateInProgress}
          />
        );
      case 3:
        return <ProfileStepSuccess petName={petName} />;
    }
  };

  const createPet = async () => {
    setIsCreateInProgress(true);
    // const loadedImageUrl = await ipfsService.uploadImageToIpfs(avatar);
    const loadedImageUrl = await imageService.uploadImage(avatar);
    const data = {
      name: petData.petName,
      profileUrl: "/" + petData.petHandle,
      typeId: petData.petType.toString(),
      breedId: petData.petBreed.toString(),
      imageUrl: loadedImageUrl,
      collectionAddress: process.env.REACT_APP_COLLECTION_ONE,
      instagramLink: petData.petIg,
      twitterLink: 'https://twitter.com',
      tiktokLink: 'https://tiktok.com',
    };
    if (petData.petSecondaryBreed != "") {
      data["secondaryBreedId"] = petData.petSecondaryBreed.toString();
    }
    console.log("data ==> ", data);
    const responseData = await httpService.post(`profile/create-profile`, data).then((res) => {
      return res.data;

    })
    .catch(function (error) {
      if (error.response) {
        notify(error.response?.data?.message ? error.response?.data?.message : error.response?.data?.error, "error");
      } else if (error.request) {
        notify("request error", "error");
      } else {
        notify(error.message, "error");
      }
    });
    if (responseData) {
      onFrontStep();
    }
    setIsCreateInProgress(false);
  };

  return (
    <FullLayout headerLocked={true} footerLocked={true}>
      <>
      <Helmet>
        <meta name="robots" content="noindex"/>
        <title>Add a Pet | KINGDOM</title>
        <meta property="og:title" content="Add a Pet" />
        <meta name="description" content="Create a new pet profile on KINGDOM, the next-gen social platform for pets." />
        <meta property="og:description" content="Create a new pet profile on KINGDOM, the next-gen social platform for pets." />
        <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
      <div className="mt-6 mb-20 px-4">
        {step < 3 && (
          <div className="absolute top-1 right-1 m-2 page-corner-stepper">
            <p className="completed">
              {step} <span className="total relative top-1">/ 2</span>
            </p>
          </div>
        )}
        <div className="flex justify-center px-4">
          <div className="w-[300px] mt-20 md:mt-32">{renderContent(step)}</div>
        </div>
      </div>
      </>
    </FullLayout>
  );
};

export default CreateProfile;
