import { getPageDataString } from "common/utils";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import { numPerPageOptions, sortOptions } from "constants/filter";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SelectOrder from "../../../elements/SelectOrder";
import DetailCard from "../DetailCard/DetailCard";
import GridCard from "../Explorecard/GridCard";
import ListCard from "../Explorecard/ListCard";
// import OwlCarousel from 'react-owl-carousel3';
import "./style.scss";

const ExploreItemsContainer = ({
  setDrawerOpen,
  setOrderBy,
  pageNumber,
  setPageNumber,
  itemPerPage,
  setItemPerPage,
  data,
  isExploreDataFetching
}) => {
  const [sortDropdownValue, setSortDropdownValue] = useState(sortOptions[0]);
  const [viewType, setViewType] = useState("grid");
  const [pageNumberOptions, setPageNumberOptions] = useState([1]);
  const [detailedData, setDetailedData] = useState(null);
  const userAddress = useSelector((state) => state.wallet.address);

  useEffect(() => {
    setDetailedData(
      data?.data.map((element) => {
        return {
          title: element.profiles.name,
          profileLink: element.profiles.url,
          pet: element.name,
          price: 0,
          likes: element.item_likes.filter((element) => element.is_liked)
            .length,
          hasLiked:
            element.item_likes.filter(
              (element) =>
                element.users.user_address === userAddress &&
                element.is_liked === true
            ).length > 0,
          image: element.url,
          itemId: element.item_id,
        };
      })
    );

    setPageNumberOptions(pageNumberOptions);
  }, [data, userAddress]);

  const handleDropdownChange = (value) => {
    setOrderBy(value);
    setSortDropdownValue(value);
  };

  // const handleNumberPerPage = (value) => {
  //   setItemPerPage(value);
  //   // setItemPerPageValue(value);
  // };

  // const handlePageNumber = (value) => {
  //   setPageNumber(value - 1);
  //   // setPageNoValue(value);
  // };

console.log("detailedData", detailedData)

  return (
    <div className="items-container">
      <div className="items-container-header flex flex-col md:flex-row justify-start md:justify-between items-start md:items-center mb-8">
        <div className="flex flex-col">
          {/* <p className="mx-0 mt-2 mb-0 items-container-title">Posts</p> */}
          <p className="mb-3 md:mb-0 result-count">
            {getPageDataString(itemPerPage, pageNumber, data?.count).replace('Showing ', '')}
          </p>
        </div>
        <div className="header-inner flex items-center">
          <button
            onClick={() => setDrawerOpen(true)}
            className="border-gray-300 md:hidden hover:border-gray-500 flex items-center justify-center h-12 w-12 rounded mr-4 border"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5.5 5h13a1 1 0 0 1 .5 1.5l-5 5.5l0 7l-4 -3l0 -4l-5 -5.5a1 1 0 0 1 .5 -1.5"></path>
            </svg>
            <em className="hidden">Show filters</em>
          </button>
          <nav className="p-0 my-0 mr-4 ml-0 min-h-0 leading-4 bg-transparent flex rounded relative">
            <button
              onClick={() => setViewType("grid")}
              className={`${
                viewType === "grid" ? "selected" : ""
              } view-option-btn flex items-center justify-center h-12 w-12 rounded rounded-r-none border border-r-0`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="4" y="4" width="6" height="6" rx="1"></rect>
                <rect x="14" y="4" width="6" height="6" rx="1"></rect>
                <rect x="4" y="14" width="6" height="6" rx="1"></rect>
                <rect x="14" y="14" width="6" height="6" rx="1"></rect>
              </svg>{" "}
              <em className="hidden">Grid view</em>
            </button>{" "}
            <button
              onClick={() => setViewType("list")}
              className={`${
                viewType === "list" ? "selected" : ""
              } view-option-btn border-gray-300 hover:border-gray-500 flex items-center justify-center h-12 w-12 border border-l-0 text-center rounded rounded-l-none`}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <rect x="4" y="4" width="16" height="6" rx="2"></rect>
                <rect x="4" y="14" width="16" height="6" rx="2"></rect>
              </svg>{" "}
              <em className="hidden">List view</em>
            </button>
          </nav>
          <SelectOrder
            value={sortDropdownValue}
            handleValue={handleDropdownChange}
            className="option-container-dropdown"
            options={sortOptions}
          />
        </div>
      </div>

      {/* {true ? ( */}
      {
        detailedData?.length === 0 && (
          <div className="w-full h-full py-16 flex items-center justify-center rounded-lg bg-gray-100">
            <p className="text-center w-full text-gothic font-semibold text-gray-800 text-base">There is no item yet</p>
          </div>
        )
      }
      {isExploreDataFetching ? (
        <div className="h-full py-28 flex items-center justify-center">
          <SpinnerIcon className="my-auto" />
        </div>
      ) : viewType === "grid" ? (
        <div className="items-wrapper grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {detailedData?.map((each) => (
            <DetailCard key={each.image} data={each} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 gap-6">
          {detailedData?.map((each) => (
            <ListCard key={each.image} data={each} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ExploreItemsContainer;
