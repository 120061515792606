import React, { useState } from "react";
import Button from "../../components/elements/Button";
import ProfileSection from "../../components/modules/ProfileDetailSection/Profile";
import Modal from "../../components/elements/Modal";
import { FaInstagram, FaTwitter } from "react-icons/fa";
import TiktokIcon from "../../components/elements/Icon/TiktokIcon";
import FullLayout from "../../components/layout/FullLayout";
import { useEffect } from "react";
import httpService from "services/http.service";
import { TwitterShareButton } from "react-share";
import { accountShareMessage } from "constants/shareMessage";
// import ipfsService from "services/ipfs.service";
import imageService from "services/image.service";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { useLocation } from "react-router-dom";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import { Helmet } from "react-helmet-async";

const Profile = (props: any) => {
  let location = useLocation();
  // console.log('location', location.state);
  const stateData = location.state
  const param = props.match.params.url;
  const [isSocialModalOpened, setIsSocialModalOpened] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [coverImage, setCoverImage] = useState("/assets/images/cover.png");
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const [isOwnerAccount, setOwnerAccount] = useState(false);
  const [profileUserAddress, setProfileUserAddress] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [isCoverUploading, setIsCoverUploading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getUserInfo = async () => {
    console.log('profileUserAddress', profileUserAddress);
    console.log('userAddress', userAddress);

    // const responseData: any = (await httpService.get(`/user/user-info`)).data;
    const responseData: any = (await httpService.get(`/user/by-address/${profileUserAddress}`)).data;

    // console.log('profiledata', responseData);
    setUserInfo(responseData);    
    setCoverImage(responseData?.cover_url || "/assets/images/cover.png");
  };

  useEffect(() => {
    if (stateData && stateData['address']){
      setProfileUserAddress(stateData['address']);
    } else {
      setProfileUserAddress(userAddress);
    }
  }, [stateData, userAddress]);

  useEffect(() => {
    if (profileUserAddress) {
      if ( profileUserAddress.toLowerCase() === userAddress?.toLowerCase()) {
        setOwnerAccount(true);
      } else {
        setOwnerAccount(false);
      }
      getUserInfo();
    }
  }, [profileUserAddress]);


  const handleDeleteModal = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  // useEffect(() => {
    

  //   if (userAddress) getUserInfo();
  // }, [userAddress]);

 
  const removeFile = async () => {
    handleDeleteModal();
    setIsCoverUploading(true);
    const responseData = (
      await httpService.put(`/user/set-cover`, {
        coverUrl: '',
      })
    ).data;
    setUserInfo({...userInfo, cover_url: ''});
    setCoverImage("/assets/images/cover.png");
    setIsCoverUploading(false);
  };


  const handleSocialModal = () => {
    setIsSocialModalOpened(!isSocialModalOpened);
  };

  const onFileSelected = async (e) => {
    setIsCoverUploading(true);
    let image = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = async () => {
      let result = null;
      if (typeof reader.result == "string") result = reader.result;
      // const uploadedUrl = await ipfsService.uploadImageToIpfs(result);
      const uploadedUrl = await imageService.uploadImage(result);
      const responseData = (
        await httpService.put(`/user/set-cover`, {
          coverUrl: uploadedUrl,
        })
      ).data;
      setCoverImage(uploadedUrl);
      setUserInfo({...userInfo, cover_url: uploadedUrl});
      setIsCoverUploading(false);
    };
  };

  const handleCopy = (url: string) => {
    setIsCopied(prev => !prev);
    navigator.clipboard.writeText(url);
    setTimeout(() => {
      setIsCopied(prev => !prev)
    }, 2000)
  }

  return (
    <FullLayout>
      <>
      <Helmet>
        {
          isOwnerAccount ? (
            <>
              <meta name="robots" content="noindex"/>
              <title>My Kingdom | KINGDOM</title>  
            </>
          ) : (
            <>
              <title>{ userInfo?.display_name ? userInfo.display_name : 'Owner Profile' } | KINGDOM</title>
              <meta property="og:title" content={`${ userInfo?.display_name ? userInfo.display_name : 'Owner Profile' } has { AMOUNT OF PET PROFILES } pet's on KINGDOM`} />
              <meta name="description" content={`Check out ${userInfo?.display_name ? userInfo.display_name : 'Owner Profile'}'s pet content on the next-gen social platform for pets.`} />
              <meta property="og:description" content={`Check out ${userInfo?.display_name ? userInfo.display_name : 'Owner Profile'}'s pet content on the next-gen social platform for pets.`} />
              <meta name="og:image" content={userInfo?.profile_url ? userInfo.profile_url : `${window.location.host}/assets/images/blanket-meta.jpg`} />
            </>
          )
        }
        
        <meta name="og:image" content={`${window.location.host}/assets/images/blanket-meta.jpg`} />
      </Helmet>
      
      <div className="max-w-full lg:max-w-screen-xl mx-auto mt-6 mb-20">
        {isSocialModalOpened && (
          <Modal handleModal={handleSocialModal}>
            <div className="space-y-2 text-gothic">
              <TwitterShareButton
                url={
                  accountShareMessage +
                  " " +
                  window.location.href +
                  `/${userInfo.display_name}`
                }
                className="w-full"
              >
                <Button
                  classNames="w-full rounded-md flex items-center px-4 py-2 !h-auto"
                  leftIcon={<FaTwitter className="mr-4" />}
                  label="Share on twitter"
                />
              </TwitterShareButton>
              {/* <Button
                classNames="w-full rounded-md flex items-center"
                leftIcon={<TiktokIcon className="mr-4" />}
                label="Share on tiktok"
              />
              <Button
                classNames="w-full rounded-md flex items-center"
                leftIcon={<FaInstagram className="mr-4" />}
                label="Share on instagram"
              /> */}
              <Button
                  classNames="w-full rounded-md flex items-center copy-btn px-4 py-2 !h-auto"
                  onClick={()=>handleCopy(window.location.href)}
                  leftIcon={
                    <svg className="mr-2" height="1.5em" width="1.5em" viewBox="0 0 24 24" stroke-width="1.5" stroke="#444" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <rect x="8" y="8" width="12" height="12" rx="2"></rect>
                      <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
                    </svg>
                  }
                  label={isCopied ? "Copied to clipboard" : "Copy link"}
                /> 
            </div>
            <div className="mt-5 sm:mt-6">
              <Button
                label="close"
                classNames="w-full rounded-md hover:!bg-gray-300 text-gothic"
                bgColor="gray"
                onClick={handleSocialModal}
              />
            </div>
          </Modal>
        )}
        <div className="w-full h-64 bg-gray-100 group relative text-gothic font-medium">
          <img
            src={coverImage}
            alt="cover"
            className="absolute inset-0 object-cover h-full w-full"
          />
          {isOwnerAccount && !isCoverUploading &&
            <div className="group-hover:opacity-100 opacity-0 absolute inset-0 bg-white/70 flex flex-col justify-center items-center">
              <p>PNG or JPEG Max 50MB test</p>
              <small>Recommended size 1440 x 256px</small>
              <input
                id="file"
                onChange={(e) => onFileSelected(e)}
                className="hidden"
                type="file"
                hidden={true}
                // ref={ref}
              />
              <label
                htmlFor="file"
                className="change-cover bg-transparent rounded-full mt-4 border-2"
                style={{ padding: "10px 25px 10px 25px" }}
              >
                Change cover
              </label>
              {userInfo?.cover_url && <>
                <button
                id="file-reset"
                onClick={(e) => handleDeleteModal()}
                className="hidden"                
                hidden={true}
              />
              <label
                htmlFor="file-reset"
                className="reset-cover bg-transparent rounded-full mt-4 border-2"
                style={{ padding: "10px 15px 10px 15px" }}
              >
                Reset to default
              </label>
              </>}
            </div>
          }

          {
            isCoverUploading && (
              <div className="absolute inset-0 flex flex-col justify-center items-center">
                <SpinnerIcon className="my-auto"/>
              </div>
            )
          }
         
        </div>
        <ProfileSection 
          handleSocialModal={handleSocialModal} 
          data={userInfo} 
          isOwnerAccount={isOwnerAccount}
          profileUserAddress={profileUserAddress}
        />
         {isDeleteModalOpen && (
          <Modal
            handleModal={handleDeleteModal}
            disableOverlay={false}
            size={800}
          >
            <div className="delete-modal">
              <h1 className="modal-title">Are you sure to remove cover image?</h1>
              <div className="action-button-container">
                <button
                  className="py-3 px-6 rounded-md mt-4 long-btn mint"
                  onClick={handleDeleteModal}
                >
                  No, Keep it
                </button>
                <button
                  className="py-3 px-6 rounded-md mt-4 long-btn delete-nft"
                  onClick={removeFile}
                >
                  Yes, delete it
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
      </>
    </FullLayout>
  );
};

export default Profile;
