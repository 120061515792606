import React from "react";
import HeartIcon from "../../../elements/Icon/HeartIcon";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import httpService from "services/http.service";
import { useState } from "react";
import { useEffect } from "react";
import "./style.scss";
import ImageLoader from "react-load-image";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import ImageWithLoader from "components/elements/ImageWithLoader/ImageWithLoader";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "store";

interface IDetailCardProps {
  data: {
    title: string;
    pet: string;
    image: string;
    price: string;
    likes: number | string;
    hasLiked?: boolean;
    itemId?: number;
    profileLink?: string;
  };
}

const DetailCard = ({ data }: IDetailCardProps) => {
  const [hasLiked, setHasLiked] = useState(data?.hasLiked);
  const [total, setTotal] = useState(Number(data?.likes));
  const userAddress = useSelector((state: RootState) => state.wallet.address);

  const like = async (event) => {
    event.cancelBubble = true;
    event.stopPropagation();
    event.preventDefault();

    if (userAddress) {
      const response = await httpService.put("/item-like/set-like", {
        itemId: data.itemId.toString(),
        status: true,
      });
      console.log(response);
      setTotal(total + 1);
      setHasLiked(true);
    } else {
      document.getElementById("connectButton").click();
    }
  };

  const unlike = async (event) => {
    event.cancelBubble = true;
    event.stopPropagation();
    event.preventDefault();

    const response = await httpService.put("/item-like/set-like", {
      itemId: data.itemId.toString(),
      status: false,
    });
    console.log(response);
    setTotal(total - 1);
    setHasLiked(false);
  };

  useEffect(() => {
    setHasLiked(data.hasLiked);
  }, [data.hasLiked]);

  return (
    <Link
      to={ data.itemId ? `/nfts/${data.itemId}` : ""}
      className="rounded h-[420px] details-card relative overflow-hidden"
    >
      <div className="image-container h-full">
        <ImageWithLoader src={data?.image}/>
      </div>
      <div className="flex justify-between items-center p-3 md:p-2 h-[17%] details-content absolute bottom-0 w-full backdrop-blur-lg hover:bg-white transition-all duration-300 bg-white/75">
        <div className="w-3/4">
          <p className="subtitle cat">{data.pet}</p>
          
          <Link className="hover:underline"
            to={data.profileLink ? `/pets${data.profileLink}` : ""}
          >
            <p className="title">{data.title}</p>
          </Link>
        </div>
        <div className="text-right">
          {/* <p className="text-xs md:text-sm">price</p> */}
          <p className={`subtitle flex items-center justify-end px-2 py-1 like-count rounded-lg cursor-pointer ${hasLiked ? "liked" : ""}`} onClick={hasLiked? unlike : like}>
            {hasLiked ? (
              <Favorite fontSize="small" />
            ) : (
              <FavoriteBorder fontSize="small" />
            )}
            {total}
          </p>
          {/* <p className="title">{`ETH ${data.price}`}</p> */}
        </div>
      </div>
      
    </Link>
  );
};

export default DetailCard;
