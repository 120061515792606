export const ROUTES = {
  DASHBOARD: '/',
  MARKETPLACE: '/marketplace',
  PROFILEDETAIL: '/pets/:url',
  MYPROFILEDETAIL: '/my-profile-detail',
  DETAIL: '/nfts/:itemId',
  PROFILE: '/users',
  EDITPROFILE: '/users/edit',
  USER: '/users/:url',
  CREATE: '/create',
  CREATEPROFILE: '/create-profile',
  CREATEACCOUNT: '/create-account',
  EXPLORE: '/explore',
  EXPLORETAG: '/explore/tag/:tagId',
  EXPLORETYPE: '/explore/type/:typeId',
  EDITACCOUNT: '/pets/:url/edit',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  COMMUNITYGUIDELINES: '/community-guidelines',
  SEARCH: '/search',
};
