import React, { useState, useEffect } from "react";
import Button from "../../../elements/Button";
import ConnectIcon from "../../../elements/Icon/ConnectIcon";
import EditIcon from "../../../elements/Icon/EditIcon";
import ProfileButton from "../ProfileButton";
import Tabs, { ITab } from "../../../elements/Tabs";
import httpService from "services/http.service";
import { useSelector } from "react-redux";
import { RootState } from "store";
import AvatarSelect from "components/modules/CreateProfile/ProfileStep1/AvatarSelect";
// import ipfsService from "services/ipfs.service";
// import s3Service from "services/s3.service";
import imageService from "services/image.service";
import "./style.scss";
import { Link } from "react-router-dom";
import Pagination from "../../../elements/Pagination/Pagination";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";
import UserInfoCard from "../UserInfoCard";

interface IProfileSectionProps {
  handleSocialModal: () => void;
  data?: any;
  isOwnerAccount?: boolean;
  profileUserAddress: string;
}

const ProfileSection = ({
  handleSocialModal,
  data,
  isOwnerAccount,
  profileUserAddress,
}: IProfileSectionProps) => {
  const [activeTab, setActiveTab] = useState<string>("pet");
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const [myProfiles, setMyProfiles] = useState([]);
  const [avatartImage, setAvatartImage] = useState("/assets/images/avatar.png");
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);
  const [isProfileFetching, setIsProfileFetching] = useState(false);
  const [follow, setFollow] = useState(false);
  const [tabs, setTabs] = useState([
    {
      label: "My Pet Profiles",
      value: "pet",
    },
  ]);
  useEffect(() => {
    let imgUrl = data?.profile_url;
    setAvatartImage(imgUrl || "/assets/images/avatar.png");
  }, [data]);
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);

  const getMyPetProfiles = async () => {
    setIsProfileFetching(true);
    const responseData = (
      await httpService.get(
        `profile/user-profiles/${profileUserAddress}?take=${itemPerPage}&skip=${pageNumber}&orderBy=most_popular&userAddress=${
          userAddress ? userAddress : ""
        }`
      )
    ).data;
    setTotalCount(responseData?.count);
    console.log("my profile ==> ", responseData);
    setMyProfiles(
      responseData?.data.map((element) => {
        return {
          title: element.name,
          pet: element.name,
          price: 0,
          likes: 0,
          hasLiked: false,
          url: element.url,
          image: element.image_url,
          itemId: element.items.length,
        };
      })
    );
    setIsProfileFetching(false);
  };
  useEffect(() => {
    if (profileUserAddress) {
      getMyPetProfiles();
    }
  }, [profileUserAddress, activeTab, pageNumber, userAddress]);

  useEffect(() => {
    console.log("data", data);
    if (isOwnerAccount) {
      setTabs([
        {
          label: "My Pet Profiles",
          value: "pet",
        },
      ]);
    } else {
      setTabs([
        {
          label: data?.display_name
            ? `${data?.display_name}'s Pet Profiles`
            : `User's Pet Profiles`,
          value: "pet",
        },
      ]);
    }
  }, [isOwnerAccount, data]);

  const handleAvatar = async (e) => {
    // const uploadedUrl = await ipfsService.uploadImageToIpfs(e);
    setIsAvatarLoading(true);
    const uploadedUrl = await imageService.uploadImage(e);

    const responseData = (
      await httpService.put(`/user/set-image`, {
        imageUrl: uploadedUrl,
      })
    ).data;
    setAvatartImage(uploadedUrl);
    setIsAvatarLoading(false)
  };

  const handlePageClick = (event) => {
    console.log("handlePageClick", event);
    setPageNumber(event.selected);
  };

  return (
    <div className="relative max-w-5xl mx-auto -mt-12 px-4 profile-page">
      <div>
        <div className="flex flex-col md:flex-row md:items-center w-full flex-wrap">
          <div className="relative group overflow-hidden mb-0">
            {isOwnerAccount ? (
              <AvatarSelect
                avatarInfo={avatartImage}
                handleAvatar={(e) => handleAvatar(e)}
                showLabel={false}
                className="profile-avatar"
                isAvatarLoading={isAvatarLoading}
              />
            ) : (
              <div className={`w-full mb-3 avatar-select profile-avatar`}>
                <div className="image-container h-24 w-24 rounded-full cursor-pointer avatar-container">
                  <img
                    src={avatartImage}
                    className="rounded-full h-24 w-24 cursor-pointer"
                    alt="avatar"
                  />
                </div>
              </div>
            )}
          </div>
          {isOwnerAccount ? (
            <div className="md:hidden absolute top-7 right-2 flex space-x-3">
              <Button
                leftIcon={
                  <>
                    <ConnectIcon />
                  </>
                }
                classNames="rounded-full w-12 inline-flex justify-center items-center profile-connect-icon"
                onClick={handleSocialModal}
              />

              <Link
                to="/users/edit"
                className="rounded-full h-12 w-12 flex items-center justify-center profile-edit-icon"
              >
                <EditIcon />
              </Link>
            </div>
          ) : (
            <div className="md:hidden absolute top-7 right-2 flex space-x-3">
              {!follow ? (
                <Button
                  label="follow"
                  variant="outlined"
                  classNames="rounded-full btn-long auto  flex items-center justify-center text-gothic follow"
                  onClick={() => setFollow(!follow)}
                />
              ) : (
                <Button
                  label="unfollow"
                  classNames="rounded-full btn-long auto colored flex items-center justify-center text-gray-600 text-gothic"
                  onClick={() => setFollow(!follow)}
                />
              )}
            </div>
          )}
          <div className="flex flex-col md:flex-row md:ml-12 space-y-2 md:space-y-0 md:space-x-10 items-start md:items-center relative md:mt-8">
            <p className="name">{`${data?.first_name ? data?.first_name : ""} ${
              data?.last_name ? data?.last_name : ""
            }`}</p>
            <p className="username">{`${
              data?.display_name ? "@" + data?.display_name : ""
            }`}</p>
            <ProfileButton address={data?.user_address} />
          </div>
          {isOwnerAccount ? (
            <div className="hidden md:flex items-center relative ml-auto mt-8 space-x-3">
              <Button
                leftIcon={
                  <>
                    <ConnectIcon />
                  </>
                }
                classNames="rounded-full !px-0 !w-10 !h-10 inline-flex justify-center items-center edit-profile-btn"
                onClick={handleSocialModal}
              />

              <Link
                to="/users/edit"
                className="rounded-full h-10 flex items-center justify-center edit-profile-btn px-4 text-gothic"
              >
                Edit account
              </Link>
            </div>
          ) : (
            <div className="hidden md:flex items-center relative ml-auto mt-8 space-x-3">
              {!follow ? (
                <Button
                  label="follow"
                  variant="outlined"
                  classNames="rounded-full btn-long auto follow flex items-center justify-center text-gothic"
                  onClick={() => setFollow(!follow)}
                />
              ) : (
                <Button
                  label="unfollow"
                  classNames="rounded-full btn-long auto colored flex items-center justify-center text-gray-600 font-medium text-gothic"
                  onClick={() => setFollow(!follow)}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <UserInfoCard isPet={false} data={data} profiles={myProfiles}/>
      <div className="mt-6">
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          onChange={(tabValue) => setActiveTab(tabValue)}
          className="w-auto"
        />
        <div className="rounded-2xl p-2 md:p-10 min-h-[400px] mt-6 bg-gray-100">
          {isProfileFetching && (
            <div className="h-full py-28 flex items-center justify-center">
              <SpinnerIcon className="my-auto" />
            </div>
          )}
          {!isProfileFetching && myProfiles.length === 0 && (
            <p className="text-center w-full text-gothic">
              You have no pet profiles yet
            </p>
          )}
          {activeTab === "pet" && (
            <div className="grid grid-cols-3 xs:grid-cols-4 sm:grid-cols-5 md:grid-cols-8 lg:grid-cols-8 gap-4">
              {myProfiles.map((card) => (
                <Link
                  to={`/pets` + card.url}
                  className="flex flex-col relative w-full rounded-full group profile-item"
                >
                  <div className="count absolute top-0 flex items-center justify-center right-0 h-7 w-7 rounded-full">
                    {card.itemId}
                  </div>
                  <img
                    src={card.image}
                    alt={card.title}
                    className="w-full aspect-square rounded-full overflow-hidden profile-item-image"
                  />
                  <p className="group-hover:text-primary w-full text-center w-full text-ellipsis overflow-hidden whitespace-nowrap">
                    {card.title}
                  </p>{" "}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <Pagination
        nextLabel=">"
        prevLabel="<"
        total={totalCount}
        handlePageClick={handlePageClick}
        itemPerPage={itemPerPage}
        pageSelected={pageNumber}
      />
    </div>
  );
};

export default ProfileSection;
