import React from "react";
import SuccessAnimationIcon from "../../../elements/Icon/SuccessAnimationIcon";
import { Link } from 'react-router-dom';

interface IProfileStepSuccessProps {
  petName: string;
}

const ProfileStepSuccess = ({
  petName
}: IProfileStepSuccessProps) => {
  return (
    <div>
      <h2 className="mb-3 success-modal-text text-center">
        {petName}'s Pet Profile is ready!
      </h2>
      <SuccessAnimationIcon />
      <Link
        to="/create"
        className="btn-long colored rounded flex items-center justify-center tracking-wide !h-[60px]"
      >
        Back to create
      </Link>
    </div>
  );
};

export default ProfileStepSuccess;
