import Tabs, { ITab } from "components/elements/Tabs";
import FullLayout from "components/layout/FullLayout";
import React, { useEffect, useState } from "react";
import "./style.scss";
import DetailCard from "../../components/modules/Explore/DetailCard/DetailCard";
import ProfileCard from "components/modules/Dashboard/TopProfilesSection/ProfileCard/index";
import Pagination from "../../components/elements/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import httpService from "services/http.service";
import useNotification from "hooks/useNotification";
import { setKeyword, setTab } from "store/reducers/searchSlicer";
import { getPageDataString } from "common/utils";
import UserCard from "components/modules/Dashboard/TopProfilesSection/UserCard";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";

const tabs: ITab[] = [
  {
    label: "Posts",
    value: "posts",
  },
  {
    label: "Pet Profiles",
    value: "pet_profiles",
  },
  {
    label: "Owners",
    value: "owners",
  },
];

const Search = () => {
  const [activeTab, setActiveTab] = useState<string>("posts");
  const [pageNumber, setPageNumber] = useState(0);
  const searchKeyword = useSelector((state: RootState) => state.search.value);
  const tab = useSelector((state: RootState) => state.search.tab);
  const notify = useNotification();
  const [itemPerPage, setItemPerPage] = useState(9);
  const [isSearchDataFetching, setIsSearchDataFetching] = useState(false);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [posts, setPosts] = useState([]);
  const [tabSwitched, setTabSwitched] = useState(false);
  const [petProfiles, setPetProfiles] = useState([]);
  const [owners, setOwners] = useState([]);
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const [query, setQuery] = useState("");

  const handlePageClick = (event) => {
    setPageNumber(event.selected);
  };
  const getSearchData = async () => {
    if (searchKeyword) {
      setIsSearchDataFetching(true);
      const responseData = await httpService
        .get(
          `/search?` +
            `keyword=${searchKeyword}` +
            `&skip=${pageNumber}` +
            `&take=${itemPerPage}` +
            `&searchIn=${activeTab}` + 
            `&userAddress=${userAddress ? userAddress : ""}`

        )
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          if (error.response) {
            notify(
              error.response?.data?.message
                ? error.response?.data?.message
                : error.response?.data?.error,
              "error"
            );
          } else if (error.request) {
            notify("request error", "error");
          } else {
            notify(error.message, "error");
          }
        });

      if (responseData) {
        setTotalCount(responseData.count);
        switch (activeTab) {
          case "posts":
            if (!tabSwitched && responseData.result.length === 0)
              dispatch(setTab("pet_profiles"));
            setPosts(
              responseData.result.map((element) => {
                return {
                  title: element.profiles.name,
                  profileLink: element.profiles.url,
                  pet: element.name,
                  price: 0,
                  likes: element.item_likes.filter(
                    (element) => element.is_liked
                  ).length,
                  hasLiked:
                    element.item_likes.filter(
                      (element) =>
                        element.users.user_address === userAddress &&
                        element.is_liked === true
                    ).length > 0,
                  image: element.url,
                  itemId: element.item_id,
                };
              })
            );
            break;
          case "pet_profiles":
            if (!tabSwitched && responseData.result.length === 0)
              dispatch(setTab("owners"));
            setPetProfiles((prev: any) => {
              const initialResponse = responseData.result.map((element) => {
                return {
                  no: element.profile_id,
                  title: element.name,
                  floorPrice: 0,
                  likeCount: element.likes_count,
                  data: {
                    value: "--",
                    color: "text-grey-600",
                  },
                  price: 0,
                  link: element.url,
                  image_url: element.image_url,
                };
              });
              return initialResponse;
            });
            break;
          case "owners":
            setOwners((prev: any) => {
              const initialResponse = responseData.result.map((element) => {
                return {
                  userId: element.user_id,
                  displayName: element.display_name,
                  firstName: element.first_name,
                  lastName: element.last_name,
                  likeCount: element.likes_count,
                  link: element.display_name,
                  image_url: element.profile_url,
                  userAddress: element.user_address,
                  title: element.display_name ? element.display_name : (
                    element.first_name ? (element.last_name
                      ? element.first_name + " " + element.last_name : element.first_name) : element.last_name)
                };
              });
              return initialResponse;
            });
            break;
        }
      }
      setIsSearchDataFetching(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setPageNumber(0);
      switch (activeTab) {
        case "posts":
          setItemPerPage(9);
          break;
        case "pet_profiles":
        case "owners":
          setItemPerPage(30);
          break;
      }
    }, 300);
  }, [activeTab]);

  const resetTab = (tabSwitched) => {
    if (tab) {
      setActiveTab(tab);
    } else {
      if (!tabSwitched) {
        dispatch(setTab("posts"));
        setActiveTab("posts");
      }
    }
  };
  useEffect(() => {
    resetTab(tabSwitched);
  }, [tab]);

  useEffect(() => {
    if (searchKeyword) {
      setQuery(searchKeyword);
    }
  }, [searchKeyword]);

  useEffect(() => {
    getSearchData();
  }, [searchKeyword, activeTab, itemPerPage, pageNumber]);

  return (
    <FullLayout>
      <div className="max-w-8xl mx-auto mt-6 mb-20 search-page">
        <div className="max-w-5xl px-4 mx-auto min-h-[75vh]">
          {query ? (
            <>
              {query && (
                <div className="flex mb-6">
                  <span className="search-tag-item inline-flex items-center py-1 px-2 mr-2 text-sm font-medium text-gray-800 bg-gray-100 rounded">
                    {query}
                    <button
                      type="button"
                      className="inline-flex items-center p-0.5 ml-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900"
                      onClick={() => {
                        setTabSwitched(false);
                        if (tab){
                          dispatch(setTab(""));
                        } else {
                          resetTab(false);
                        }
                        setQuery("");
                        
                        dispatch(setKeyword(""));
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        className="w-3.5 h-3.5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="sr-only">Remove badge</span>
                    </button>
                  </span>
                </div>
              )
            }

            <Tabs
              tabs={tabs}
              activeTab={activeTab}
              onChange={(tabValue) => {
                setTabSwitched(true);
                dispatch(setTab(tabValue));
              }}
              containerClass="search-page-tab border-b"
              className="p-3 !hover:text-gray-600 !mb-0"
            />
            { totalCount > 0 && 
              <p className="text-sm text-gray-500 py-6 result-count">
              {getPageDataString(itemPerPage, pageNumber, totalCount).replace('Showing ', '')}
            </p>
            }
            
            {activeTab === "posts" && (
              <>
              {
                isSearchDataFetching ? (
                  <div className="mt-12 flex items-center justify-center">
                    <SpinnerIcon className="my-auto" />
                  </div>
                ) : posts.length === 0 ? (
                  <div className="p-20 bg-gray-100 empty-section">
                    <p className="text-center">
                      There is no pet post
                    </p>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto">
                    {posts.map((item: any) => (
                      <DetailCard key={item.image} data={item} />
                    ))}
                  </div>
                )
              }
              </>
              
            )}
            {activeTab === "pet_profiles" && (
              <>
                {
                  isSearchDataFetching ? (
                    <div className="mt-12 flex items-center justify-center">
                      <SpinnerIcon className="my-auto" />
                  </div>
                  ) : petProfiles.length === 0 ? (
                    <div className="p-20 bg-gray-100 empty-section">
                      <p className="text-center">
                        There is no pet profiles.
                      </p>
                    </div>
                  ) : (
                    <div className="profile-section md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 max-w-5xl mx-auto">
                      {petProfiles.map((item: any, index: number) => (
                        <ProfileCard
                          key={`${item.no}-${index}`}
                          item={item}
                          index={index}
                        />
                      ))}
                    </div>
                  )
                }
              </>
              
            )}
            {activeTab === "owners" && (
              <>
              {
                isSearchDataFetching ? (
                  <div className="mt-12 flex items-center justify-center">
                    <SpinnerIcon className="my-auto" />
                </div>
                ) : owners.length === 0 ? (
                  <div className="p-20 bg-gray-100 empty-section">
                    <p className="text-center">
                      There is no owner.
                    </p>
                  </div>
                ) : (
                  <div className="profile-section md:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 max-w-5xl mx-auto">
                    {owners.map((item: any, index: number) => (
                      <UserCard
                        key={index}
                        item={item}
                        index={index}
                      />
                    ))}
                  </div>
                )
              }
              </>
              
            )}

            {
              totalCount > 0 && !isSearchDataFetching && (
                <div className="block w-full py-4 mt-6">
                  <hr />
                  <Pagination
                    nextLabel=">"
                    prevLabel="<"
                    total={totalCount}
                    handlePageClick={handlePageClick}
                    itemPerPage={itemPerPage}
                    pageSelected={pageNumber}
                  />
                </div>
              )
            }
              
            </>
          ) : (
            <div className="p-20 bg-gray-100 empty-section">
              <p className="text-center">
                Please use the search bar to search for a Post, Pet Profile, or
                Owner account.
              </p>
            </div>
          )}
        </div>
      </div>
    </FullLayout>
  );
};

export default Search;
