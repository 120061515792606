import React from 'react';
import './style.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';

interface ICustomMenuProps {
    open: boolean;
    setOpen: () => void;
    top?: number;
    right?: number;
    children: React.ReactNode;
    width?: number;
    className?: string;
    height?:string
}

const CustomMenu = ({open, setOpen, children, top=0, right=0, width=72, className, height="auto"} : ICustomMenuProps) => {
    const [isShowing, setIsShowing] = useState(null);

    useEffect(() => {
        setIsShowing(open)
    }, [open])

    const handleDropdownClose = () => {
        setIsShowing(!isShowing);
        setTimeout(() => {
            setOpen();
        }, 350)
    }

    return (
        <>
            <div  className={`menu-container ${isShowing ? `show !h-[${height}]` : ""} ${className} absolute top-${top} right-${right} p-1 rounded origin-top-right bg-white w-${width} z-10`}>
                {children}
            </div>
            <div className={`fixed inset-0 w-full h-full bg-white/50 z-[9] custom-overlay ${isShowing ? "show" : ""}`} onClick={handleDropdownClose}></div>
        </>
    );
};

export default CustomMenu;