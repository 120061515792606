import React from "react";
import SpinnerIcon from "../../../../elements/Icon/SpinnerIcon";

const ModalStep2 = () => {
  return (
    <div>
      <div className="absolute top-1 left-1 m-2">
        <p className="mint-modal-step-text">2/3</p>
      </div>
      <div className="h-full flex flex-col">
        <div>
          <div className="mx-auto flex items-center justify-center h-20 w-20 rounded-full mt-12">
            <SpinnerIcon className="text-primary" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="leading-6 mint-modal-info">
              Upload files and mint token
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalStep2
