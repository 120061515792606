import React, {useEffect, useState} from "react";
import Button from "../../../elements/Button";
import Input from "../../../elements/Input";

interface IAccountStep3Props {
  onFrontStep: () => void;
}

const AccountStep3 = ({ onFrontStep }: IAccountStep3Props) => {
  const [userName, setUserName] = useState('Something-Good-0001');
  const [userNameOpen, setUserNameOpen] = useState(true);

  useEffect(() => {
    setUserNameOpen(userName == 't'? false : userName == ''? false : userName.length >= 4);
  }, [userName]);

  const slugify = (text: string) => {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  };


  return (
    <div>
      <h2 className="mb-3 text-center text-xl text-dark-logo font-title">
        Choose a username
      </h2>
      <div className="">
        <Input
          className=""
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          hint={`enterkingdom.io/users/${slugify(userName)}`}
        />
      </div>
      <div className="my-4">
        {
          userNameOpen ? (
            <Button
              classNames="w-full rounded-2xl h-auto py-4 font-title"
              label="next"
              onClick={onFrontStep}
            />
          ) : (
            <Button
              bgColor="gray"
              classNames="w-full rounded-2xl h-auto py-4 font-title"
              label={`${userName ? 'Unavailable' : 'Enter a username'}`}
            />
          )
        }
      </div>
    </div>
  );
};

export default AccountStep3;
