import httpService from "services/http.service";
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const verifyPayment = async (timeToEnd, paymentIntent) => {
  try {

    const intent = await httpService.get("stripe/payment-intent/" + paymentIntent);
    console.log('intent', intent.data);
    if (intent && intent.data && intent.data.is_minted) {
      return true;
    } else {
      await sleep(2000);
      if (Date.now() > timeToEnd) {
          return false;
      }
      return verifyPayment(timeToEnd, paymentIntent);
    }
  } catch (error) {
      console.log('error ', error);
      await sleep(2000);
      if (Date.now() > timeToEnd) {
          return false;
      }
      return verifyPayment(timeToEnd, paymentIntent);
  }
};
export const verifyNFT = async (timeToEnd, itemId) => {
  try {

    const item = await httpService.get(`item/item-by-id?itemId=${itemId}`);
    console.log('intent', item.data);
    if (item && item.data && item.data.length && item.data[0].nfts.length) {
      return true;
    } else {
      await sleep(2000);
      if (Date.now() > timeToEnd) {
          return false;
      }
      return verifyNFT(timeToEnd, itemId);
    }
  } catch (error) {
      console.log('error ', error);
      await sleep(2000);
      if (Date.now() > timeToEnd) {
          return false;
      }
      return verifyNFT(timeToEnd, itemId);
  }
};

export const getPageDataString = (itemPerPageValue, pageNoValue, totalCount) => {
  return `Showing ${1 + itemPerPageValue * (pageNoValue)} - ${itemPerPageValue * (pageNoValue) + itemPerPageValue < totalCount
    ? itemPerPageValue * (pageNoValue) + itemPerPageValue
    : totalCount} of ${totalCount} results`;
}

export const getETHRate = async () => {
  const rate = (
    await httpService.http.get(
      `https://min-api.cryptocompare.com/data/price?fsym=${process.env.REACT_APP_NATIVE_CURRENCY}&tsyms=USD`
    )
  ).data["USD"];
  console.log('ETH rate', rate);
  return rate;
}

export const isInstagramLinked = (instagram_url) => {
  if (!instagram_url) return false;

  return (instagram_url?.includes('https://instagram.com/') && instagram_url !== 'https://instagram.com/') || (instagram_url?.includes('https://www.instagram.com/') && instagram_url !== 'https://www.instagram.com/');
}

export const isTiktokLinked = (tiktok_url) => {
  if (!tiktok_url) return false;

  return (tiktok_url?.includes('https://tiktok.com/') && tiktok_url !== 'https://tiktok.com/') || (tiktok_url?.includes('https://www.tiktok.com/') && tiktok_url !== 'https://www.tiktok.com/');
}

export const isTwitterLinked = (twitter_url) => {
  if (!twitter_url) return false;

  return (twitter_url?.includes('https://twitter.com/') && twitter_url !== 'https://twitter.com/') || (twitter_url?.includes('https://www.twitter.com/') && twitter_url !== 'https://www.twitter.com/');
}