import React, {useEffect, useState} from "react";
import httpService from "services/http.service";
import DetailCardHome from "../../Explore/DetailCard/DetailCardHome";
import { useSelector } from "react-redux";
import history from "common/history";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";

const MostLovedPets = () => {
  const userAddress = useSelector((state) => state.wallet.address);
  const [nftData, setNft] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [skip, setSkip] = useState(0)
  const [limit, setLimit] = useState(6);
  const [isFetchingPets, setIsFetchingPets] = useState(false);

  const getItems = async () => {
    setIsFetchingPets(true);
    const responseData = (
      await httpService.get(`/item/most-liked-items?take=${limit}&skip=${skip}`)
    ).data;      
    const { data, count} = responseData;
    setTotalCount(count);
    setNft((prev) => {
      const initialResponse = data.map((element) => {
        return {
          no: element.item_id,
          title: element.name,
          pet: element.profiles.name,
          profileLink: element.profiles.url,
          image: element.url,
          likes: element.item_likes.length,
          price: "0",
          hasLiked: userAddress ? 
            element.item_likes.filter(
              (element) =>
                element.users.user_address === userAddress &&
                element.is_liked === true
            ).length > 0 : false,
          itemId: element.item_id,
        };
      })

      if(initialResponse.length === limit){
        return initialResponse;
      } else {
         let  tempResponse = [...Array(limit - initialResponse.length).fill(0)].map(item => {
          return {
            no: 0,
            title: "NFT title here 1",
            pet: "Bella",
            image: "/assets/images/fake-1.png",
            likes: 0,
            price: "0",
            hasLiked: false,
            itemId: 0,
          }
         })
         return [...initialResponse, ...tempResponse]
      }
    });
    setIsFetchingPets(false)
  }

  useEffect(() => {
    getItems();
  }, [limit, skip]);

  return (
    <div className="container mx-auto mt-20 most-loved-section">
      <div className="pl-4 mb-12">
        <h2 className="section-title">Most loved Kingdom Pets</h2>
        <p className="section-subtitle">last 30 days</p>
      </div>      

      {
        isFetchingPets ? (
          <div className="h-4 w-full py-28 flex items-center justify-center">
            <SpinnerIcon className="my-auto" />
          </div>
        ) : (
          <div className="items-wrapper grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {nftData?.map((each, index) => (
                <DetailCardHome key={index} data={each} />
              ))}
          </div>
        )
      }

      <div className="mt-10 text-center flex justify-center">
        <button className="h-14 sm:h-12 flex items-center justify-center rounded tracking-wide btn-long colored auto px-6"
        onClick={() => {
          history.push('/explore')
        }}
        >
          Browse pet content
        </button>
      </div>
    </div>
  );
};

export default MostLovedPets;
