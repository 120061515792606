import React, { useEffect, useState } from "react";
import "./style.scss";
import { GrSearch } from "react-icons/gr";
import httpService from "services/http.service";
import SpinnerIcon from "components/elements/Icon/SpinnerIcon";

const ExploreSidebar = (props) => {
    const [types, setTypes] = useState([]);
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState({});
    const [selectedTypes, setSelectedTypes] = useState({});
    const [init, setInit] = useState(false);
    const [isTypesFetching, setIsTypesFetching] = useState(false);
    const [isTagsFetching, setIsTagsFetching] = useState(false);

    useEffect(() => {
        const setTypeFromParam = (typeId) => {
            const temp = selectedTypes;
            temp[typeId[0]] = true;
            setSelectedTypes(temp);
            setInit(true);
        };
        const setTagFromParam = (tagId) => {
            const temp = selectedTags;
            temp[tagId[0]] = true;
            setSelectedTags(temp);
            setInit(true);
        };
        if (!init) {
            if (props.types && Object.keys(props.types).length > 0)
                setTypeFromParam(props.types);
            if (props.tags && Object.keys(props.tags).length > 0)
                setTagFromParam(props.tags);
        }
    }, [props.types, props.tags]);

    useEffect(() => {
        const fetchTypes = async () => {
            setIsTypesFetching(true);
            const responseData = (await httpService.get(`/type/top-types`)).data;
            setTypes(
                responseData.map((element) => {
                    return {
                        id: element.type_id,
                        title: element.name,
                        value: element.name,
                    };
                })
            );
            setIsTypesFetching(false);
        };
        const fetchTags = async () => {
            setIsTagsFetching(true);
            const responseData = (await httpService.get(`/tag/top-tags?take=20&skip=0`)).data;
            setTags(
                responseData.map((element) => {
                    return {
                        id: element.tag_id,
                        title: element.name,
                        value: element.name,
                    };
                })
            );
            setIsTagsFetching(false);
        };

        fetchTypes();
        fetchTags();
    }, []);

    const appendType = (event) => {
        const temp = selectedTypes;
        temp[event.target.id] = event.target.checked;
        setSelectedTypes(temp);
        const list = []
        Object.keys(temp).map(key => {
            if (temp[key]) list.push(key);
        })
        props.setTypes(list);
    };

    const appendTag = (event) => {
        const temp = selectedTags;
        temp[event.target.id] = event.target.checked;
        setSelectedTags(temp);
        const list = []
        Object.keys(temp).map(key => {
            if (temp[key]) list.push(key);
        })
        props.setTags(list);
    };

    return (
        <div className="explore-sidebar-container block overflow-hidden py-4 md:py-12 px-8 break-words relative">
            {/* <div className="search-box group">
                <p className="sidebar-title">Search</p>
                <div className="search-input">
                    <input
                        type="text"
                        placeholder="Search NFTs"
                        value={props.search}
                        onChange={(event) => props.setSearch(event.target.value)}
                    />
                    <div className="icon">
                        <GrSearch />
                    </div>
                </div>
            </div> */}
            <div className="options-container">
                <div className="option-item" key={1}>
                    <p className="sidebar-title">Types</p>
                    <div className="border-box">
                        <ul className="pl-0 my-0 ml-0">
                            {
                                isTypesFetching 
                                    ? <div className="h-full py-28 flex items-center justify-center">
                                        <SpinnerIcon className="my-auto" />
                                      </div>
                                    : types.map((item) => (
                                        <li
                                            className="w-full text-left rounded group cursor-pointer group option"
                                            key={item.id}
                                        >
                                            <label class="pl-2 py-2 flex items-center w-full font-semibold align-middle cursor-pointer group-checked:bg-red-500">
                                                <input
                                                    id={item.id}
                                                    type="checkbox"
                                                    defaultChecked={selectedTypes[item.id]}
                                                    className="p-0 my-0 mr-1 ml-0 align-middle cursor-pointer checkbox-input"
                                                    onChange={appendType}
                                                />
                                                <span className="pl-2">{item.title}</span>
                                            </label>
                                        </li>
                                    ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="options-container">
                <div className="option-item" key={2}>
                    <p className="sidebar-title">Tags</p>
                    <div className="border-box">
                        <ul className="pl-0 my-0 ml-0">
                            {
                                isTagsFetching 
                                    ? <div className="h-full py-28 flex items-center justify-center">
                                        <SpinnerIcon className="my-auto" />
                                      </div>
                                    : tags.map((item) => (
                                        <li
                                            className="w-full text-left rounded group cursor-pointer group option"
                                            key={item.id}
                                        >
                                            <label class="pl-2 py-2 flex items-center w-full font-semibold align-middle cursor-pointer group-checked:bg-red-500">
                                                <input
                                                    id={item.id}
                                                    type="checkbox"
                                                    defaultChecked={selectedTags[item.id]}
                                                    className="p-0 my-0 mr-1 ml-0 align-middle cursor-pointer checkbox-input"
                                                    onChange={appendTag}
                                                />
                                                <span className="pl-2">{item.title}</span>
                                            </label>
                                        </li>
                                    ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExploreSidebar;
