import React from "react";
import Button from "../../../elements/Button";

interface IAccountStep2Props {
  onFrontStep: () => void;
}

const AccountStep2 = ({ onFrontStep }: IAccountStep2Props) => {
  return (
    <div>
      <h2 className="mb-3 text-center text-xl text-dark-logo font-title">
        Welcome to Kingdom!
      </h2>
      <p className="text-center mb-6">
        Let's create an account for you.
      </p>
      <Button
        label="Get started"
        classNames="w-full py-4 h-auto rounded-2xl font-medium font-title"
        onClick={onFrontStep}
      />
    </div>
  );
};

export default AccountStep2;
