import React, {ChangeEventHandler} from "react";
import {FaSearch, FaTimes} from "react-icons/fa";

interface ISearchProps {
  placeholder?: string;
  classname?: string;
  value?: string | number;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onSubmit?;
}

const SearchInput = ({
  placeholder = 'Search',
  classname,
  value,
  onChange,
  name,
  onSubmit,
}: ISearchProps) => {
  return (
    <div className="relative pr-12">
      <input
        type="search"
        placeholder={placeholder}
        className={`placeholder-gray-500 text-sm outline-none w-full py-2 bg-gray-300 rounded-full border-transparent focus:border-primary border-2 box-border ${classname}`}
        value={value}
        name={name}
        onChange={onChange}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            onSubmit();
          }
        }}
      />
      <FaSearch className="absolute text-gray-400 top-3 left-4" />
    </div>
  );
};

export default SearchInput;
