import React from "react";

type IPetType = {
  petName: string;
  petHandle: string;
  petIg: string;
  avatar: string;
  petType: string;
  petBreed: string;
};

interface IPreviewCardProps {
  selected: number | null;
  pet: IPetType;
  image: string;
  title: string;
  profileInfo;
}

const PreviewCard = ({
  selected,
  pet,
  image,
  title,
  profileInfo
}: IPreviewCardProps) => {
  return (
    <div className="rounded-2xl p-1 md:p-3 shadow-md border-2 preview-card w-auto md:w-[246px] overflow-hidden">
      {
        !!pet && (
          <img
            src={pet.avatar}
            alt={pet.avatar}
            className="h-10 w-10 rounded-full object-cover absolute top-1 right-1 border-2 border-secondary bg-gray-100"
          />
        )
      }
      <img src={profileInfo.avatar} alt="pet" className="h-10 w-10 rounded-full object-cover absolute top-1 right-1 right-top-corner" />
      <img
        className="object-cover aspect-square w-full rounded-t-xl"
        src={image || `/assets/images/placeholder.png`}
        alt=""
      />
      <div className="flex justify-between p-1 md:p-2">
        <div className="w-full">
          {
            !!pet ? <>
              <p className="subtitle w-full text-ellipsis overflow-hidden whitespace-nowrap">
                {pet.petName || 'Select profile'}
              </p>
              <p className="title w-full text-ellipsis overflow-hidden whitespace-nowrap">
                {title || 'Preview'}
              </p>
            </> : (
              <p className="title w-full text-ellipsis overflow-hidden whitespace-nowrap">Preview</p>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default PreviewCard;
