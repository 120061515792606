import { configureStore } from '@reduxjs/toolkit'
import { loadState, saveState } from './localStorage'
import counterReducer from './reducers/counterSlicer'
import walletPopupSlicer from './reducers/walletpopupSlicer'
import walletReducer from './reducers/walletSlicer'
import searchSlicer from './reducers/searchSlicer'


const initialState = loadState();

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    wallet: walletReducer,
    search: searchSlicer,
    walletPopup: walletPopupSlicer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  preloadedState: initialState,
})

store.subscribe(() => {
  saveState(store.getState());
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch