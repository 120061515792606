import React, { useEffect, useState } from "react";
import httpService from "services/http.service";
import { Favorite, FavoriteBorder } from "@material-ui/icons";
import "./styles.scss";
import ImageWithLoader from "components/elements/ImageWithLoader/ImageWithLoader";
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { RootState } from "store";

interface IDetailCardProps {
  data: {
    title: string;
    pet: string;
    image: string;
    price: string;
    likes: number | string;
    hasLiked?: boolean;
    itemId?: number;
  };
}

const ListCard = ({ data }: IDetailCardProps) => {
  const [hasLiked, setHasLiked] = useState(data?.hasLiked);
  const [total, setTotal] = useState(Number(data?.likes));
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const like = async (event) => {
    event.cancelBubble = true;
    event.stopPropagation();
    event.preventDefault();

    if (userAddress) {
      const response = await httpService.put("/item-like/set-like", {
        itemId: data.itemId.toString(),
        status: true,
      });
      console.log(response);
      setTotal(total + 1);
      setHasLiked(true);
    } else {
      document.getElementById("connectButton").click();
    }
  };

  const unlike = async (event) => {
    event.cancelBubble = true;
    event.stopPropagation();
    event.preventDefault();

    const response = await httpService.put("/item-like/set-like", {
      itemId: data.itemId.toString(),
      status: false,
    });
    console.log(response);
    setTotal(total - 1);
    setHasLiked(false);
  };

  useEffect(() => {
    setHasLiked(data.hasLiked);
  }, [data.hasLiked]);

  const handleLike = (event) => {
    if (hasLiked) unlike(event);
    else like(event);
  };

  return (
    <Link
      to={`/nfts/` + data.itemId}
      className="rounded-cards no-blue bg-white hover:border-primary border-2 border-gray-200 flex origin-top-left list-card"
    >
      <ImageWithLoader src={data.image} classNames="object-cover w-full max-w-[12rem] aspect-square"/>
      {/* <img
          className="object-cover w-1/3 max-w-[12rem] aspect-square"
          src={data.image}
          alt="assets/images/fake-1.png"
        /> */}
      
      <div className="flex w-full justify-between p-1 md:p-2 ml-6">
        <div className="w-3/4">
          <p className="subtitle cat">{data.title}</p>
          <p className="title">{data.pet}</p>
        </div>{" "}
        <div className="text-right">
          <button
            onClick={handleLike}
            className="subtitle text-sm text-gray-500 flex items-center font-title justify-end rounded-md px-2 py-1 like-count"
          >
            {/* <svg
              className="h-5 w-5 mr-1"
              viewBox="0 0 24 24"
              stroke-width="1"
              stroke="currentColor"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M19.5 13.572 12 21l-7.5-7.428m0 0A5 5 0 1 1 12 7.006a5 5 0 1 1 7.5 6.572"></path>
            </svg> */}
            {hasLiked ? (
              <Favorite fontSize="small" color="secondary" />
            ) : (
              <FavoriteBorder fontSize="small" />
            )}
            <span>{total}</span>
          </button>
        </div>
      </div>
    </Link>
  );
};

export default ListCard;
