import React, {useEffect, useState} from "react";
import AccountStep1 from "../../components/modules/CreateAccount/AccountStep1";
import AccountStep2 from "../../components/modules/CreateAccount/AccountStep2";
import AccountStep3 from "../../components/modules/CreateAccount/AccountStep3";
import AccountStep4 from "../../components/modules/CreateAccount/AccountStep4";
import FullLayout from "../../components/layout/FullLayout";

const CreateAccount = () => {
  const [step, setStep] = useState(1);

  useEffect(() => {
    if (step === 1) {
      setTimeout(() => {
        setStep(step + 1);
      }, 1800);
    }
  }, []);

  useEffect(() => {
    if (step === 5) {
      setStep(1);
    }
  }, [step]);

  const onFrontStep = () => {
    setStep(step + 1);
  };

  const renderContent = (step: number) => {
    switch (step) {
      case 1:
        return <AccountStep1 />
      case 2:
        return <AccountStep2 onFrontStep={onFrontStep} />
      case 3:
        return <AccountStep3 onFrontStep={onFrontStep} />
      case 4:
        return <AccountStep4 onFrontStep={onFrontStep} />
    }
  };

  return (
    <FullLayout headerLocked={true} footerLocked={true}>
      <div className="mt-6 mb-20 px-4">
        {
          step > 2 && step !== 5 && (
            <div className="absolute top-1 right-1 m-2 page-corner-stepper">
              <p className="completed">{step - 2} <span className="total relative top-1">/ 2</span></p>
            </div>
          )
        }
        <div className="flex justify-center px-4">
          <div className="h-[300px] w-[300px] mt-20 md:mt-32">
            {renderContent(step)}
          </div>
        </div>
      </div>
    </FullLayout>
  );
};

export default CreateAccount;
