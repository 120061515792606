import { getPageDataString } from "common/utils";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./style.scss";

const Pagination = ({
  nextLabel = "next >",
  prevLabel = "< previous",
  pageSelected,
  handlePageClick,
  total,
  itemPerPage,
}) => {
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    setPageCount(Math.ceil(total / itemPerPage));
  }, [total, itemPerPage]);

  return (
    <div className="pagination-wrapper">
      <div className="desktop-pagination w-full flex items-center justify-between">
        <p className="pagination-label result-count">
          {getPageDataString(itemPerPage, pageSelected, total)}
        </p>
        {total > itemPerPage && (
          <ReactPaginate
            breakLabel="..."
            nextLabel={nextLabel}
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            previousLabel={prevLabel}
            renderOnZeroPageCount={null}
            containerClassName="pagination-container flex rounded-md"
            pageClassName="page-item"
            activeClassName="page-active"
            previousClassName="page-nav"
            nextClassName="page-nav"
            breakClassName="page-break"
            marginPagesDisplayed={3}
          />
        )}
      </div>
      {total > itemPerPage && (
        <div className="mobile-pagination  w-full flex items-center justify-between">
          <button
            className="rounded-md"
            onClick={() => {
              if (pageSelected > 0) {
                handlePageClick({
                  selected: pageSelected - 1,
                });
              }
            }}
          >
            Previous
          </button>
          <button
            className="rounded-md"
            onClick={() => {
              if (total > itemPerPage * (pageSelected + 1)) {
                handlePageClick({
                  selected: pageSelected + 1,
                });
              }
            }}
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
