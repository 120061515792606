import React, { useEffect, useState } from "react";
import Input from "../../../elements/Input";
import Button from "../../../elements/Button";
import AvatarSelect from "./AvatarSelect";
import "./style.scss";
import { FilePond } from "react-filepond";

interface IProfileStep1Props {
  onFrontStep: () => void;
  petNameInfo: string;
  handlePetName: (name: string) => void;
  petHandleInfo: string;
  handlePetHandle: (value: string) => void;
  petIgInfo: string;
  handlePetIg: (value: string) => void;
  avatarInfo: string;
  handleAvatar;
}

const ProfileStep1 = ({
  onFrontStep,
  petNameInfo,
  handlePetName,
  petHandleInfo,
  handlePetHandle,
  petIgInfo,
  handlePetIg,
  avatarInfo,
  handleAvatar,
}: IProfileStep1Props) => {
  const [userNameOpen, setUserNameOpen] = useState(true);

  useEffect(() => {
    setUserNameOpen(petHandleInfo == "" ? false : petHandleInfo.length >= 4);
  }, [petHandleInfo]);

  const slugify = (text: string) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/^-+/, ""); // Trim - from start of text
    // .replace(/-+$/, ""); // Trim - from end of text
  };

  // console.log("avatarInfo", avatarInfo)

  return (
    <div className="pet-profile-1">
      <h2 className="mb-3 text-center title">Pet Profile Details</h2>
      {/* <AvatarSelect
        avatarInfo={avatarInfo}
        handleAvatar={(e) => handleAvatar(e)}
      /> */}
        
      <div className="mt-3">
        <FilePond
          files={avatarInfo ? [avatarInfo] : []}
          allowReorder={false}
          allowMultiple={false}
          allowReplace={true}
          onupdatefiles={fileItems => {
            // Set currently active file objects to this.state
            const files = fileItems.map(fileItem => fileItem.file);
            let reader = new FileReader();
            reader.readAsDataURL(files[0]);
            reader.onload = () => {
              handleAvatar(reader.result);
            };
          }}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
        />
      </div>
      <div className="mt-3">
        <Input
          title="Pet name"
          placeholder="Pet name..."
          value={petNameInfo}
          onChange={(e) => handlePetName(e.target.value)}
        />
      </div>
      <div className="mt-3">
        <Input
          title="Kingdom pet profile URL"
          placeholder="Kingdom handle..."
          hint={`enterkingdom.io/profiles/${slugify(petHandleInfo)}`}
          value={petHandleInfo}
          onChange={(e) => handlePetHandle(slugify(e.target.value))}
          coloredHint
        />
      </div>
      {/* <div className="mt-3">
        <Input
          title="Pet Instagram handle"
          placeholder="IG handle..."
          value={petIgInfo}
          onChange={(e) => handlePetIg(e.target.value)}
        />
      </div> */}
      <div className="my-4">
        {userNameOpen &&
        petNameInfo &&
        avatarInfo !== "/assets/images/1.png" ? (
          <Button
            label="next"
            classNames="w-full rounded btn-long colored"
            onClick={onFrontStep}
          />
        ) : (
          <Button
            label="next"
            classNames="w-full rounded btn-long white-colored !text-gray-600"
            bgColor="gray"
          />
        )}
      </div>
    </div>
  );
};

export default ProfileStep1;
