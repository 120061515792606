import React from "react";
import LeftArrowIcon from "../../../../elements/Icon/LeftArrowIcon";

interface IStepButtonProps {
  step: number;
  onClick?: () => void;
}

const StepButton = ({ step, onClick }: IStepButtonProps) => {
  return (
    <button
      className="outline-none flex items-center font-semibold -ml-1 step-button"
      onClick={onClick}
    >
      <LeftArrowIcon className={`${step === 1 ? '' : ''}`} />
      step <span className="ml-2 proportional-nums w-12"><span className="w-12">{step}</span> / 4</span>
    </button>
  );
};

export default StepButton;
