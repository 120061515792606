import React from "react";
import SuccessAnimationIcon from "../../../elements/Icon/SuccessAnimationIcon";
import { Link } from 'react-router-dom';

const AccountStep1 = () => {
  return (
    <>
      <h2 className="mb-3 text-center text-xl text-dark-logo font-title">
        Kingdom Account created!
      </h2>
      <div className="flex flex-col">
        <SuccessAnimationIcon />
        <Link
          to="/create-profile"
          className="flex items-center justify-center px-6 py-4 w-full font-medium font-title rounded-2xl flex-none active:ring-2 bg-primary hover:bg-primary-light text-white active:ring-primary border-2 border-transparent text-center tracking-wide"
        >
          Create your first pet profile
        </Link>
      </div>
    </>
  );
};

export default AccountStep1;
