import React from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import "./style.scss";
import httpService from "services/http.service";
import useNotification from "hooks/useNotification";
import { verifyNFT, verifyPayment } from "common/utils";

const ElementProvider = ({ itemId, handleModal, onCreate, paymentIntent }) => {
  const stripe = useStripe();
  const elements = useElements();
  const notify = useNotification();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    const url = window.location.href;

    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    console.log('itemId', itemId);
    await httpService.put("item/mint", {
      itemId: itemId.toString(),
    });
    
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${url}`,
      },
      redirect: "if_required",
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      // console.log(result.error.message);
      notify("Creadit Payment Failed", "error");
      await httpService.put("item/mint-failed", {
        itemId: itemId.toString(),
      });
      handleModal();
    } else {
      handleModal();
      onCreate(2);

      const endRequestsAt = Date.now() + parseInt(process.env.REACT_APP_TRANSACTION_WAITING_TIME);
      const verified = await verifyPayment(endRequestsAt, paymentIntent);
      if (verified) {
        onCreate(3);
        notify("Creadit Payment Success", "success");
        notify("Initiating Minting", "warning");
      } else {
        notify("Creadit Payment Failed", "error");
        await httpService.put("item/mint-failed", {
          itemId: itemId.toString(),
        });
        handleModal();
        return;
      }

      const endRequestsAt1 = Date.now() + parseInt(process.env.REACT_APP_TRANSACTION_WAITING_TIME);
      const verified1 = await verifyNFT(endRequestsAt1, itemId);
      if (verified1) {
        onCreate(4);
      } else {
        notify("Transaction timeout, but still your transaction can succeeded", "error");
        await httpService.put("item/mint-failed", {
          itemId: itemId.toString(),
        });
        handleModal();
        return;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe} className="button">
        Submit
      </button>
    </form>
  );
};

export default ElementProvider;
