import React, { memo } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { ROUTES } from "./constants";
import Dashboard from "./pages/Dashboard";
import MarketPlace from "./pages/MarketPlace";
import ProfileDetail from "./pages/ProfileDetail";
import MyProfileDetail from "./pages/MyProfileDetail";
import Detail from "./pages/Detail";
import Profile from "./pages/Profile";
import EditProfile from "./pages/EditProfile";
import User from "./pages/User";
import Create from "./pages/Create";
import CreateProfile from "./pages/CreateProfile";
import CreateAccount from "./pages/CreateAccount";
import ExplorePage from "./pages/Explore";
import "./assets/styles/style.scss";
import EditAccount from "./pages/EditAccount/index";
import Privacy from './pages/StaticPages/Privacy';
import TermsOfServices from "pages/StaticPages/TermsOfServices";
import CommunityGuidelines from "pages/StaticPages/CommunityGuidelines";
import NotFound from './pages/StaticPages/NotFound';
import Search from "pages/Search";

const App = memo(() => {
  return (
    <Switch>
      <Route exact path={ROUTES.DASHBOARD} component={Dashboard} />
      <Route exact path={ROUTES.MARKETPLACE} component={MarketPlace} />
      <Route exact path={ROUTES.PROFILEDETAIL} component={ProfileDetail} />
      <Route exact path={ROUTES.MYPROFILEDETAIL} component={MyProfileDetail} />
      <Route exact path={ROUTES.DETAIL} component={Detail} />
      <Route exact path={ROUTES.PROFILE} component={Profile} />
      <Route exact path={ROUTES.EDITPROFILE} component={EditProfile} />
      <Route exact path={ROUTES.USER} component={Profile} />
      <Route exact path={ROUTES.CREATE} component={Create} />
      <Route exact path={ROUTES.CREATEPROFILE} component={CreateProfile} />
      <Route exact path={ROUTES.CREATEACCOUNT} component={CreateAccount} />
      <Route exact path={ROUTES.EXPLORE} component={ExplorePage} />
      <Route exact path={ROUTES.EXPLORETAG} component={ExplorePage} />
      <Route exact path={ROUTES.EXPLORETYPE} component={ExplorePage} />
      <Route exact path={ROUTES.EDITACCOUNT} component={EditAccount} />
      <Route exact path={ROUTES.PRIVACY} component={Privacy} />
      <Route exact path={ROUTES.TERMS} component={TermsOfServices} />
      <Route exact path={ROUTES.COMMUNITYGUIDELINES} component={CommunityGuidelines} />
      <Route exact path={ROUTES.SEARCH} component={Search}/>
      <Route exact path={"*"} component={NotFound} />
    </Switch>
  );
});

export default App;
