import React, { useEffect, useState } from "react";
import ProfileButton from "../ProfileButton";
import Button from "../../../elements/Button";
import ConnectIcon from "../../../elements/Icon/ConnectIcon";
import { profileCards } from "../../../../constants/profile";
import DetailCard from "../DetailCard";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Link } from "react-router-dom";
import Pagination from "../../../elements/Pagination/Pagination";
import httpService from "services/http.service";
import { getPageDataString } from "common/utils";
import ProfileInfoCard from "../ProfileInfoCard";

interface IProfileDetailSectionProps {
  handleSocialModal: () => void;
  handleReportModal: () => void;
  data: any;
}

const ProfileDetailSection = ({
  handleSocialModal,
  handleReportModal,
  data,
}: IProfileDetailSectionProps) => {
  const [itemData, setItemData] = useState([]);
  const [name, setName] = useState(null);
  const [url, setUrl] = useState(null);
  const [address, setAddress] = useState(null);
  const [profileImage, setProfileImage] = useState("/assets/images/fake-1.png");
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  let fetched = false;
  const [pageNumber, setPageNumber] = useState(0);
  const [itemPerPage, setItemPerPage] = useState(9);
  const [totalCount, setTotalCount] = useState(0);

  const getItems =async () => {
    const _itemData = await httpService.get(`/item/item-by-profile?userAddress=${userAddress}&take=${itemPerPage}&skip=${pageNumber}&profileId=${data.profile_id}`)

    if (_itemData && _itemData.data) {
      const _items = _itemData.data.data;
      setTotalCount(_itemData.data.count);
      setItemData(
        _items.map((element) => {
          fetched = true;
          return {
            title: element.name,
            pet: data.name,
            price: 0,
            likes: element.item_likes.filter((element) => element.is_liked)
              .length,
            hasLiked:
              element.item_likes.filter(
                (element) =>
                  element.users.user_address === userAddress &&
                  element.is_liked === true
              ).length > 0,
            image: element.url,
            itemId: element.item_id,
          };
        })
      );
    } else {
      setItemData([]);
    }
  }
  const makeItemData = async () => {
    console.log('makeItemData', data);
    setUrl(data.url.slice(1, data.url.length));
    setName(data.name);
    setProfileImage(data.image_url);
    setAddress(data.collection_address);
    getItems();
  };

  useEffect(() => {
    if (data) makeItemData();
  }, [data, userAddress]);

  useEffect(() => {
    if (data) getItems();
  }, [pageNumber]);

  const handlePageClick = (event) => {
    setPageNumber(event.selected);
  };

  return (
    <div className="relative max-w-5xl mx-auto -mt-12 px-4">
      <div className="flex flex-col md:flex-row md:items-center w-full flex-wrap">
        <div className="relative group rounded-full overflow-hidden w-28 md:w-32 h-28 md:h-32 mb-4 md:mb-0">
          <img
            src={profileImage}
            alt="fake-1"
            className="rounded-full object-cover profile-avatar-image"
          />
        </div>
        <div className="flex flex-col md:flex-row md:ml-12 space-y-2 md:space-y-0 md:space-x-10 items-start md:items-center relative md:mt-8">
          <Link to={url} className="block font-bold hover:underline name">
            {name}'s profile
          </Link>
          <Link 
            to={
                {     
                  pathname: `/users/${data?.owner?.display_name}`,
                  state: {
                    address: data?.owner?.user_address
                  },
                }
              } 
            className="block font-bold hover:underline name"
          >
            <p className="username">{`${data?.owner?.display_name ? '@'+ data?.owner?.display_name : ''}`}</p>
          </Link>
          {address && <ProfileButton address={address} />}
        </div>
        <div className="flex items-center absolute md:relative -top-2 md:top-0 right-2 md:right-0 ml-auto mt-8 space-x-3">
          {userAddress === data?.profile_owner && (
            <Link to={`/pets/${url}/edit`}>
              <Button
                label="Edit Profile"
                classNames="rounded-full h-10 flex items-center justify-center edit-profile-btn px-4"
              />
            </Link>
          )}
          <Button
            onClick={handleSocialModal}
            leftIcon={
              <>
                <ConnectIcon />
              </>
            }
            classNames="rounded-full w-12 inline-flex justify-center items-center profile-connect-icon"
          />
          {userAddress === data?.profile_owner || (
            <Button
              label="report"
              bgColor="gray"
              classNames="rounded-full text-gothic font-semibold"
              onClick={handleReportModal}
            />
          )}
        </div>
      </div>
      
      <ProfileInfoCard isPet={true} data={data} />

      <div className="w-full flex items-center justify-between mt-4">
        <p className="bottom-title">{name}'s Pet Posts</p>
        <p className="bottom-subtitle">{getPageDataString(itemPerPage, pageNumber, totalCount)}</p>
      </div>
      <div className="rounded-2xl p-2 md:p-10 min-h-[400px] mt-6 bg-gray-100">
        <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-2">
          {itemData.length > 0 &&
            itemData.map((card) => <DetailCard key={card.image} data={card} />)}
        </div>
      </div>
      <div className="block w-full px-4">
          <hr />
          <Pagination
            nextLabel=">"
            prevLabel="<"
            total={totalCount}
            handlePageClick={handlePageClick}
            itemPerPage={itemPerPage}
            pageSelected={pageNumber}
          />
        </div>
    </div>
  );
};

export default ProfileDetailSection;
