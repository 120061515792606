import React, { useRef, useMemo } from "react";
import usePopup from "../../../hooks/usePopup";
import { FaChevronDown } from "react-icons/fa";
import "./styles.scss";

interface ISelectProps {
  options;
  className?: string;
  placeholder: string;
  title: string;
  handleValue: (value: string) => void;
  value;
}

const SelectOrder = ({
  options,
  className,
  placeholder,
  title,
  handleValue,
  value,
}: ISelectProps) => {
  const popupRef = useRef(null);
  const { isOpen, toggleMenu } = usePopup(popupRef);

  const selectedOption = useMemo(() => {
    return options.find((o) => o.value === value?.value);
  }, [value]);

  return (
    <div className={`relative common-select-input ${className}`}>
      <label className="font-medium font-title text-sm text-gray-700">
        {title}
      </label>
      <button
        className="relative py-4 w-full outline-none flex items-center justify-between cursor-pointer rounded min-h-10 px-4 h-[48px]"
        onClick={toggleMenu}
        ref={popupRef}
      >
        <span>{selectedOption ? selectedOption.title : placeholder}</span>
        <FaChevronDown className="absolute right-5" />
      </button>
      {isOpen && (
        <div className="common-select-list z-40 absolute top-16 right-0 shadow w-64 rounded bg-white max-h-60 overflow-auto">
          {options.map((option) => (
            <div
              className={`px-4 py-2 text-black-400 hover:bg-primary/25 cursor-pointer flex items-center justify-between common-select-list-item ${
                option.value === value?.value ? "selected" : ""
              }`}
              key={option.value}
              onClick={() => handleValue(option)}
            >
              <span>{option.title}</span>
              {
                option.value === value?.value && <span className="text-white">
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </span>
              }
                
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectOrder;
