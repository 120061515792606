import { createSlice } from '@reduxjs/toolkit'

export interface WalletPopupState {
  message: string;
  show: boolean;
}

const initialState: WalletPopupState = {
  message: '',
  show: false
}

export const walletPopupSlice = createSlice({
  name: 'walletPopup',
  initialState,
  reducers: {
    setVerifyModalOpen: (state, action) => {
      state.show = action.payload;
    },
    setVerifyModalMessage: (state, action) => {
      state.message = action.payload;
    },
  },
})

export const { setVerifyModalOpen, setVerifyModalMessage } = walletPopupSlice.actions

export default walletPopupSlice.reducer