import React from "react";

interface ILeftArrowIconProps {
  className?: string;
}

const LeftArrowIcon = ({ className }: ILeftArrowIconProps) => {
  return (
    <svg className={`mr-2 stroke-current ${className}`} width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000"
         fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z " fill="none"/>
      <line x1="5" y1="12" x2="19" y2="12"/>
      <line x1="5" y1="12" x2="9" y2="16"/>
      <line x1="5" y1="12" x2="9" y2="8"/>
    </svg>
  );
};

export default LeftArrowIcon;
