import React, {ReactNode, useState, useEffect} from "react";
import './style.scss';

interface IModalProps {
  children: ReactNode;
  handleModal: () => void;
  disableOverlay?: boolean;
  size?: number;
  show?: boolean;
  className?: string;
}

const Modal = ({
  children,
  handleModal,
  disableOverlay = true,
  size = 500,
  show = true,
  className = '',
}: IModalProps) => {
  const [isShowing, setIsShowing] = useState(null);

  useEffect(() => {
      setIsShowing(show)
  }, [show])

  const handleModalClose = () => {
    setIsShowing(!isShowing);
    setTimeout(() => {
      handleModal();
    }, 350)
  }
  return (
    <div className={`fixed z-50 inset-0 w-full h-full overflow-auto bg-opacity-75 bg-gray-500 flex justify-center items-center modal-wrapper ${isShowing? "show" : ""} ${className}`}>
      {
        disableOverlay && <div className={`fixed inset-0 custom-backdrop ${isShowing? "show" : ""}`} onClick={handleModalClose} />
      }
      <div className={`relative inline-block ${isShowing? "show" : ""} w-[350px] md:w-auto align-bottom bg-white rounded-xl p-3 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-[${size}] sm:min-w-[380px] sm:p-5 modal-container`}>
        {children}
      </div>
    </div>
  );
}

export default Modal;
