import React, { useEffect, useState } from "react";
import Logo from "../../elements/Logo";
import SearchInput from "../../elements/SearchInput";
import CustomLink from "../../elements/CustomLink";
import Button from "../../elements/Button";
import { FaBars } from "react-icons/fa";
import HeaderMobileMenu from "./HeaderMobileMenu";
import "./style.scss";
import CustomMenu from "../../elements/CustomMenu/CustomMenu";
import history from "common/history";
import { isMobile } from "react-device-detect";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

import httpService from "../../../services/http.service";
import storageService from "../../../services/storage.service";
import { useDispatch, useSelector } from "react-redux";
import { connect, disconnect, reconnect } from "store/reducers/walletSlicer";
import { setKeyword, setTab } from "store/reducers/searchSlicer";
import {
  setVerifyModalOpen,
  setVerifyModalMessage,
} from "store/reducers/walletpopupSlicer";
import { RootState } from "store";
import { Link } from "react-router-dom";
import useNotification from "hooks/useNotification";
import Input from "./../../elements/Input/index";
import Modal from "../../elements/Modal/index";

const tokenKey = process.env.REACT_APP_USER_TOKEN_KEY;
interface IHeaderProps {
  locked?: boolean;
}

const phraseList = [
  "nice",
  "pond",
  "blast",
  "calm",
  "crop",
  "supreme",
  "watch",
  "industry",
  "next",
  "mint",
  "function",
  "recall",
];

const Header = ({ locked }: IHeaderProps) => {
  const [isToggled, setIsToggled] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [currentScreen, setCurrentScreen] = useState("initial");
  const [inputPhraseList, setInputPhraseList] = useState([]);
  const [isWalletConnected, setIsWalletConnected] = useState(null);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const userAddress = useSelector((state: RootState) => state.wallet.address);
  const provider = useSelector((state: RootState) => state.wallet.provider);
  const walletType = useSelector((state: RootState) => state.wallet.type);
  const searchKeyword = useSelector((state: RootState) => state.search.value);
  const verifyModalMessage = useSelector(
    (state: RootState) => state.walletPopup.message
  );
  const isVerifyModalOpen = useSelector(
    (state: RootState) => state.walletPopup.show
  );
  const [balance, setBalance] = useState(null);
  const notify = useNotification();
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [hideNotification, setHideNotification] = useState(false);

  // useEffect(() => {});

  const signout = () => {
    dispatch(disconnect());
    localStorage.clear();
    window.location.href = "/";
  };

  const signIn = async (account: string, signer: any, walletType: string) => {
    try {
      const data = (
        await httpService.post("auth/nounce", {
          userAddress: account,
        })
      ).data;

      if (walletType === "WALLET_CONNECT") {
        dispatch(
          setVerifyModalMessage(
            "Please sign your identity from connected wallet app."
          )
        );
        dispatch(setVerifyModalOpen(true));
      }
      const signature = await signer.signMessage(
        `I am signing my one-time nonce${data[0].user_nounce}`
      );
      dispatch(setVerifyModalOpen(false));
      return (
        await httpService.post("/auth/signin", {
          userAddress: account,
          signature,
        })
      ).data;
    } catch (error) {
      dispatch(setVerifyModalOpen(false));
      return null;
    }
  };

  const switchToCustomNetwork = async (provider, walletType) => {
    try {
      if (walletType === "WALLET_CONNECT") {
        dispatch(
          setVerifyModalMessage(
            "Please switch blockchain network in your connected wallet app."
          )
        );
        dispatch(setVerifyModalOpen(true));
      }
      await provider.send("wallet_switchEthereumChain", [
        { chainId: process.env.REACT_APP_CHAIN_ID },
      ]);
      dispatch(setVerifyModalOpen(false));
      return true;
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (
        switchError.code === 4902 ||
        switchError.message.includes("Unrecognized chain ID")
      ) {
        try {
          dispatch(
            setVerifyModalMessage(
              "Please confirm network addition from your connected wallet app."
            )
          );
          dispatch(setVerifyModalOpen(true));
          await provider.send("wallet_addEthereumChain", [
            {
              chainName: process.env.REACT_APP_CHAIN_NAME,
              chainId: process.env.REACT_APP_CHAIN_ID,
              rpcUrls: [process.env.REACT_APP_RPC],
              nativeCurrency: {
                name: process.env.REACT_APP_NATIVE_CURRENCY_NAME,
                symbol: process.env.REACT_APP_NATIVE_CURRENCY,
                decimals: 18,
                token_id: process.env.REACT_APP_TOKEN_ID,
              },
            },
          ]);
          dispatch(setVerifyModalOpen(false));
          return true;
        } catch (addError) {
          dispatch(setVerifyModalOpen(false));
          console.log("addError", addError);
          return false;
        }
      } else {
        dispatch(setVerifyModalOpen(false));
        console.log("switchError else");
        return false;
      }
    }
  };

  const setNetworkEvent = async (provider) => {
    provider.on("network", async (network) => {
      if (network?.chainId !== parseInt(process.env.REACT_APP_CHAIN_ID, 16)) {
        notify("Network changed in wallet ", "error");
        signout();
        return;
      }
    });
  };

  const connectToMetaMask = async () => {
    const providerOptions = {
      /* See Provider Options Section */
    };

    const walletType = "META_MASK";
    const web3Modal = new Web3Modal({
      network: "mumbai", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });

    const instance = await web3Modal.connect();

    const provider = new ethers.providers.Web3Provider(instance, "any");

    const chainId = (await provider.getNetwork()).chainId;
    if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID, 16)) {
      const switchNetwork = await switchToCustomNetwork(provider, walletType);
      if (!switchNetwork) {
        notify(
          `Change your network to ${process.env.REACT_APP_CHAIN_NAME}.`,
          "error"
        );
        return;
      }
    }

    await setNetworkEvent(provider);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const account = await signer.getAddress();
    const data = await signIn(account, signer, walletType);
    storageService.setItem(tokenKey, data.access_token);

    dispatch(
      connect({
        type: walletType,
        address: account,
        signer,
        provider,
      })
    );
  };

  const connectToConnectWallet = async () => {
    const _chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 16);
    const walletType = "WALLET_CONNECT";
    console.log("_chainId", _chainId);
    const walletProvider = new WalletConnectProvider({
      bridge: "https://bridge.walletconnect.org",
      rpc: {
        [_chainId]: process.env.REACT_APP_RPC,
      },
      infuraId: process.env.REACT_APP_INFURA_ID, // required
    });
    await walletProvider.enable();

    const provider = new ethers.providers.Web3Provider(walletProvider, "any");

    const chainId = (await provider._networkPromise).chainId;
    if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID, 16)) {
      const switchNetwork = await switchToCustomNetwork(provider, walletType);
      if (!switchNetwork) {
        notify(
          `Change your network to ${process.env.REACT_APP_CHAIN_NAME}.`,
          "error"
        );
        return;
      }
    }
    await setNetworkEvent(provider);
    await provider.send("eth_accounts", []);
    const signer = provider.getSigner();

    const account = await signer.getAddress();

    const data = await signIn(account, signer, walletType);
    storageService.setItem(tokenKey, data.access_token);

    dispatch(
      connect({
        type: walletType,
        address: account,
        signer,
        provider,
      })
    );
  };

  const _connectToConnectWallet = async () => {
    try {
      await connectToConnectWallet();
      setIsWalletConnected(true);
      setIsConnectModalOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const _connectToMetamask = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        await connectToMetaMask();
        setIsWalletConnected(true);
        setIsConnectModalOpen(false);
      } else {
        window.open(process.env.REACT_APP_METAMASK_DEEPLINK);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setInputPhraseList([...phraseList].fill(""));
    const getBalance = async () => {
      const amount = await provider.getBalance(userAddress);
      setBalance(parseFloat(ethers.utils.formatEther(amount)).toFixed(4));
    };
    if (userAddress && provider) getBalance();
  }, [userAddress, provider]);

  const handleConnectModal = () => {
    setIsConnectModalOpen(!isConnectModalOpen);
  };

  useEffect(() => {
    console.log("isVerifyModalOpen header", isVerifyModalOpen);
    console.log("verifyModalMessage header", verifyModalMessage);
  }, [isVerifyModalOpen, verifyModalMessage]);

  const handleItemSelect = (item: string) => {
    const tempList = [...inputPhraseList];
    const pointedIndex = tempList.findIndex((item: any) => item === "");
    if (pointedIndex <= 11) {
      tempList[pointedIndex] = item;
      setInputPhraseList(tempList);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("hideNotification") === "true") {
      setHideNotification(true);
    }

    if (userAddress && walletType) {
      if (walletType == "META_MASK") {
        if (typeof window.ethereum !== "undefined") {
          reconnectToMetaMask();
        } else {
          window.open(process.env.REACT_APP_METAMASK_DEEPLINK);
        }
      } else {
        reconnectToConnectWallet();
      }
    }
  }, []);

  const reconnectToMetaMask = async () => {
    const providerOptions = {
      /* See Provider Options Section */
    };
    const web3Modal = new Web3Modal({
      network: "mumbai", // optional
      cacheProvider: true, // optional
      providerOptions, // required
    });

    const instance = await web3Modal.connect();

    const provider = new ethers.providers.Web3Provider(instance, "any");

    const chainId = (await provider._networkPromise).chainId;
    if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID, 16)) {
      const switchNetwork = await switchToCustomNetwork(provider, "META_MASK");
      if (!switchNetwork) {
        notify(
          `Change your network to ${process.env.REACT_APP_CHAIN_NAME}.`,
          "error"
        );
        return;
      }
    }

    await setNetworkEvent(provider);

    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    dispatch(
      reconnect({
        signer: signer,
        provider: provider,
      })
    );
  };

  const reconnectToConnectWallet = async () => {
    const _chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 16);
    const walletProvider = new WalletConnectProvider({
      bridge: "https://bridge.walletconnect.org",
      rpc: {
        [_chainId]: process.env.REACT_APP_RPC,
      },
      infuraId: process.env.REACT_APP_INFURA_ID, // required
    });
    await walletProvider.enable();

    const provider = new ethers.providers.Web3Provider(walletProvider, "any");

    const chainId = (await provider._networkPromise).chainId;
    if (chainId !== parseInt(process.env.REACT_APP_CHAIN_ID, 16)) {
      const switchNetwork = await switchToCustomNetwork(
        provider,
        "WALLET_CONNECT"
      );
      if (!switchNetwork) {
        notify(
          `Change your network to ${process.env.REACT_APP_CHAIN_NAME}.`,
          "error"
        );
        return;
      }
    }

    await setNetworkEvent(provider);
    await provider.send("eth_accounts", []);
    const signer = provider.getSigner();

    // const account = await signer.getAddress();

    dispatch(
      reconnect({
        signer: signer,
        provider: provider,
      })
    );
  };

  const onSubmitClicked = () => {
    // console.log('onSubmitClicked', window.location.href);
    if (searchValue.length > 2) {
      dispatch(setTab(''));
      dispatch(setKeyword(searchValue));
      if (!window.location.href.includes("search")) {
        history.push("/search");
      }
    }
  };

  const onChangeText = (e) => {
    // console.log('onSubmitClicked', e.target.value);
    setSearchValue(e.target.value);
    if (e.target.value.length === 0) {
      dispatch(setKeyword(""));
    }
  };

  useEffect(() => {
    setSearchValue(searchKeyword);
  }, [searchKeyword]);

  const registerSubscriber = async (e) => {
    e.preventDefault();
    if (!userEmail) {
      notify("Input your email.", "error");
      return;
    }

    try {
      const data = await httpService
        .post("/subscriber/create", {
          subscriber_email: userEmail,
          subscriber_type: 1,
        })
        .then((res) => {
          return res.data;
        })
        .catch(function (error) {
          if (error.response) {
            notify(
              error.response?.data?.message
                ? error.response?.data?.message
                : error.response?.data?.error,
              "error"
            );
          } else if (error.request) {
            notify("request error", "error");
          } else {
            notify(error.message, "error");
          }
        });
      if (data) {
        notify(
          "Your email has been registered, glad to see you in our community.",
          "success"
        );
        setUserEmail("");
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const callHideNotification = () => {
    localStorage.setItem("hideNotification", "true");
    setHideNotification(true);
  };

  return (
    <>
      <header className="bg-white h-20 w-full relative">
        <div className="mx-auto px-4 sm:px-6 lg:px-10 py-4 flex items-center justify-between">
          <div className="xl:w-2/8 flex-shrink-0 flex items-center">
            <Link
              to="/"
              className="text-dark-logo dark:text-light-logo relative header-logo"
            >
              <Logo />
            </Link>
          </div>

          {!locked && (
            <>
              <div className="hidden xl:flex flex-1 items-center justify-center px-6 xl:4/7">
                <SearchInput
                  classname="pl-10 pr-3 header-search !rounded !text-sm"
                  placeholder="Search by profile, post or owner"
                  name="search"
                  value={searchValue}
                  onChange={onChangeText}
                  onSubmit={onSubmitClicked}
                />
                <CustomLink href="/explore" label="Explore" classNames="ml-5" />
                <div hidden={userAddress ? false : true}>
                  <CustomLink
                    href="/users"
                    label="My Kingdom"
                    classNames="ml-5"
                  />
                </div>
              </div>
              <div className="hidden xl:w-3/7 md:flex justify-end">
                {!userAddress ? (
                  <Button
                    id="connectButton"
                    label="Connect"
                    classNames="btn-long auto colored h-12 flex items-center px-6 border border-transparent rounded-buttons flex-none focus-visible:outline-none tracking-wide rounded"
                    onClick={handleConnectModal}
                  />
                ) : (
                  <div className="hidden lg:flex lg:items-center lg:justify-end xl:col-span-4">
                    <div className="flex gap-3 relative">
                      {/* <>
                        {!hideNotification && (
                          <div className="relative">
                            <button
                              onClick={() =>
                                setIsNotificationMenuOpen((prev) => !prev)
                              }
                              className="rounded-full bg-primary/10 border-primary border-2 group text-primary h-12 w-12 flex items-center justify-center"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="block h-8 w-8 group-hover:scale-105"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                                <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                              </svg>
                            </button>
                            {isNotificationMenuOpen && (
                              <CustomMenu
                                className="notification-menu shadow-lg rounded-2xl p-2"
                                open={isNotificationMenuOpen}
                                setOpen={() => setIsNotificationMenuOpen(false)}
                              >
                                <div className="notification-container p-2">
                                  <label>Connect your email</label>
                                  <Input
                                    placeholder="Your email..."
                                    value={userEmail}
                                    onChange={(e) =>
                                      setUserEmail(e.target.value)
                                    }
                                  />
                                  <button
                                    onClick={registerSubscriber}
                                    className="py-3 px-6 rounded-md mt-4 btn-long colored"
                                  >
                                    Done
                                  </button>
                                  <p
                                    className="info-text"
                                    onClick={callHideNotification}
                                  >
                                    hide notification
                                  </p>
                                </div>
                              </CustomMenu>
                            )}

                            <div className="rounded-full h-4 w-4 bg-primary absolute top-0 right-0"></div>
                          </div>
                        )}
                      </> */}
                      <button
                        onClick={() => {
                          if (userAddress) {
                            history.push("/create");
                          } else {
                            handleConnectModal();
                          }
                        }}
                        className="btn-long auto colored h-12 flex items-center px-6 border border-transparent rounded-buttons flex-none focus-visible:outline-none tracking-wide rounded"
                      >
                        Create
                      </button>
                      <button
                        onClick={() => setIsAccountMenuOpen(!isAccountMenuOpen)}
                        className="header-wallet-number rounded font-title border-2 h-12 flex items-center gap-2 border-primary pl-4 pr-2  hover:bg-secondary/10 text-black"
                      >
                        <p className="py-2">
                          {userAddress.slice(0, 4) +
                            "..." +
                            userAddress.slice(
                              userAddress.length - 2,
                              userAddress.length
                            )}
                        </p>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                        </svg>
                      </button>
                    </div>
                  </div>
                )}

                <FaBars
                  className="md:hidden cursor-pointer"
                  onClick={() => setIsToggled(!isToggled)}
                />
              </div>
              <div className="flex lg:hidden justify-end">
                <button
                  onClick={() => setIsToggled(!isToggled)}
                  className="-mx-2 rounded-md p-2 inline-flex items-center justify-center text-dark-logo focus:outline-none mobile-menu-icon"
                  aria-expanded="false"
                >
                  <svg
                    className="block h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    ></path>
                  </svg>{" "}
                  <svg
                    className="hidden h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>
            </>
          )}
        </div>
      </header>
      {isToggled && !locked && (
        <HeaderMobileMenu
          signout={signout}
          setIsToggled={() => setIsToggled(!isToggled)}
        />
      )}

      {isConnectModalOpen && (
        <Modal
          size={384}
          disableOverlay={true}
          handleModal={handleConnectModal}
          show={isConnectModalOpen}
        >
          <div className="connect-modal-container">
            {currentScreen === "initial" ? (
              <div className="choose-option-screen">
                <p className="connect-modal-title">Connect a wallet</p>
                <button
                  onClick={() => setCurrentScreen("choose-wallet")}
                  className="py-3 px-6 rounded-md mt-4 btn-long white-colored"
                >
                  Connect existing wallet
                </button>
                {/* <p className="or-text">or</p>
                <button
                  onClick={() => setCurrentScreen("create-kingdom-wallet")}
                  className="py-3 px-6 rounded-md mt-4 btn-long colored"
                >
                  Create Kingdom wallet
                </button>
                <p
                  onClick={() => setCurrentScreen("recover-wallet")}
                  className="modal-colored-text"
                >
                  Recover Kingdom Wallet
                </p> */}
              </div>
            ) : currentScreen === "choose-wallet" ? (
              <div className="choose-wallet-screen w-full">
                <p className="connect-modal-title">Choose wallet provider</p>
                <div className="wallet-options flex justify-center sm:justify-between gap-4 items-center">
                  {!isMobile && (
                    <div
                      onClick={_connectToMetamask}
                      className="wallet-option w-[164px] h-[152px] flex flex-col justify-center items-center cursor-pointer"
                    >
                      <svg
                        className="w-24"
                        id="Layer_1"
                        data-name="Layer 1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 250 250"
                      >
                        <polygon
                          points="227.6 19.3 138.7 85.4 155.1 46.4 227.6 19.3"
                          fill="#e2761b"
                          stroke="#e2761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="22.3 19.3 110.5 86 94.9 46.4 22.3 19.3"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="195.6 172.5 171.9 208.8 222.6 222.7 237.2 173.3 195.6 172.5"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="12.9 173.3 27.4 222.7 78.1 208.8 54.4 172.5 12.9 173.3"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="75.2 111.1 61.1 132.5 111.4 134.7 109.6 80.7 75.2 111.1"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="174.7 111.1 139.8 80 138.7 134.7 188.9 132.5 174.7 111.1"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="78.1 208.8 108.3 194 82.2 173.6 78.1 208.8"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="141.6 194 171.9 208.8 167.7 173.6 141.6 194"
                          fill="#e4761b"
                          stroke="#e4761b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="171.9 208.8 141.6 194 144 213.8 143.8 222.1 171.9 208.8"
                          fill="#d7c1b3"
                          stroke="#d7c1b3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="78.1 208.8 106.2 222.1 106 213.8 108.3 194 78.1 208.8"
                          fill="#d7c1b3"
                          stroke="#d7c1b3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="106.7 160.6 81.5 153.2 99.2 145 106.7 160.6"
                          fill="#233447"
                          stroke="#233447"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="143.2 160.6 150.7 145 168.5 153.2 143.2 160.6"
                          fill="#233447"
                          stroke="#233447"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="78.1 208.8 82.4 172.5 54.4 173.3 78.1 208.8"
                          fill="#cd6116"
                          stroke="#cd6116"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="167.6 172.5 171.9 208.8 195.6 173.3 167.6 172.5"
                          fill="#cd6116"
                          stroke="#cd6116"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="188.9 132.5 138.7 134.7 143.3 160.6 150.8 145 168.6 153.2 188.9 132.5"
                          fill="#cd6116"
                          stroke="#cd6116"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="81.5 153.2 99.3 145 106.7 160.6 111.4 134.7 61.1 132.5 81.5 153.2"
                          fill="#cd6116"
                          stroke="#cd6116"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="61.1 132.5 82.2 173.6 81.5 153.2 61.1 132.5"
                          fill="#e4751f"
                          stroke="#e4751f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="168.6 153.2 167.7 173.6 188.9 132.5 168.6 153.2"
                          fill="#e4751f"
                          stroke="#e4751f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="111.4 134.7 106.7 160.6 112.6 191.1 113.9 150.9 111.4 134.7"
                          fill="#e4751f"
                          stroke="#e4751f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="138.7 134.7 136.3 150.8 137.3 191.1 143.3 160.6 138.7 134.7"
                          fill="#e4751f"
                          stroke="#e4751f"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="143.3 160.6 137.3 191.1 141.6 194 167.7 173.6 168.6 153.2 143.3 160.6"
                          fill="#f6851b"
                          stroke="#f6851b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="81.5 153.2 82.2 173.6 108.3 194 112.6 191.1 106.7 160.6 81.5 153.2"
                          fill="#f6851b"
                          stroke="#f6851b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="143.8 222.1 144 213.8 141.8 211.8 108.1 211.8 106 213.8 106.2 222.1 78.1 208.8 87.9 216.8 107.8 230.7 142.1 230.7 162.1 216.8 171.9 208.8 143.8 222.1"
                          fill="#c0ad9e"
                          stroke="#c0ad9e"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="141.6 194 137.3 191.1 112.6 191.1 108.3 194 106 213.8 108.1 211.8 141.8 211.8 144 213.8 141.6 194"
                          fill="#161616"
                          stroke="#161616"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="231.4 89.7 239 53.2 227.6 19.3 141.6 83.2 174.7 111.1 221.5 124.8 231.8 112.8 227.4 109.5 234.5 103 229 98.7 236.1 93.3 231.4 89.7"
                          fill="#763d16"
                          stroke="#763d16"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="11 53.2 18.6 89.7 13.8 93.3 20.9 98.7 15.5 103 22.6 109.5 18.2 112.8 28.4 124.8 75.2 111.1 108.3 83.2 22.3 19.3 11 53.2"
                          fill="#763d16"
                          stroke="#763d16"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="221.5 124.8 174.7 111.1 188.9 132.5 167.7 173.6 195.6 173.3 237.2 173.3 221.5 124.8"
                          fill="#f6851b"
                          stroke="#f6851b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="75.2 111.1 28.4 124.8 12.9 173.3 54.4 173.3 82.2 173.6 61.1 132.5 75.2 111.1"
                          fill="#f6851b"
                          stroke="#f6851b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                        <polygon
                          points="138.7 134.7 141.6 83.2 155.2 46.4 94.9 46.4 108.3 83.2 111.4 134.7 112.5 151 112.6 191.1 137.3 191.1 137.5 151 138.7 134.7"
                          fill="#f6851b"
                          stroke="#f6851b"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></polygon>
                      </svg>
                      <p className="option-title">Metamask</p>
                    </div>
                  )}

                  <div
                    onClick={_connectToConnectWallet}
                    className="wallet-option w-[164px] h-[152px] flex flex-col justify-center items-center cursor-pointer"
                  >
                    <svg
                      className="w-24"
                      id="Layer_1"
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 250 250"
                    >
                      <g id="Page-1">
                        <g id="walletconnect-logo-alt">
                          <path
                            id="WalletConnect"
                            d="M57.7,82.4C94.9,46,155.1,46,192.3,82.4l4.5,4.4a4.5,4.5,0,0,1,0,6.6l-15.3,15a2.5,2.5,0,0,1-3.4,0l-6.1-6a67.5,67.5,0,0,0-94,0l-6.6,6.4a2.4,2.4,0,0,1-3.3,0l-15.3-15a4.5,4.5,0,0,1,0-6.6Zm166.3,31,13.6,13.4a4.5,4.5,0,0,1,0,6.6l-61.4,60.1a5,5,0,0,1-6.8,0h0l-43.6-42.7a1.2,1.2,0,0,0-1.6,0h0L80.6,193.5a5,5,0,0,1-6.8,0h0L12.4,133.4a4.5,4.5,0,0,1,0-6.6L26,113.4a5,5,0,0,1,6.8,0l43.6,42.7a1,1,0,0,0,1.6,0h0l43.6-42.7a5,5,0,0,1,6.8,0h0L172,156.1a1,1,0,0,0,1.6,0l43.6-42.7A5,5,0,0,1,224,113.4Z"
                            fill="#3b99fc"
                          ></path>
                        </g>
                      </g>
                    </svg>
                    <p className="option-title">Wallet Connect</p>
                  </div>
                </div>
                <p
                  onClick={() => setCurrentScreen("initial")}
                  className="modal-colored-text"
                >
                  back
                </p>
              </div>
            ) : currentScreen === "create-kingdom-wallet" ? (
              <div className="create-wallet-screen">
                <p className="connect-modal-title">Write down passphrase</p>
                <div className="grid grid-cols-4 gap-2 mb-6 phrase-container">
                  {phraseList.map((item: any, index: number) => {
                    return (
                      <div className="phrase-item rounded-full relative h-10 flex items-center justify-center">
                        {item}
                        <span className="absolute font-light -top-1 -left-1 rounded-full h-4 w-4 flex items-center justify-center border box-content">
                          {index + 1}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <button
                  onClick={() => setCurrentScreen("confirm-wallet")}
                  className="py-3 px-6 rounded-md mt-4 btn-long colored"
                >
                  Got it
                </button>
              </div>
            ) : currentScreen === "confirm-wallet" ? (
              <div className="confirm-wallet relative">
                <button className="absolute -top-3 -right-3 rounded-full bg-gray-100 hover:bg-gray-200 active:bg-gray-300 p-2">
                  <span className="sr-only">Undo last word</span>
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="h-4 w-4"
                  >
                    <polyline points="1 4 1 10 7 10"></polyline>
                    <path d="M3.51 15a9 9 0 1 0 2.13-9.36L1 10"></path>
                  </svg>
                </button>

                <p className="connect-modal-title">Confirm passphrase</p>

                <div className="grid grid-cols-4 gap-2 mb-6 input-options">
                  {inputPhraseList.length > 0 &&
                    inputPhraseList.map((item: any, index: number) => {
                      return item === "" ? (
                        <div className="phrase-item-empty rounded-full relative h-10 flex items-center justify-center">
                          {item}
                        </div>
                      ) : (
                        <div className="phrase-item rounded-full relative h-10 flex items-center justify-center">
                          {item}
                          <span className="absolute font-light -top-1 -left-1 rounded-full h-4 w-4 flex items-center justify-center border box-content">
                            {index + 1}
                          </span>
                        </div>
                      );
                    })}
                </div>

                <div className="selectable-options">
                  <span>Select the words in the right order</span>
                  <div className="grid grid-cols-4 gap-2 mt-2">
                    {phraseList.length > 0 &&
                      phraseList.map((item: any, index: number) => {
                        return (
                          <div
                            onClick={() => handleItemSelect(item)}
                            className={`cursor-pointer phrase-item rounded-full relative h-10 flex items-center justify-center text-white ${
                              inputPhraseList.includes(item) ? "selected" : ""
                            }`}
                          >
                            {item}
                          </div>
                        );
                      })}
                  </div>
                </div>

                <button
                  onClick={() => setCurrentScreen("confirm-wallet")}
                  className="py-3 px-6 rounded-md mt-4 btn-long white-colored"
                >
                  Check
                </button>

                <p
                  onClick={() => setCurrentScreen("initial")}
                  className="modal-colored-text"
                >
                  back
                </p>
              </div>
            ) : currentScreen === "recover-wallet" ? (
              <div className="recover-wallet-screen">
                <p className="connect-modal-title">Enter passphrase</p>
                <div className="grid grid-cols-4 gap-2 mt-2">
                  {phraseList.length > 0 &&
                    phraseList.map((item: any, index: number) => {
                      return (
                        <div
                          onClick={() => handleItemSelect(item)}
                          className={`cursor-pointer phrase-item-empty rounded-full relative h-10 flex items-center justify-center text-white`}
                        >
                          <input
                            type="text"
                            className="rounded-full outline-none wallet-input"
                          />
                          <span className="absolute font-light -top-1 -left-1 rounded-full h-4 w-4 flex items-center justify-center border box-content">
                            {index + 1}
                          </span>
                        </div>
                      );
                    })}
                </div>
                <button
                  onClick={() => setCurrentScreen("confirm-wallet")}
                  className="py-3 px-6 rounded-md mt-4 btn-long white-colored"
                >
                  Check
                </button>

                <p
                  onClick={() => setCurrentScreen("initial")}
                  className="modal-colored-text"
                >
                  back
                </p>
              </div>
            ) : null}
          </div>
        </Modal>
      )}

      {isAccountMenuOpen && (
        <CustomMenu
          className="account-menu"
          open={isAccountMenuOpen}
          setOpen={() => setIsAccountMenuOpen(false)}
        >
          <div>
            <div className="wallet-no flex flex-col">
              <span>address</span>
              <p>
                {userAddress.slice(0, 20) +
                  "..." +
                  userAddress.slice(userAddress.length - 3, userAddress.length)}
              </p>
            </div>
            <div className="balance flex p-2">
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 relative" version="1.1" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd"
              viewBox="0 0 784.37 1277.39">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer"/>
                <g id="_1421394342400">
                <g>
                  <polygon fill="#343434" fill-rule="nonzero" points="392.07,0 383.5,29.11 383.5,873.74 392.07,882.29 784.13,650.54 "/>
                  <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,0 -0,650.54 392.07,882.29 392.07,472.33 "/>
                  <polygon fill="#3C3C3B" fill-rule="nonzero" points="392.07,956.52 387.24,962.41 387.24,1263.28 392.07,1277.38 784.37,724.89 "/>
                  <polygon fill="#8C8C8C" fill-rule="nonzero" points="392.07,1277.38 392.07,956.52 -0,724.89 "/>
                  <polygon fill="#141414" fill-rule="nonzero" points="392.07,882.29 784.13,650.54 392.07,472.33 "/>
                  <polygon fill="#393939" fill-rule="nonzero" points="0,650.54 392.07,882.29 392.07,472.33 "/>
                </g>
                </g>
              </g>
              </svg>
              {/* <svg
                style={{ fill: "#8247e5" }}
                className="w-8 h-8 relative top-1"
                viewBox="0 0 507.91 446.91"
              >
                <defs></defs>
                <title>polygon-matic</title>
                <path
                  className="cls-1"
                  d="M384.58,136.59c-9.28-5.3-21.22-5.3-31.83,0l-74.26,43.77L228.1,208.2,155.16,252c-9.28,5.3-21.22,5.3-31.83,0l-57-34.48a32.33,32.33,0,0,1-15.92-27.85V123.33c0-10.61,5.31-21.22,15.92-27.85l57-33.15c9.28-5.31,21.22-5.31,31.83,0l57,34.48a32.31,32.31,0,0,1,15.92,27.85v43.76l50.39-29.18V94.16c0-10.61-5.3-21.22-15.91-27.85L156.48,4c-9.28-5.31-21.21-5.31-31.82,0L15.91,67.63C5.3,72.94,0,83.55,0,94.16V218.81C0,229.42,5.3,240,15.91,246.66L123.33,309c9.28,5.31,21.22,5.31,31.83,0l72.94-42.44,50.39-29.17,72.94-42.44c9.28-5.3,21.22-5.3,31.83,0l57,33.16A32.32,32.32,0,0,1,456.19,256v66.3c0,10.61-5.3,21.22-15.91,27.85l-55.7,33.16c-9.28,5.3-21.22,5.3-31.83,0l-57-33.16a32.32,32.32,0,0,1-15.91-27.85V279.82L229.42,309v43.76c0,10.61,5.31,21.22,15.92,27.85l107.41,62.33c9.29,5.31,21.22,5.31,31.83,0L492,380.6a32.32,32.32,0,0,0,15.91-27.85v-126c0-10.61-5.3-21.22-15.91-27.85Z"
                ></path>
              </svg> */}
              <div className="balance-content flex flex-col ml-4">
                <span>Balance</span>
                <p>{balance} ETH</p>
              </div>
            </div>
            <hr className="mb-4" />
            <div className="menu-item-container flex flex-col">
              <Link className="menu-item w-full mb-2" to="/users">
                My kingdom
              </Link>
              <Link className="menu-item w-full mb-2" to="/users/edit">
                Edit Account
              </Link>
            </div>
            <button className="sign-out-btn" onClick={signout}>
              {" "}
              Sign Out
            </button>
          </div>
        </CustomMenu>
      )}

      {isVerifyModalOpen && (
        <Modal
          size={384}
          disableOverlay={true}
          handleModal={() => dispatch(setVerifyModalOpen(false))}
          show={isVerifyModalOpen}
        >
          <div className="wallet-verify-modal-container">
            <h2>Wallet connect action</h2>
            <p>{verifyModalMessage}</p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Header;
